"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOnlineService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var jssha_1 = require("jssha");
var ServiceOnlineService = /** @class */ (function () {
    function ServiceOnlineService(http, authenticationService, tokenExtractor) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.tokenExtractor = tokenExtractor;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_service_online_service = process.env.service_online_env_hostname;
        this.partnerId = process.env.SOL_partnerId;
        this.version = process.env.SOL_version;
        this.communityId = process.env.SOL_communityId;
        this.rooftopId = this.get_gcdm_config['SOL_rooftopId'];
        this.community = process.env.SOL_community;
    }
    // sollAppCall(token:string): Observable<any>{
    //     var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
    //     var configs = { headers: headers };
    //     let sol_input_data =  {communityId: this.communityId, version: this.version, partnerId: this.partnerId, rooftopId: this.rooftopId, hashKey:""};
    //     return this.http.post(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-call", sol_input_data, configs)
    //    .pipe(map(data =>  data as any));
    // }
    // checkSolApp(token:string): Observable<any>{
    //     var headers = new HttpHeaders({ 'Content-Type': 'application/json'});
    //     const sha = new jsSHA("SHA-384", "TEXT");
    //                 sha.setHMACKey(this.sharedKey, "TEXT");
    //                 sha.update(token);
    //      var hash = sha.getHMAC('HEX'); 
    //     var configs = { headers: headers };
    //     let sol_input_data =  {communityId: this.communityId, version: this.version, partnerId: this.partnerId, rooftopId: this.rooftopId, hashKey:hash};
    //     return this.http.post(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-check", sol_input_data, configs)
    //    .pipe(map(data =>  data as any));
    // }
    //     solAppRegCall(_email:string, _title:string, _nameFirst:string, _nameLast:string, _addressLine1:string,
    //         _addressLine2:string, _addressLine3:string, _addressLine4:string, _postalCode:string, _addressLine5:string,
    //         _homeNo:string, _mobile:string, _workNo:string, token:string, sol_token:string): Observable<any>{
    //         var _InputData = {
    //         CustomerId: _email, // CustomerID : New customer Email Address
    //         RooftopId: this.rooftopId, // RooftopID or Dealer ID underWhich they will be registered
    //         Password: '',
    //         Language: "en", // Language (Leave as "en")
    //         Activated: "true", // Set as Activated "true"
    //         Title: _title, // User Title
    //         FirstName: _nameFirst, // User First Name
    //         SurName: _nameLast, // User SurName
    //         Name1: "", // User NickName
    //         Name2: "",
    //         Name3: "",
    //         Name4: "",
    //         Name5: "",
    //         Name6: "",
    //         Address1: "", //Address Line 1
    //         Address2: _addressLine1, //Address Suburb
    //         Address3: _addressLine2, // Address City
    //         Address4: _addressLine3,
    //         Address5: _addressLine4,
    //         Address6: "",
    //         Address7: "",
    //         Address8: "",
    //         Address9: "",
    //         Address10: "",
    //         Address11: "",
    //         Address12: "",
    //         Address13: "",
    //         Address14: "",
    //         Address15: "",
    //         Address16: "",
    //         PostalCode: _postalCode, //Address Postal Code
    //         AddressKLADR: false,
    //         Country: _addressLine5, // Address Country
    //         LegalAddress1: "", //Address Line 1
    //         LegalAddress2: "", //Address Suburb
    //         LegalAddress3: "", // Address City
    //         LegalAddress4: "",
    //         LegalAddress5: "",
    //         LegalAddress6: "",
    //         LegalAddress7: "",
    //         LegalAddress8: "",
    //         LegalAddress9: "",
    //         LegalAddress10: "",
    //         LegalAddress11: "",
    //         LegalAddress12: "",
    //         LegalAddress13: "",
    //         LegalAddress14: "",
    //         LegalAddress15: "",
    //         LegalAddress16: "",
    //         LegalPostalCode: "", //Address Postal Code
    //         LegalAddressKLADR: false,
    //         LegalCountry: "", // Address Country
    //         HomeTelNoAreaCode: "", // Home tell Area Code IE 011
    //         HomeTelNo: _homeNo, // Home Tel Number
    //         MobileTelNo: _mobile, // Mobile Number
    //         WorkTelNoAreaCode: "",
    //         WorkTelNo: _workNo,
    //         WorkTelNoExt: "",
    //         Fax: "",
    //         EmailAddress: _email, // User Email Address
    //         StopAllMail: true,
    //         Company: "", // User Employer
    //         CompanyType: "", //Employer Industry
    //         CompanyVehicleDriver: false,
    //         CompanyAddress1: "", //Business Address
    //         CompanyAddress2: "",
    //         CompanyAddress3: "",
    //         CompanyAddress4: "",
    //         CompanyAddress5: "",
    //         CompanyAddress6: "",
    //         CompanyAddress7: "",
    //         CompanyAddress8: "",
    //         CompanyAddress9: "",
    //         CompanyAddress10: "",
    //         CompanyAddress11: "",
    //         CompanyAddress12: "",
    //         CompanyAddress13: "",
    //         CompanyAddress14: "",
    //         CompanyAddress15: "",
    //         CompanyAddress16: "",
    //         CompanyPostalCode: "",
    //         CompanyAddressKLADR: false,
    //         CompanyCountry: "",
    //         AllowContactCustomer: false,
    //         community: this.community, //Community which we will receive on go live
    //         PreferredRoofTopId: ""
    //         };
    //         sessionStorage.setItem('registerDets',JSON.stringify(_InputData))
    //         var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
    //         var configs = { headers: headers };
    //         var sol_input_data = {inpuData: _InputData, communityId: this.communityId, solToken:sol_token}
    //         return this.http.post(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-reg-call", sol_input_data, configs)
    //         .pipe(map(data =>  data as any));
    //         /*=**************************************************************ERROR CODES FOR REGISTRATION**************************************************************
    //         Response if user already exists {"ErrorCode":11}
    //         Response if user successfully registered {"ErrorCode":0}
    //         */
    // }
    // solAppCustomerCall(token:string, custmerEmail:string): Observable<any>{
    //     var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
    //     var configs = { headers: headers };
    //     let sol_input_data =  {communityId: this.communityId, appVersion: this.version, partnerId: this.partnerId, custmerEmail:custmerEmail, rooftopId: this.rooftopId, hashKey:""};
    //     return this.http.post(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-customer-call", sol_input_data, configs)
    //    .pipe(map(data =>  data as any));
    // }
    // checkSolAppCustomer(token:string, custmerEmail:string): Observable<any>{
    //     var headers = new HttpHeaders({ 'Content-Type': 'application/json'});
    //     const sha = new jsSHA("SHA-384", "TEXT");
    //                 sha.setHMACKey(this.sharedKey, "TEXT");
    //                 sha.update(token);
    //      var hash = sha.getHMAC('HEX'); 
    //     var configs = { headers: headers };
    //     let sol_input_data =  {communityId: this.communityId, custmerEmail:custmerEmail, version: this.version, PartnerId: this.partnerId, rooftopId: this.rooftopId, hashKey:hash};
    //     return this.http.post(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-check-customer", sol_input_data, configs)
    //    .pipe(map(data =>  data as any));
    // }
    ServiceOnlineService.prototype.getSolKey = function (token) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
        var configs = { headers: headers };
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/get-sol/sol-app-keys", configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postRequestTokenServiceOnline = function (sol) {
        var headers = new http_1.HttpHeaders({
            'Content-type': 'application/json'
        });
        var configs = { headers: headers };
        var sol_input_data = { CustomerId: sol.sol_mail, PartnerId: this.partnerId, Version: this.version, CommunityId: this.communityId };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/RequestToken", sol_input_data, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postRequestTokenServiceOnlineCust = function (customerUserName) {
        var headers = new http_1.HttpHeaders({
            'Content-type': 'application/json'
        });
        var configs = { headers: headers };
        var sol_input_data = { CustomerId: customerUserName, PartnerId: this.partnerId, Version: this.version, CommunityId: this.communityId };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/RequestToken", sol_input_data, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postCheckPasswordServiceOnline = function (token, sol) {
        var sha = new jssha_1.default("SHA-384", "TEXT");
        sha.setHMACKey(sol.sol_share, "TEXT");
        sha.update(token);
        var hash = sha.getHMAC('HEX');
        var sol_input_data = { RooftopId: this.rooftopId };
        var headers = new http_1.HttpHeaders({ 'Content-type': 'application/json', 'Authorization': "DataHub-Hash " + btoa(sol.sol_mail + ":" + hash + ":" + sol.sol_key)
        });
        var configs = { headers: headers };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/CheckPassword", sol_input_data, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postCheckPasswordServiceOnlineCustomer = function (token, custmerEmail, sol) {
        var sha = new jssha_1.default("SHA-384", "TEXT");
        sha.setHMACKey(sol.sol_share, "TEXT");
        sha.update(token);
        var hash = sha.getHMAC('HEX');
        var headers = new http_1.HttpHeaders({
            'Content-type': 'application/json', 'Authorization': "DataHub-Hash " + btoa(custmerEmail + ":" + hash + ":" + sol.sol_key)
        });
        var sol_input_data = { 'RooftopId': this.rooftopId, 'CommunityId': this.communityId,
            'Authorization': "DataHub-Hash " + btoa(custmerEmail + ":" + hash + ":" + sol.sol_key) };
        var configs = { headers: headers };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/CheckPassword", sol_input_data, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postRegisterCustomerServiceOnline = function (_email, _title, _nameFirst, _nameLast, _addressLine1, _addressLine2, _addressLine3, _addressLine4, _postalCode, _addressLine5, _homeNo, _mobile, _workNo, token, sol) {
        var _InputData = {
            CustomerId: _email,
            RooftopId: this.rooftopId,
            Password: '',
            Language: "en",
            Activated: "true",
            Title: _title,
            FirstName: _nameFirst,
            SurName: _nameLast,
            Name1: "",
            Name2: "",
            Name3: "",
            Name4: "",
            Name5: "",
            Name6: "",
            Address1: "",
            Address2: _addressLine1,
            Address3: _addressLine2,
            Address4: _addressLine3,
            Address5: _addressLine4,
            Address6: "",
            Address7: "",
            Address8: "",
            Address9: "",
            Address10: "",
            Address11: "",
            Address12: "",
            Address13: "",
            Address14: "",
            Address15: "",
            Address16: "",
            PostalCode: _postalCode,
            AddressKLADR: false,
            Country: _addressLine5,
            LegalAddress1: "",
            LegalAddress2: "",
            LegalAddress3: "",
            LegalAddress4: "",
            LegalAddress5: "",
            LegalAddress6: "",
            LegalAddress7: "",
            LegalAddress8: "",
            LegalAddress9: "",
            LegalAddress10: "",
            LegalAddress11: "",
            LegalAddress12: "",
            LegalAddress13: "",
            LegalAddress14: "",
            LegalAddress15: "",
            LegalAddress16: "",
            LegalPostalCode: "",
            LegalAddressKLADR: false,
            LegalCountry: "",
            HomeTelNoAreaCode: "",
            HomeTelNo: _homeNo,
            MobileTelNo: _mobile,
            WorkTelNoAreaCode: "",
            WorkTelNo: _workNo,
            WorkTelNoExt: "",
            Fax: "",
            EmailAddress: _email,
            StopAllMail: true,
            Company: "",
            CompanyType: "",
            CompanyVehicleDriver: false,
            CompanyAddress1: "",
            CompanyAddress2: "",
            CompanyAddress3: "",
            CompanyAddress4: "",
            CompanyAddress5: "",
            CompanyAddress6: "",
            CompanyAddress7: "",
            CompanyAddress8: "",
            CompanyAddress9: "",
            CompanyAddress10: "",
            CompanyAddress11: "",
            CompanyAddress12: "",
            CompanyAddress13: "",
            CompanyAddress14: "",
            CompanyAddress15: "",
            CompanyAddress16: "",
            CompanyPostalCode: "",
            CompanyAddressKLADR: false,
            CompanyCountry: "",
            AllowContactCustomer: false,
            community: this.community,
            PreferredRoofTopId: ""
        };
        sessionStorage.setItem('registerDets', JSON.stringify(_InputData));
        _InputData.Password = sol.sol_key;
        var headers = new http_1.HttpHeaders({
            'Content-type': 'application/json', 'Authorization': "DataHub-Token " + token
        });
        var configs = { headers: headers };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/RegisterCustomer", _InputData, configs)
            .pipe(operators_1.map(function (data) { return data; }));
        /*=**************************************************************ERROR CODES FOR REGISTRATION**************************************************************
        Response if user already exists {"ErrorCode":11}
        Response if user successfully registered {"ErrorCode":0}
        */
    };
    ServiceOnlineService.prototype.postGetRooftopListServiceOnline = function (token, customerEmail) {
        var headers = new http_1.HttpHeaders({
            'Content-type': 'application/json', 'Authorization': "DataHub-Token " + token
        });
        var configs = { headers: headers };
        var getRoofTop = { "CustomerId": customerEmail, "ChangeDate": "", "RooftopType": 0, "Token": token, "CommunityId": this.communityId };
        return this.http.post(this.get_service_online_service + "/api/" + this.communityId + "/ServiceOnline/GetRooftopList", getRoofTop, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService.prototype.postToSolApp = function (solInfo) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
        var configs = { headers: headers };
        var add = solInfo;
        //    return this.http.post<any>(this.get_gcdm_config['CP_hostname']+"/customerportalapi/rest/cp/vehicles/service-online/post-to-sol-app", JSON.stringify(add), configs)
        return this.http.post("https://solzaapi.azurewebsites.net/api/v1/loginToSol", JSON.stringify(add), configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ServiceOnlineService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, _services_1.AuthenticationService, http_1.HttpXsrfTokenExtractor])
    ], ServiceOnlineService);
    return ServiceOnlineService;
}());
exports.ServiceOnlineService = ServiceOnlineService;
