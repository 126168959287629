"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = void 0;
var core_1 = require("@angular/core");
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.prototype.setActiveContract = function (activeContract) {
        sessionStorage.setItem("activeContract", activeContract);
    };
    StorageService.prototype.getActiveContract = function () {
        return sessionStorage.getItem("activeContract");
    };
    StorageService.prototype.setPhoneValue = function (phoneValue) {
        sessionStorage.setItem("phoneValue", phoneValue);
    };
    StorageService.prototype.getPhoneValue = function () {
        return sessionStorage.getItem("phoneValue");
    };
    StorageService.prototype.setEmailValue = function (emailValue) {
        sessionStorage.setItem("emailValue", emailValue);
    };
    StorageService.prototype.getEmailValue = function () {
        return sessionStorage.getItem("emailValue");
    };
    StorageService.prototype.setCountry = function (country) {
        sessionStorage.setItem("country", country);
    };
    StorageService.prototype.getCountry = function () {
        return sessionStorage.getItem("country");
    };
    StorageService.prototype.setStreet = function (street) {
        sessionStorage.setItem("street", street);
    };
    StorageService.prototype.getStreet = function () {
        return sessionStorage.getItem("street");
    };
    StorageService.prototype.setStrSuppl2 = function (strSuppl2) {
        sessionStorage.setItem("strSuppl2", strSuppl2);
    };
    StorageService.prototype.getStrSuppl2 = function () {
        return sessionStorage.getItem("strSuppl2");
    };
    StorageService.prototype.setPostalCode = function (postalCode) {
        sessionStorage.setItem("postalCode", postalCode);
    };
    StorageService.prototype.getPostalCode = function () {
        return sessionStorage.getItem("postalCode");
    };
    StorageService.prototype.setCity = function (city) {
        sessionStorage.setItem("city", city);
    };
    StorageService.prototype.getCity = function () {
        return sessionStorage.getItem("city");
    };
    StorageService.prototype.setDistrictName = function (districtName) {
        sessionStorage.setItem("districtName", districtName);
    };
    StorageService.prototype.getDistrictName = function () {
        return sessionStorage.getItem("districtName");
    };
    StorageService.prototype.setGcdmCustNameFirst = function (gcdmCustNameFirst) {
        sessionStorage.setItem("GCDM_Cust_Name_First", gcdmCustNameFirst);
    };
    StorageService.prototype.getGcdmCustNameFirst = function () {
        return sessionStorage.getItem("GCDM_Cust_Name_First");
    };
    StorageService.prototype.setGcdmIdNo = function (gcdmIdNo) {
        sessionStorage.setItem("GCDM_IdNo", gcdmIdNo);
    };
    StorageService.prototype.getGcdmIdNo = function () {
        return sessionStorage.getItem("GCDM_IdNo");
    };
    StorageService.prototype.setGcdmCustBp = function (gcdmCustBp) {
        sessionStorage.setItem("GCDM_Cust_Bp", gcdmCustBp);
    };
    StorageService.prototype.getGcdmCustBp = function () {
        return sessionStorage.getItem("GCDM_Cust_Bp");
    };
    StorageService.prototype.setGcdmCustNameLast = function (gcdmCustNameLast) {
        sessionStorage.setItem("GCDM_Cust_Name_Last", gcdmCustNameLast);
    };
    StorageService.prototype.getGcdmCustNameLast = function () {
        return sessionStorage.getItem("GCDM_Cust_Name_Last");
    };
    StorageService.prototype.setGcdmCustTitle = function (gcdmCustTitle) {
        sessionStorage.setItem("GCDM_Cust_Title", gcdmCustTitle);
    };
    StorageService.prototype.getGcdmCustTitle = function () {
        return sessionStorage.getItem("GCDM_Cust_Title");
    };
    StorageService.prototype.setGcdmEmail = function (gcdmEmail) {
        sessionStorage.setItem("GCDM_Email", gcdmEmail);
    };
    StorageService.prototype.getGcdmEmail = function () {
        return sessionStorage.getItem("GCDM_Email");
    };
    StorageService.prototype.setProcepect = function (isProcepect) {
        sessionStorage.setItem("isProcepect", isProcepect);
    };
    StorageService.prototype.getProcepect = function () {
        return sessionStorage.getItem("isProcepect");
    };
    StorageService.prototype.getCurrentVehicles = function () {
        return JSON.parse(sessionStorage.getItem("currentVehicles"));
    };
    StorageService.prototype.setCurrentVehicles = function (currentVehicles) {
        sessionStorage.setItem("currentVehicles", JSON.stringify(currentVehicles));
    };
    StorageService.prototype.getServiceOnlineUnAvail = function () {
        return sessionStorage.getItem("serviceOnlineUnAvail");
    };
    StorageService.prototype.setServiceOnlineUnAvail = function (serviceOnlineUnAvail) {
        sessionStorage.setItem("serviceOnlineUnAvail", serviceOnlineUnAvail);
    };
    StorageService.prototype.getPolicyMinorVersion = function () {
        return sessionStorage.getItem("policyMinorVersion");
    };
    StorageService.prototype.setPolicyMinorVersion = function (policyMinorVersion) {
        sessionStorage.setItem("policyMinorVersion", policyMinorVersion);
    };
    StorageService.prototype.getOndemandDone = function () {
        return sessionStorage.getItem("ondemandDone");
    };
    StorageService.prototype.setOndemandDone = function (ondemandDone) {
        sessionStorage.setItem("ondemandDone", ondemandDone);
    };
    StorageService.prototype.getSelectedVehicle = function () {
        return JSON.parse(sessionStorage.getItem("selectedVehicle"));
    };
    StorageService.prototype.setSelectedVehicle = function (selectedVehicle) {
        sessionStorage.setItem("selectedVehicle", JSON.stringify(selectedVehicle));
    };
    StorageService.prototype.getDealerId = function () {
        return sessionStorage.getItem("dealerId");
    };
    StorageService.prototype.setDealerId = function (dealerId) {
        sessionStorage.setItem("dealerId", dealerId);
    };
    StorageService.prototype.getVin = function () {
        return sessionStorage.getItem("vin");
    };
    StorageService.prototype.setVin = function (vin) {
        sessionStorage.setItem("vin", vin);
    };
    StorageService.prototype.getSfPolicyList = function () {
        return this.sfPolicyList;
    };
    StorageService.prototype.setNscPolicyList = function (nscPolicyList) {
        this.nscPolicyList = nscPolicyList;
    };
    StorageService.prototype.getNscPolicyList = function () {
        return this.nscPolicyList;
    };
    StorageService.prototype.setSfPolicyList = function (sfPolicyList) {
        this.sfPolicyList = sfPolicyList;
    };
    StorageService.prototype.removeItems = function () {
        sessionStorage.removeItem('ctiveContract');
        sessionStorage.removeItem('GCDM_IdNo');
        sessionStorage.removeItem('GCDM_Cust_Bp');
        sessionStorage.removeItem('GCDM_Cust_Name_Last');
        sessionStorage.removeItem('GCDM_Cust_Title');
        sessionStorage.removeItem('GCDM_Email');
        sessionStorage.removeItem("GCDM_Cust_Name_First");
        sessionStorage.removeItem('city');
        sessionStorage.removeItem('districtName');
        sessionStorage.removeItem('postalCode');
        sessionStorage.removeItem('strSuppl2');
        sessionStorage.removeItem('street');
        sessionStorage.removeItem('country');
        sessionStorage.removeItem('emailValue');
        sessionStorage.removeItem('phoneValue');
        sessionStorage.removeItem("isProcepect");
        sessionStorage.removeItem("currentVehicles");
        sessionStorage.removeItem("serviceOnlineUnAvail");
        sessionStorage.removeItem("policyMinorVersion");
        sessionStorage.removeItem("ondemandDone");
        sessionStorage.removeItem("selectedVehicle");
        sessionStorage.removeItem("dealerId");
        sessionStorage.removeItem("vin");
        sessionStorage.removeItem("CP_OAUTH_BEARER_TOKEN");
    };
    StorageService.prototype.clearStore = function () {
        localStorage.clear();
        sessionStorage.clear();
    };
    StorageService = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [])
    ], StorageService);
    return StorageService;
}());
exports.StorageService = StorageService;
