"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TyreSearchComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var tyreoptionsmodal_component_1 = require("./tyreoptionsmodal/tyreoptionsmodal.component");
var TyreSearchComponent = /** @class */ (function () {
    function TyreSearchComponent(authenticationService, modalService) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.loading = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
    }
    TyreSearchComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    TyreSearchComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
    };
    TyreSearchComponent.prototype.openTyreOptionsModal = function (childVehicleTyre) {
        var modalRef = this.modalService.open(tyreoptionsmodal_component_1.TyreOptionsModalComponent);
        modalRef.componentInstance.my_modal_title = 'TYRE OPTIONS';
        modalRef.componentInstance.vehicleTyreSearch = childVehicleTyre.vehicleTyreSearch.z55TyreInfo;
        modalRef.result.then(function (result) {
        }).catch(function (error) {
            // console.log(error);
        });
    };
    TyreSearchComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TyreSearchComponent.prototype, "childVehicleTyreSearch", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Boolean)
    ], TyreSearchComponent.prototype, "childIsEmptyTyreSearch", void 0);
    TyreSearchComponent = __decorate([
        core_1.Component({
            selector: 'tyre-search',
            template: require('./tyresearch.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            ng_bootstrap_1.NgbModal])
    ], TyreSearchComponent);
    return TyreSearchComponent;
}());
exports.TyreSearchComponent = TyreSearchComponent;
