"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoPreferredRetailerOvrvwComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var preferredretailermodal_component_1 = require("./../../profile/preferredretailer/preferredretailermodal/preferredretailermodal.component");
var service_dealer_service_1 = require("@/_services/service-dealer.service");
var storage_service_1 = require("@/_services/storage-service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var NoPreferredRetailerOvrvwComponent = /** @class */ (function () {
    function NoPreferredRetailerOvrvwComponent(authenticationService, modalService, storageService, alertService, serviceDealerService) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.storageService = storageService;
        this.alertService = alertService;
        this.serviceDealerService = serviceDealerService;
        this.loading = false;
        this.selectYourDealerCode = new core_1.EventEmitter();
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
    }
    NoPreferredRetailerOvrvwComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    NoPreferredRetailerOvrvwComponent.prototype.openPreferredRetailerModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(preferredretailermodal_component_1.PreferredRetailerModalComponent);
        modalRef.componentInstance.my_modal_title = 'PREFERRED RETAILER EDIT';
        modalRef.componentInstance.dealerId = this.storageService.getDealerId();
        modalRef.result.then(function (result) {
            _this.serviceDealerService.updateServiceDealer(result)
                .subscribe(function (data) {
                _this.ngOnInit();
                _this.selectYourDealerCode.emit(result);
                console.log("dealer results ::; ", result);
                _this.alertService.success('Retail updated Successful', true);
            }, function (error) {
                _this.alertService.error(error);
            });
        }).catch(function (error) {
            //  console.log(error);
        });
    };
    NoPreferredRetailerOvrvwComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    NoPreferredRetailerOvrvwComponent.prototype.ngOnInit = function () {
    };
    __decorate([
        core_1.Output(),
        __metadata("design:type", Object)
    ], NoPreferredRetailerOvrvwComponent.prototype, "selectYourDealerCode", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], NoPreferredRetailerOvrvwComponent.prototype, "childServiceDealer", void 0);
    NoPreferredRetailerOvrvwComponent = __decorate([
        core_1.Component({
            selector: 'no-preferred-retailer-ovrvw',
            template: require('./nopreferredretailer-ovrvw.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            ng_bootstrap_1.NgbModal,
            storage_service_1.StorageService,
            _services_1.AlertService,
            service_dealer_service_1.ServiceDealerService])
    ], NoPreferredRetailerOvrvwComponent);
    return NoPreferredRetailerOvrvwComponent;
}());
exports.NoPreferredRetailerOvrvwComponent = NoPreferredRetailerOvrvwComponent;
