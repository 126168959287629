"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferredRetailerComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var preferredretailermodal_component_1 = require("./preferredretailermodal/preferredretailermodal.component");
var service_dealer_service_1 = require("@/_services/service-dealer.service");
var storage_service_1 = require("@/_services/storage-service");
var dealer_list_service_1 = require("@/_services/dealer-list.service");
var insertid_mdl_component_1 = require("../../_components/ondemand/insertid_mdl/insertid_mdl.component");
var PreferredRetailerComponent = /** @class */ (function () {
    function PreferredRetailerComponent(authenticationService, dealerListService, userService, modalService, serviceDealerService, storageService, alertService, router) {
        this.authenticationService = authenticationService;
        this.dealerListService = dealerListService;
        this.userService = userService;
        this.modalService = modalService;
        this.serviceDealerService = serviceDealerService;
        this.storageService = storageService;
        this.alertService = alertService;
        this.router = router;
        this.display = 'none';
        this.isNavShown = false;
        this.noDealer = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
    }
    PreferredRetailerComponent.prototype.toggleNavShow = function () {
        this.isNavShown = !this.isNavShown;
    };
    PreferredRetailerComponent.prototype.ngOnInit = function () {
        this.loadServiceDealer();
    };
    PreferredRetailerComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    PreferredRetailerComponent.prototype.loadServiceDealer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var serviceDealerObservable;
            var _this = this;
            return __generator(this, function (_a) {
                if (this.storageService.getDealerId() === null || this.storageService.getDealerId() === undefined) {
                    this.noDealer = true;
                    this.preferredDealerMessage = "Please select your Preferred Retailer:";
                }
                else {
                    this.noDealer = false;
                    serviceDealerObservable = this.dealerListService.getDealerAgent(this.get_gcdm_config['dealer_brand'], this.storageService.getDealerId());
                    serviceDealerObservable.subscribe(function (serviceDealer) {
                        _this.serviceDealer = serviceDealer;
                        _this.loading = true;
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    PreferredRetailerComponent.prototype.openPreferredRetailerModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(preferredretailermodal_component_1.PreferredRetailerModalComponent);
        modalRef.componentInstance.my_modal_title = 'Choose / Select your preferred Retailer?';
        modalRef.componentInstance.dealerId = this.storageService.getDealerId();
        modalRef.result.then(function (result) {
            _this.serviceDealerService.updateServiceDealer(result)
                .subscribe(function (data) {
                _this.ngOnInit();
                _this.alertService.success('Preferred Retailer updated successfully', true);
            }, function (error) {
                _this.alertService.error(error);
            });
        }).catch(function (error) {
            //  console.log(error);
        });
    };
    PreferredRetailerComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    PreferredRetailerComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    PreferredRetailerComponent = __decorate([
        core_1.Component({
            template: require('./preferredretailer.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            dealer_list_service_1.DealerListService,
            _services_1.UserService,
            ng_bootstrap_1.NgbModal,
            service_dealer_service_1.ServiceDealerService,
            storage_service_1.StorageService,
            _services_1.AlertService,
            router_1.Router])
    ], PreferredRetailerComponent);
    return PreferredRetailerComponent;
}());
exports.PreferredRetailerComponent = PreferredRetailerComponent;
