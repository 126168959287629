"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var profilemodal_component_1 = require("./profilemodal/profilemodal.component");
var contactdetailsmodal_component_1 = require("./contactdetailsmodal/contactdetailsmodal.component");
var logindetailsmodal_component_1 = require("./logindetailsmodal/logindetailsmodal.component");
var storage_service_1 = require("@/_services/storage-service");
var insertid_mdl_component_1 = require("../_components/ondemand/insertid_mdl/insertid_mdl.component");
var common_1 = require("@angular/common");
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(authenticationService, userService, modalService, storageService, alertService, router) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.modalService = modalService;
        this.storageService = storageService;
        this.alertService = alertService;
        this.router = router;
        this.display = 'none';
        this.loading = false;
        this.isNavShown = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
    }
    ProfileComponent.prototype.toggleNavShow = function () {
        this.isNavShown = !this.isNavShown;
    };
    ProfileComponent.prototype.ngOnInit = function () {
        if (this.storageService.getOndemandDone() === 'dont-show') {
            this.hideOndemand = 'dont-show';
        }
        this.loadCustomerDetails();
    };
    ProfileComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    ProfileComponent.prototype.listTilte = function (title) {
        if (title === 'SAL_MR') {
            return 'Mr';
        }
        if (title === 'SAL_MS') {
            return 'Ms';
        }
        if (title === 'SAL_MRS') {
            return 'Mrs';
        }
        if (title === 'SAL_MISS') {
            return 'Miss';
        }
        if (title === 'SAL_DR') {
            return 'Dr';
        }
    };
    ProfileComponent.prototype.loadCustomerDetails = function () {
        var _this = this;
        this.loading = true;
        var datePipe = new common_1.DatePipe("en-US");
        var userServiceObservable = this.userService.getGcdmCustomerProfile();
        userServiceObservable.subscribe(function (customerDetails) {
            customerDetails.forEach(function (o, i) {
                _this.customerDetails = o.businessPartner;
                if (_this.customerDetails !== undefined) {
                    _this.salutation = _this.listTilte(_this.customerDetails.salutation);
                    _this.givenName = _this.customerDetails.givenName;
                    _this.surname = _this.customerDetails.surname;
                    _this.birthday = _this.customerDetails.birthday !== undefined ? datePipe.transform(new Date(_this.customerDetails.birthday), "yyyy-MM-dd") : "";
                    _this.correspondLanguageISO = _this.customerDetails.correspondLanguageISO;
                }
                _this.userAccount = o.userAccount;
                if (_this.customerDetails.profile !== undefined) {
                    if (_this.customerDetails.profile.personalIdentifications !== undefined) {
                        _this.customerDetails.profile.personalIdentifications.personalIdentifications.forEach(function (o, i) {
                            _this.idNumber = o.id;
                        });
                    }
                }
                if (_this.customerDetails.communications !== undefined) {
                    if (_this.customerDetails.communications.communicationPhones !== undefined) {
                        var isPresent = _this.customerDetails.communications.communicationPhones.find(function (p) { return p.communicationType === 'MOB' && p.preferred; });
                        if (isPresent) {
                            _this.phonenumber = isPresent.value;
                        }
                        else {
                            _this.phonenumber = "";
                        }
                    }
                    if (_this.customerDetails.communications.communicationEMails !== undefined) {
                        var isPresent = _this.customerDetails.communications.communicationEMails.find(function (p) { return p.preferred; });
                        if (isPresent) {
                            _this.emailAddress = isPresent.value;
                        }
                        // else {
                        //     if(isPresent.value !== undefined){
                        //         this.emailAddress = isPresent.value;
                        //     }
                        // }
                    }
                }
                if (_this.customerDetails.addresses !== undefined) {
                    _this.customerDetails.addresses.addresses.forEach(function (o, i) {
                        if (o.preferred) {
                            _this.city = o.city;
                            _this.country = o.country;
                            _this.districtName = o.districtName;
                            _this.postalCode = o.postalCode;
                            _this.strSuppl1 = o.strSuppl1;
                            _this.street = o.street;
                        }
                    });
                }
                if (_this.userAccount !== undefined) {
                    _this.userAccountEmail = _this.userAccount.mail;
                }
            });
        });
    };
    ProfileComponent.prototype.openProfileModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(profilemodal_component_1.ProfileModalComponent);
        modalRef.componentInstance.my_modal_title = 'PROFILE EDIT';
        modalRef.result.then(function (result) {
            _this.storageService.setGcdmCustTitle(_this.listTilte(result.title));
            _this.storageService.setGcdmCustNameLast(result.surname);
            _this.storageService.setGcdmCustNameFirst(result.names);
            _this.userService.updateCustomerProfile(result)
                .subscribe(function (data) {
                _this.alertService.success('Profile updated successfully', true);
                _this.ngOnInit();
            });
        }).catch(function (error) {
        });
    };
    ProfileComponent.prototype.openContactDetailsModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(contactdetailsmodal_component_1.ContactDetailsModalComponent);
        modalRef.componentInstance.my_modal_title = 'CONTACT DETAILS EDIT';
        modalRef.result.then(function (result) {
            _this.userService.updateContactDetails(result)
                .subscribe(function (data) {
                _this.alertService.success('Contact Details updated successfully', true);
                _this.ngOnInit();
            });
        }).catch(function (error) {
        });
    };
    ProfileComponent.prototype.openLogingDetailsModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(logindetailsmodal_component_1.LoginDetailsModalComponent);
        modalRef.componentInstance.my_modal_title = 'LOGIN DETAILS EDIT';
        modalRef.result.then(function (result) {
            console.log(result);
            _this.userService.updatepassword(result)
                .subscribe(function (data) {
                _this.alertService.success('Login Details updated successfully', true);
                _this.ngOnInit();
            }, function (error) {
                _this.alertService.error(_this.getErrorDetails(error));
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    ProfileComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    ProfileComponent.prototype.getErrorDetails = function (response) {
        if (response.indexOf("PASSWORD_HISTORY") !== -1) {
            return "This password has already been used";
        }
        else if (response.indexOf("PASSWORD_COMPLEXITY") !== -1) {
            return "Password does not meet complexity, it should include uppercase, numbers and special characters.";
        }
        else {
            return "Incorrect password.";
        }
    };
    ProfileComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    ProfileComponent = __decorate([
        core_1.Component({ template: require('./profile.component.html') }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            ng_bootstrap_1.NgbModal,
            storage_service_1.StorageService,
            _services_1.AlertService,
            router_1.Router])
    ], ProfileComponent);
    return ProfileComponent;
}());
exports.ProfileComponent = ProfileComponent;
