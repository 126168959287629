"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleAccountService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var _services_1 = require("@/_services");
var VehicleAccountService = /** @class */ (function () {
    function VehicleAccountService(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    VehicleAccountService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, _services_1.AuthenticationService])
    ], VehicleAccountService);
    return VehicleAccountService;
}());
exports.VehicleAccountService = VehicleAccountService;
