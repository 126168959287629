"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddVehicleModalComponent = void 0;
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var platform_browser_1 = require("@angular/platform-browser");
var AddVehicleModalComponent = /** @class */ (function () {
    function AddVehicleModalComponent(activeModal, sanitizer, formBuilder) {
        this.activeModal = activeModal;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.loading = false;
        this.submitted = false;
        this.isHidden = true;
        this.createForm();
    }
    AddVehicleModalComponent.prototype.createForm = function () {
        this.AddVehicleForm = this.formBuilder.group({
            contactpreferences: ["", forms_1.Validators.required],
            communicationMethod: ["", forms_1.Validators.required],
            chassisnumber: ["", forms_1.Validators.required],
            registrationnumber: ["", forms_1.Validators.required],
            vin: '',
            year: ["", forms_1.Validators.required],
            model: ["", forms_1.Validators.required],
            acquireddate: '',
            acquiredkilometres: '',
            currentkilometres: '',
            interiortrim: '',
            colour: ''
        });
    };
    AddVehicleModalComponent.prototype.submitForm = function () {
        this.submitted = true;
        if (this.AddVehicleForm.invalid) {
            this.loading = false;
            return;
        }
        this.activeModal.close(this.AddVehicleForm.value);
    };
    Object.defineProperty(AddVehicleModalComponent.prototype, "f", {
        get: function () {
            return this.AddVehicleForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    AddVehicleModalComponent.prototype.toggleVisibility = function (event) {
        this.isHidden = false;
        this.selecttedComms = event.target.value;
    };
    AddVehicleModalComponent.prototype.ngOnInit = function () {
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], AddVehicleModalComponent.prototype, "my_modal_title", void 0);
    AddVehicleModalComponent = __decorate([
        core_1.Component({
            selector: 'addvehiclemodal',
            template: require('./addvehiclemodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            platform_browser_1.DomSanitizer,
            forms_1.FormBuilder])
    ], AddVehicleModalComponent);
    return AddVehicleModalComponent;
}());
exports.AddVehicleModalComponent = AddVehicleModalComponent;
