"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TechnicalSpecsComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var TechnicalSpecsComponent = /** @class */ (function () {
    function TechnicalSpecsComponent(authenticationService, userService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.loading = false;
        this.vehicleTech = [];
        this.getTechPdf = function (modelCode) {
            var url = this.zaonl_env_hostname + '/dws/VTS/servlet/VtsPdfUI?modelCode=' + modelCode;
            window.open(url, '_blank');
        };
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.zaonl_env_hostname = process.env.zaonl_env_hostname;
    }
    TechnicalSpecsComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    TechnicalSpecsComponent.prototype.ngOnInit = function () {
        //     this.vehicleTech = this.childVehicletTech.vehicleTechs;
        // console.log("childVehicletTech :::::::::: ",this.childVehicletTech.vehicleTechs);
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TechnicalSpecsComponent.prototype, "childVehicletTech", void 0);
    TechnicalSpecsComponent = __decorate([
        core_1.Component({
            selector: 'technical-specs',
            template: require('./technicalspecs.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService])
    ], TechnicalSpecsComponent);
    return TechnicalSpecsComponent;
}());
exports.TechnicalSpecsComponent = TechnicalSpecsComponent;
