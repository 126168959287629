"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOptionsModalComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var platform_browser_1 = require("@angular/platform-browser");
var _services_1 = require("@/_services");
var contactadealer_component_1 = require("@/_components/contacadealermodal/contactadealer.component");
var storage_service_1 = require("@/_services/storage-service");
var ServiceOptionsModalComponent = /** @class */ (function () {
    function ServiceOptionsModalComponent(activeModal, formBuilder, sanitizer, authenticationService, storageService, modalService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.sanitizer = sanitizer;
        this.authenticationService = authenticationService;
        this.storageService = storageService;
        this.modalService = modalService;
        this.isShown = false;
        this.services = [];
        this.totalValue = 0;
        this.serviceItems = [];
        this.formNotes = [];
        this.updateServicesSelected = function (id, iterm, event, servRest) {
            var _this = this;
            var isPresent = this.services.find(function (p) { return p.inclPrice === servRest.inclPrice; });
            if (event.target.checked) {
                this.services.push(iterm);
                this.sumAddition(iterm.inclPrice);
                if (!isPresent) {
                    this.services.unshift(servRest);
                    this.sumAddition(servRest.inclPrice);
                }
            }
            this.services.forEach(function (o, i) {
                if (event.target.checked === false && o.defects.id === id) {
                    if (_this.services.length === 2) {
                        if (isPresent) {
                            _this.sumSubtraction(isPresent.inclPrice);
                            _this.services.splice(_this.services.indexOf(isPresent), 1);
                            _this.services = [];
                        }
                    }
                    _this.sumSubtraction(o.inclPrice);
                    _this.services.splice(_this.services.indexOf(o), 1);
                }
            });
        };
        this.showServiceOptionsContactModal = function () {
            var _this = this;
            this.services.forEach(function (o, i) {
                _this.formNotes.push({ Parts: o.defects.heading, PartPrice: o.inclPrice });
            });
            var modalRef = this.modalService.open(contactadealer_component_1.ContactADealerComponent);
            modalRef.componentInstance.my_modal_title = 'CONTACT A RETAILER';
            this.notes = JSON.stringify({ notes: this.formNotes, TotalPrice: this.totalValue });
            this.dealer = this.storageService.getDealerId();
            this.bodyNo = this.storageService.getVin();
            var apiKey = this.get_gcdm_config['service_kit_api_key'];
            var url = 'assets/page/contactForm.html';
            modalRef.componentInstance.urlSafe = this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url + "?wccomment=" + "VIN number: " + this.bodyNo + " " + this.notes + "&wcprefdealer=" + this.dealer + "&apiKey=" + apiKey);
            modalRef.result.then(function (result) {
            }).catch(function (error) {
            });
            // this.services.forEach((o, i) => {
            // });
            this.activeModal.close();
        };
        this.onExpand = function (iterm) {
            this.toggleId = iterm.defects.id;
            this.isShown = !this.isShown;
        };
        // this.createForm();
    }
    ServiceOptionsModalComponent.prototype.sumAddition = function (inclPrice) {
        this.totalValue = this.totalValue + Number(inclPrice);
    };
    ServiceOptionsModalComponent.prototype.sumSubtraction = function (inclPrice) {
        this.totalValue = this.totalValue - Number(inclPrice);
    };
    ServiceOptionsModalComponent.prototype.createForm = function () {
        this.ServiceOptionsEditForm = this.formBuilder.group({
            serviceoption1: '',
            serviceoption2: '',
        });
    };
    ServiceOptionsModalComponent.prototype.submitForm = function () {
        this.activeModal.close(this.ServiceOptionsEditForm.value);
    };
    ServiceOptionsModalComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ServiceOptionsModalComponent.prototype, "my_modal_title", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ServiceOptionsModalComponent.prototype, "serviceOptions", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ServiceOptionsModalComponent.prototype, "servRest", void 0);
    ServiceOptionsModalComponent = __decorate([
        core_1.Component({
            selector: 'serviceoptionsmodal',
            template: require('./serviceoptionsmodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            forms_1.FormBuilder,
            platform_browser_1.DomSanitizer,
            _services_1.AuthenticationService,
            storage_service_1.StorageService,
            ng_bootstrap_1.NgbModal])
    ], ServiceOptionsModalComponent);
    return ServiceOptionsModalComponent;
}());
exports.ServiceOptionsModalComponent = ServiceOptionsModalComponent;
