"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactADealerComponent = void 0;
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var platform_browser_1 = require("@angular/platform-browser");
var _services_1 = require("@/_services");
var ContactADealerComponent = /** @class */ (function () {
    function ContactADealerComponent(activeModal, sanitizer, authenticationService) {
        this.activeModal = activeModal;
        this.sanitizer = sanitizer;
        this.authenticationService = authenticationService;
        this.web_api = process.env.webpai_hostname;
    }
    // loadScripts() {
    //       console.log("loading scripts ::::: ");
    //     const dynamicScripts = [
    //       //scripts to be loaded
    //       "src/_content/js/contacadealer-first-load.js",
    //       this.web_api+"/js/v1/cpwebapi.min.js",
    //       "src/_content/js/contacadealer-second-load.js"
    //     ];
    //     for (let i = 0; i < dynamicScripts.length; i++) {
    //       const node = document.createElement('script');
    //       node.src = dynamicScripts[i];
    //       node.type = 'text/javascript';
    //       node.async = false;
    //       document.getElementById('scripts').appendChild(node);
    //     }
    //     console.log("loading scripts ::::: ", dynamicScripts);
    //   }
    ContactADealerComponent.prototype.ngOnInit = function () {
        // this.get_gcdm_config = this.authenticationService.get_config();
        // this.env = this.web_api;
        // this.dealer = this.storageService.getDealerId();
        // this.bodyNo = this.storageService.getVin();
        // console.log("this.webapi :::::::: ",this.apiKey)
        // const url = this.get_gcdm_config['service_kit_api_key_host'];
        // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ContactADealerComponent.prototype, "my_modal_title", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ContactADealerComponent.prototype, "formNotes", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ContactADealerComponent.prototype, "apiKey", void 0);
    ContactADealerComponent = __decorate([
        core_1.Component({
            selector: 'contact-a-dealer',
            template: require('./contactadealer.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            platform_browser_1.DomSanitizer,
            _services_1.AuthenticationService])
    ], ContactADealerComponent);
    return ContactADealerComponent;
}());
exports.ContactADealerComponent = ContactADealerComponent;
