"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndOfTermComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
// import {VehicleMotorplan} from "@/_models/vehicle-motorplan";
// import {VehicleMotorplanService} from "@/_services/vehicle-motorplan.service";
var _services_1 = require("@/_services");
var EndOfTermComponent = /** @class */ (function () {
    function EndOfTermComponent(authenticationService, userService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.loading = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        // this.loadVehicleMotorplan();
    }
    // loadVehicleMotorplan() {
    //     this.loading = true;
    //     let vehicleMotorplanObservable = this.vehicleMotorplanService.getVehicleMotorplan("WBAAN92020NB02667");
    //     vehicleMotorplanObservable.subscribe((vehicleMotorplan: VehicleMotorplan) => {this.vehicleMotorplan = vehicleMotorplan; console.log("this.vehicleMotorplan :: ",vehicleMotorplan);});
    //     this.setVehicleMotorplan(vehicleMotorplanObservable);
    // }
    // private setVehicleMotorplan(vehicleMotorplan: any) {
    //     this.vehicleMotorplan = vehicleMotorplan;
    //     this.loading = false
    // }
    EndOfTermComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    EndOfTermComponent.prototype.ngOnInit = function () {
    };
    EndOfTermComponent = __decorate([
        core_1.Component({
            selector: 'end-of-term',
            template: require('./endofterm.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService])
    ], EndOfTermComponent);
    return EndOfTermComponent;
}());
exports.EndOfTermComponent = EndOfTermComponent;
