"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MotorplanContractComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var renewmotorplanmodal_component_1 = require("./renewmotorplanmodal/renewmotorplanmodal.component");
var _services_1 = require("@/_services");
var vehicle_motorplan_service_1 = require("@/_services/vehicle-motorplan.service");
var storage_service_1 = require("@/_services/storage-service");
var vehicle_cosy_image_service_1 = require("@/_services/vehicle-cosy.image.service");
var MotorplanContractComponent = /** @class */ (function () {
    function MotorplanContractComponent(authenticationService, vehicleMotorplanService, vehicleCosyImageService, storageService, modalService) {
        this.authenticationService = authenticationService;
        this.vehicleMotorplanService = vehicleMotorplanService;
        this.vehicleCosyImageService = vehicleCosyImageService;
        this.storageService = storageService;
        this.modalService = modalService;
        this.motorPlanOpationals = new core_1.EventEmitter();
        this.showMotorPlanOptional = false;
        this.vehicleCosyImages = [];
        this.onChange = function (event) {
            this.contractTypeSelected = event.target.value;
        };
        this.showMotorPlanOptional = false;
    }
    MotorplanContractComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    MotorplanContractComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
        this.loginBrand = this.get_gcdm_config['brand'];
        this.showMotorPlanOptional = false;
    };
    MotorplanContractComponent.prototype.openRenewMotorplanModal = function (VehicleMotorplan) {
        var modalRef = this.modalService.open(renewmotorplanmodal_component_1.RenewMotorplanModalComponent);
        modalRef.componentInstance.my_modal_title = 'EXTEND MOTORPLAN';
        modalRef.componentInstance.vehicleMotorplan = VehicleMotorplan;
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    MotorplanContractComponent.prototype.openMotorplanOptional = function (VehicleMotorplan) {
        this.motorPlanOpationals.emit(VehicleMotorplan.vehicleInfo.vinNumber);
    };
    MotorplanContractComponent.prototype.redirectToMotorPlanOptionalApp = function (VehicleMotorplan) {
        var _this = this;
        var vehicleCosyImageObservable = this.vehicleCosyImageService.getVehicleCosyImageForMpanOpt(VehicleMotorplan.vehicleInfo.vinNumber);
        vehicleCosyImageObservable.subscribe(function (vehicleCosyImage) {
            _this.vehicleCosyImage = vehicleCosyImage;
            _this.postToMotorplanOptional(_this.vehicleCosyImage, VehicleMotorplan);
        }, function (error) {
            _this.postToMotorplanOptional("", VehicleMotorplan);
        });
    };
    MotorplanContractComponent.prototype.postToMotorplanOptional = function (vehicleCosyImage, VehicleMotorplan) {
        var _this = this;
        var vehicleMotorplanServiceObservable = this.vehicleMotorplanService.postToOptionalContractApp(VehicleMotorplan.motoplan, VehicleMotorplan.vehicleInfo, VehicleMotorplan.motoplan.motorPlanOptional, vehicleCosyImage, VehicleMotorplan.vehicleInfo.vinNumber, this.storageService.getDealerId(), this.storageService.getEmailValue());
        vehicleMotorplanServiceObservable.subscribe(function (transactToMotorPlanApp) {
            transactToMotorPlanApp;
            if (transactToMotorPlanApp) {
                window.open(_this.get_gcdm_config['CP_hostname'] + "/dashboard/home?transactionId=" + transactToMotorPlanApp.transactionId, '_self');
            }
        });
    };
    MotorplanContractComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], MotorplanContractComponent.prototype, "childVehicleMotorplan", void 0);
    __decorate([
        core_1.Output(),
        __metadata("design:type", Object)
    ], MotorplanContractComponent.prototype, "motorPlanOpationals", void 0);
    MotorplanContractComponent = __decorate([
        core_1.Component({
            selector: 'motorplan-contract',
            template: require('./motorplancontract.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            vehicle_motorplan_service_1.VehicleMotorplanService,
            vehicle_cosy_image_service_1.VehicleCosyImageService,
            storage_service_1.StorageService,
            ng_bootstrap_1.NgbModal])
    ], MotorplanContractComponent);
    return MotorplanContractComponent;
}());
exports.MotorplanContractComponent = MotorplanContractComponent;
