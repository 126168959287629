"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var UserService = /** @class */ (function () {
    function UserService(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.contactPolicyConsents = [];
        this.resendActivationMail = function (mail) {
            return this.http.post(this.gcdm_env_hostname + "/gcdm/public/" + this.get_gcdm_config['businessContext'] + "/workflows/mailAdministrations/customers/resend?loginId=" + mail)
                .pipe(operators_1.map(function (data) { return data; }));
        };
        this.gcdmProfileSubject = new rxjs_1.BehaviorSubject(JSON.parse(localStorage.getItem('gcdmProfile')));
        this.gcdmProfile = this.gcdmProfileSubject.asObservable();
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_host = process.env.gcdm_host;
        this.gcdm_key_id = process.env.gcdm_key_id;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    UserService.prototype.getGcdmCustomerProfile = function () {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        return this.http.get(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers", configs)
            .pipe(operators_1.map(function (data) { return data; }), operators_1.delay(1000));
    };
    UserService.prototype.getGcdmCustomerProfileOnePage = function (access_token) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + access_token });
        var configs = { headers: headers };
        return this.http.get(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers", configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    UserService.prototype.updateCustomerProfile = function (customerProfile) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        var custProfileObjectJson = {
            "businessPartner": {
                "salutation": customerProfile.title,
                "surname": customerProfile.surname,
                "givenName": customerProfile.names,
                // "birthday":customerProfile.dob,
                "partnerCategory": "PERSON"
            }
        };
        return this.http.put(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers", custProfileObjectJson, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ;
    // updateLoginDetails(loginDetails: any) {
    //     var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    // 	var configs = { headers: headers };
    // 	var loginDetailsObjectJson = {  
    // 	    	"userAccount":
    // 	    	{  
    // 		      "mail":loginDetails.email,
    // 		      "password":loginDetails.password
    // 			}
    // 			//    ,
    // 	    	// "businessPartner":
    // 	    	// {  
    // 		    //   "salutation":user.formOfAddress,
    // 		    //   "surname":user.surname,
    // 		    //   "givenName":user.firstName,
    // 		    //   "partnerCategory":"PERSON"     
    // 			//    },"contactPolicyConsents":contactPolicyConsents
    // 			// }
    // 	}
    // 	console.log("loginDetailsObjectJson ::: ",loginDetailsObjectJson)
    //     return this.http.put(this.gcdm_env_hostname+"/gcdm/protected/v4/"+ this.get_gcdm_config['businessContext']+"/customers", loginDetailsObjectJson, configs)
    //         .pipe(map(function (data) { return data; }));
    // };
    UserService.prototype.updateContactDetails = function (customerContact) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        var strSuppl1 = customerContact.strSuppl1 !== undefined ? customerContact.strSuppl1 : "";
        var custContactObjectJson = {
            "businessPartner": {
                "communications": {
                    "communicationEMails": [
                        {
                            "communicationStatus": "PRIVATE",
                            "value": customerContact.email,
                            "communicationType": "EMAIL",
                            "preferred": true
                        }
                    ],
                    "communicationPhones": [
                        {
                            "communicationStatus": "PRIVATE",
                            "communicationType": "MOB",
                            "value": customerContact.mobilenumber,
                            "preferred": true
                        }
                    ]
                },
                "addresses": {
                    "addresses": [
                        {
                            "addressStatus": "PRIVATE",
                            "city": customerContact.city,
                            "districtName": customerContact.districtName,
                            "postalCode": customerContact.postalcode,
                            "strSuppl1": strSuppl1,
                            "street": customerContact.street,
                            "country": customerContact.CountryIso2,
                            "preferred": true
                        }
                    ]
                },
                "partnerCategory": "PERSON"
            }
        };
        return this.http.put(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers", custContactObjectJson, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ;
    UserService.prototype.updateLoginEmail = function (customerProfile) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        var customerProfileJson = {
            "customerProfile": customerProfile
        };
        return this.http.put(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers/userAccount/mail", customerProfileJson, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ;
    UserService.prototype.updatepassword = function (customerPassword) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'text/plain',
            'Accept': 'application/json, text/plain, */*', 'X-c2b-Authorization': customerPassword.oldPassword,
            'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        return this.http.put(this.gcdm_env_hostname + "/gcdm/protected/" + this.get_gcdm_config['businessContext'] + "/customers/userAccount/password", customerPassword.password, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    ;
    Object.defineProperty(UserService.prototype, "gcdmProfileValue", {
        get: function () {
            return this.gcdmProfileSubject.value;
        },
        enumerable: false,
        configurable: true
    });
    UserService.prototype.register = function (user, contactPolicyConsents) {
        var regObjectJson = {
            "userAccount": {
                "mail": user.mail,
                "password": user.password
            },
            "businessPartner": {
                "salutation": user.formOfAddress,
                "surname": user.surname,
                "givenName": user.firstName,
                "partnerCategory": "PERSON"
            }, "contactPolicyConsents": contactPolicyConsents
        };
        return this.http.post(this.gcdm_env_hostname + "/gcdm/public/v4/" + this.get_gcdm_config['businessContext'] + "/customers", regObjectJson)
            .pipe(operators_1.map(function (results) {
            localStorage.setItem('gcdmProfile', JSON.stringify(results));
            localStorage.setItem('registeredEmail', user.mail);
            return results;
        }));
    };
    UserService.prototype.activate = function (token) {
        return this.http.post(this.gcdm_env_hostname + "/gcdm/public/" + this.get_gcdm_config['businessContext'] + "/workflows/mailAdministrations/tokens/" + token + "/activation", true);
    };
    UserService.prototype.checkEmail = function (email) {
        return this.http.get(this.gcdm_env_hostname + "/gcdm/public/" + this.get_gcdm_config['businessContext'] + "/userAccounts?loginId=" + email);
    };
    UserService.prototype.resetPassword = function (email) {
        localStorage.setItem('resetEmail', email);
        return this.http.post(this.gcdm_env_hostname + "/gcdm/public/" + this.get_gcdm_config['businessContext'] + "/customers/userAccount/password/reset?loginId=" + email, true);
    };
    UserService.prototype.policyStoreReg = function () {
        var headers = new http_1.HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json',
            'KeyId': this.gcdm_key_id });
        var configs = { headers: headers };
        return this.http.get(this.gcdm_env_hostname + "/pm2/pm-document-service/api/v1/documents/Privacy_Statement/ZA", configs)
            .pipe(operators_1.map(function (data) { return data; }), operators_1.delay(2000));
    };
    UserService.prototype.findAddress = function (addressSearch) {
        var Key = "WT81-UC77-BK95-BN59", IsMiddleware = false, Origin = "", Countries = "ZAF", Limit = "10", Language = "en";
        var params = '';
        params += "&Key=" + encodeURIComponent(Key);
        params += "&Text=" + encodeURIComponent(addressSearch);
        params += "&IsMiddleware=" + encodeURIComponent(IsMiddleware);
        params += "&Container=" + encodeURIComponent('');
        params += "&Origin=" + encodeURIComponent(Origin);
        params += "&Countries=" + encodeURIComponent(Countries);
        params += "&Limit=" + encodeURIComponent(Limit);
        params += "&Language=" + encodeURIComponent(Language);
        var headers = new http_1.HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
        var configs = { headers: headers };
        return this.http.post("https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws", params, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    UserService.prototype.retrieveAddress = function (Id) {
        var Key = "WT81-UC77-BK95-BN59";
        var Field1Format = "";
        var params = '';
        params += "&Key=" + encodeURIComponent(Key);
        params += "&Id=" + encodeURIComponent(Id);
        params += "&Field1Format=" + encodeURIComponent(Field1Format);
        var headers = new http_1.HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
        var configs = { headers: headers };
        return this.http.post("https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws", params, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    UserService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, _services_1.AuthenticationService])
    ], UserService);
    return UserService;
}());
exports.UserService = UserService;
