"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoMotorplanContractComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var blackoilmodal_component_1 = require("./blackoilmodal/blackoilmodal.component");
var _services_1 = require("@/_services");
var NoMotorplanContractComponent = /** @class */ (function () {
    function NoMotorplanContractComponent(modalService, authenticationService) {
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.loginBrand = this.get_gcdm_config['brand'];
    }
    NoMotorplanContractComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    NoMotorplanContractComponent.prototype.ngOnInit = function () {
    };
    NoMotorplanContractComponent.prototype.openBlackOilModal = function () {
        var modalRef = this.modalService.open(blackoilmodal_component_1.BlackOilModalComponent);
        modalRef.componentInstance.my_modal_title = 'MY ' + this.get_brand + ' PLANS';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    NoMotorplanContractComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    NoMotorplanContractComponent = __decorate([
        core_1.Component({
            selector: 'no-motorplan-contract',
            template: require('./nomotorplancontract.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbModal,
            _services_1.AuthenticationService])
    ], NoMotorplanContractComponent);
    return NoMotorplanContractComponent;
}());
exports.NoMotorplanContractComponent = NoMotorplanContractComponent;
