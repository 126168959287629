"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.faqsComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var faqs1_component_1 = require("./faqs1/faqs1.component");
var faqs2_component_1 = require("./faqs2/faqs2.component");
var faqs3_component_1 = require("./faqs3/faqs3.component");
var faqsComponent = /** @class */ (function () {
    function faqsComponent(router) {
        this.router = router;
        this.showSideBar = true;
    }
    faqsComponent.prototype.ngOnInit = function () {
        if (this.router.url == '/faqs' || this.router.url == '/faqs/applicationProceess' || this.router.url == '/faqs/settlements' || this.router.url == '/faqs/adjustments' || this.router.url == '/faqs/Natis' || this.router.url == '/faqs/selectEOT' || this.router.url == '/faqs/arrears' || this.router.url == '/faqs/legal' || this.router.url == '/faqs/EOT') {
            this.showSideBar = true;
        }
    };
    faqsComponent = __decorate([
        core_1.Component({ template: require('./faqs.component.html') }),
        core_1.NgModule({
            imports: [
                faqs1_component_1.faqs1Component,
                faqs2_component_1.faqs2Component,
                faqs3_component_1.faqs3Component
            ]
        }),
        __metadata("design:paramtypes", [router_1.Router])
    ], faqsComponent);
    return faqsComponent;
}());
exports.faqsComponent = faqsComponent;
