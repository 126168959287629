"use strict";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = void 0;
exports.environment = {
    production: true,
    API_DSP_BASE_URL: '',
    API_GCDM_BASE_URL: '',
    API_DLNET_BASE_URL: "",
    API_SOL_BASE_URL: "",
    API_SOL_MOT_BASE_URL: "",
    API_WEB_API_BASE_URL: "",
    BASIC: 'admin:CP@1pass',
    mockApi: false,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
