"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivacySettingsComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var commsmethodbmwmodal_component_1 = require("./commsmethodbmwmodal/commsmethodbmwmodal.component");
var commsmethodfsmodal_component_1 = require("./commsmethodfsmodal/commsmethodfsmodal.component");
var policy_store_consent_service_1 = require("@/_services/policy-store-consent.service");
var router_2 = require("@angular/router");
var storage_service_1 = require("@/_services/storage-service");
var common_1 = require("@angular/common");
var insertid_mdl_component_1 = require("../../_components/ondemand/insertid_mdl/insertid_mdl.component");
var forms_1 = require("@angular/forms");
var PrivacySettingsComponent = /** @class */ (function () {
    function PrivacySettingsComponent(authenticationService, userService, modalService, formBuilder, policyStoreConsentService, alertService, route, storageService, router) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.policyStoreConsentService = policyStoreConsentService;
        this.alertService = alertService;
        this.route = route;
        this.storageService = storageService;
        this.router = router;
        this.display = 'none';
        this.isDataLoaded = false;
        this.loading = false;
        this.isNavShown = false;
        this.contactPolicyConsentsDataNsc = [];
        this.contactPolicyConsentsDataSf = [];
        this.contactPolicyConsentsDataDisplayNsc = [];
        this.contactPolicyConsentsDataDisplaySf = [];
        this.contactPolicyConsent = [];
        this.contactConsentNsc = [];
        this.nscData = [];
        this.fsData = [];
        this.fsSaveData = [];
        this.nscSaveData = [];
        this.reducerFunction = function (data) {
            data["label"] = data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS' : data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS'
                : data["label"] = data.usageId === 'EMAIL' ? 'Email'
                    : data["label"] = data.usageId === 'MAIL' ? 'Post'
                        : data["label"] = data.usageId === 'ANALYTICS' ? 'Analytics' : data["label"] = data.usageId === 'IN_CAR' ? 'In-vehicle'
                            : data.usageId;
            return data;
        };
        this.currentUser = this.authenticationService.currentUserValue;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
        this.form = this.formBuilder.group({
            contactPolicyConsentsNsc: new forms_1.FormArray([]),
            contactPolicyConsentsSf: new forms_1.FormArray([])
        });
    }
    PrivacySettingsComponent.prototype.onCheckChange = function (obj) {
        var datePipe = new common_1.DatePipe("en-US");
        var minorVersion = 0;
        if (Number(this.storageService.getPolicyMinorVersion()) == 0) {
            minorVersion = obj.minorVersion;
        }
        else {
            minorVersion = Number(this.storageService.getPolicyMinorVersion());
        }
        var contactPolicyConsent = {
            consent: obj.consent,
            consentDate: datePipe.transform(new Date(), "yyy-MM-ddThh:mm:ss"),
            country: obj.country,
            language: obj.language,
            legalEntityId: obj.legalEntityId,
            majorVersion: obj.majorVersion,
            minorVersion: minorVersion,
            policyId: obj.policyId,
            touchpointId: 'CUSTOMER_PORTAL_WEB_SITE_ZA',
            purposeId: obj.purposeId,
            usageId: obj.usageId
        };
        return contactPolicyConsent;
    };
    PrivacySettingsComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    PrivacySettingsComponent.prototype.addCheckboxes = function (contactPolicyConsents) {
        return __awaiter(this, void 0, void 0, function () {
            var sortBeforeCheckForDup;
            var _this = this;
            return __generator(this, function (_a) {
                this.storageService.setNscPolicyList(contactPolicyConsents);
                contactPolicyConsents.forEach(function (o, i) {
                    if (o.legalEntityId === 'BMW_SOUTH_AFRICA_PTY_LTD_'
                        && o.policyId === "Privacy_Statement") {
                        _this.contactPolicyConsentsDataNsc.push(_this.reducerFunction(o));
                    }
                });
                sortBeforeCheckForDup = this.contactPolicyConsentsDataNsc.sort(function (a, b) { return new Date(b.consentDate) > new Date(a.consentDate) ? 1 : -1; });
                this.contactPolicyConsentsDataDisplayNsc = this.checkDuplicateInObject(sortBeforeCheckForDup);
                this.contactPolicyConsentsDataDisplayNsc = this.sortByLetter(this.contactPolicyConsentsDataDisplayNsc);
                this.contactPolicyConsentsDataDisplayNsc.forEach(function (o, i) {
                    _this.nscData.push(o);
                    var control = new forms_1.FormControl(o.consent === 'ACCEPT' ? true : false); // if item is ACCEPT set to true, else false       
                    _this.form.controls.contactPolicyConsentsNsc.push(control);
                });
                return [2 /*return*/];
            });
        });
    };
    PrivacySettingsComponent.prototype.sortByLetter = function (contactConsents) {
        return contactConsents.sort(function (a, b) {
            var nameA = a.usageId, nameB = b.usageId;
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
    };
    PrivacySettingsComponent.prototype.getTime = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, date != null ? new Date(date).getTime() : 0];
            });
        });
    };
    PrivacySettingsComponent.prototype.addCheckboxesForSf = function (contactPolicyConsents) {
        return __awaiter(this, void 0, void 0, function () {
            var sortBeforeCheckForDup;
            var _this = this;
            return __generator(this, function (_a) {
                this.storageService.setSfPolicyList(contactPolicyConsents);
                contactPolicyConsents.forEach(function (o, i) {
                    if (o.legalEntityId === 'BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_'
                        && o.policyId === "Privacy_Statement") {
                        _this.contactPolicyConsentsDataSf.push(_this.reducerFunction(o));
                    }
                });
                sortBeforeCheckForDup = this.contactPolicyConsentsDataSf.sort(function (a, b) { return new Date(b.consentDate) > new Date(a.consentDate) ? 1 : -1; });
                this.contactPolicyConsentsDataDisplaySf = this.checkDuplicateInObject(sortBeforeCheckForDup);
                this.contactPolicyConsentsDataDisplaySf = this.sortByLetter(this.contactPolicyConsentsDataDisplaySf);
                this.contactPolicyConsentsDataDisplaySf.forEach(function (o, i) {
                    _this.fsData.push(o);
                    var control = new forms_1.FormControl(o.consent === 'ACCEPT' ? true : false); // if item is ACCEPT set to true, else false       
                    _this.form.controls.contactPolicyConsentsSf.push(control);
                });
                return [2 /*return*/];
            });
        });
    };
    PrivacySettingsComponent.prototype.checkDuplicateInObject = function (inputArray) {
        var seenDuplicate = false, testObject = {};
        var returnItems = [];
        inputArray.map(function (item) {
            var itemPropertyName = item["usageId"];
            if (itemPropertyName in testObject) {
                testObject[itemPropertyName].duplicate = true;
                item.duplicate = true;
                seenDuplicate = true;
            }
            else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                returnItems.push(item);
            }
        });
        return returnItems;
    };
    PrivacySettingsComponent.prototype.toggleNavShow = function () {
        this.isNavShown = !this.isNavShown;
    };
    PrivacySettingsComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.getPolicy();
    };
    PrivacySettingsComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    PrivacySettingsComponent.prototype.openCommsMethodBMWModal = function () {
        var _this = this;
        var MethodOfCom = 'METHOD OF COMMUNICATION ' + this.get_brand + ' EDIT';
        var modalRef = this.modalService.open(commsmethodbmwmodal_component_1.CommsMethodBMWModalComponent);
        modalRef.componentInstance.my_modal_title = MethodOfCom;
        modalRef.result.then(function (result) {
            var consents = result.concat(_this.fsData);
            consents.forEach(function (o, i) {
                _this.nscSaveData.push(_this.onCheckChange(o));
            });
            _this.policyStoreConsentService.updatePolicyStoreConsent(_this.nscSaveData)
                .subscribe(function (data) {
                _this.alertService.success('Privacy settings updated successfully', true);
                _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                var currentUrl = _this.router.url;
                _this.router.navigateByUrl(currentUrl)
                    .then(function () {
                    _this.router.navigated = false;
                    _this.router.navigate([_this.router.url]);
                });
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    PrivacySettingsComponent.prototype.openCommsMethodFSModal = function () {
        var _this = this;
        var MethodOfComFin = 'METHOD OF COMMUNICATION ' + this.get_brand + ' FINANCIAL SERVICES EDIT';
        var modalRef = this.modalService.open(commsmethodfsmodal_component_1.CommsMethodFSModalComponent);
        modalRef.componentInstance.my_modal_title = MethodOfComFin;
        modalRef.result.then(function (result) {
            var consents = result.concat(_this.nscData);
            consents.forEach(function (o, i) {
                _this.fsSaveData.push(_this.onCheckChange(o));
            });
            _this.policyStoreConsentService.updatePolicyStoreConsent(_this.fsSaveData)
                .subscribe(function (data) {
                _this.alertService.success('Privacy settings updated successfully', true);
                _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
                var currentUrl = _this.router.url;
                _this.router.navigateByUrl(currentUrl)
                    .then(function () {
                    _this.router.navigated = false;
                    _this.router.navigate([_this.router.url]);
                });
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    PrivacySettingsComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    PrivacySettingsComponent.prototype.getPolicy = function () {
        var _this = this;
        this.route.snapshot.data['data'].forEach(function (o, i) {
            _this.contactPolicyConsent = o.contactPolicyConsents;
            _this.isDataLoaded = true;
            _this.addCheckboxes(_this.contactPolicyConsent);
            _this.addCheckboxesForSf(_this.contactPolicyConsent);
        });
    };
    PrivacySettingsComponent = __decorate([
        core_1.Component({
            template: require('./privacysettings.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            ng_bootstrap_1.NgbModal,
            forms_1.FormBuilder,
            policy_store_consent_service_1.PolicyStoreConsentService,
            _services_1.AlertService,
            router_2.ActivatedRoute,
            storage_service_1.StorageService,
            router_1.Router])
    ], PrivacySettingsComponent);
    return PrivacySettingsComponent;
}());
exports.PrivacySettingsComponent = PrivacySettingsComponent;
