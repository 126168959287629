"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TyreOptionsModalComponent = void 0;
var core_1 = require("@angular/core");
var _services_1 = require("@/_services");
var contactadealer_component_1 = require("@/_components/contacadealermodal/contactadealer.component");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var platform_browser_1 = require("@angular/platform-browser");
var storage_service_1 = require("@/_services/storage-service");
var TyreOptionsModalComponent = /** @class */ (function () {
    function TyreOptionsModalComponent(modalService, authenticationService, activeModal, sanitizer, storageService) {
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.activeModal = activeModal;
        this.sanitizer = sanitizer;
        this.storageService = storageService;
        this.showTyreContactModal = function () {
            var modalRef = this.modalService.open(contactadealer_component_1.ContactADealerComponent);
            modalRef.componentInstance.my_modal_title = 'CONTACT A RETAILER';
            this.formNotes = "Please contact the customer about vehicle tyres.";
            this.dealer = this.storageService.getDealerId();
            this.bodyNo = this.storageService.getVin();
            var apiKey = this.get_gcdm_config['tyre_search_api_key'];
            var url = 'assets/page/contactForm.html';
            modalRef.componentInstance.urlSafe = this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url + "?wccomment=" + "VIN number: " + this.bodyNo + " " + this.formNotes + "&wcprefdealer=" + this.dealer + "&apiKey=" + apiKey);
            modalRef.result.then(function (result) {
            }).catch(function (error) {
            });
            this.activeModal.close();
        };
    }
    TyreOptionsModalComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TyreOptionsModalComponent.prototype, "my_modal_title", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TyreOptionsModalComponent.prototype, "vehicleTyreSearch", void 0);
    TyreOptionsModalComponent = __decorate([
        core_1.Component({
            selector: 'tyreoptionsmodal',
            template: require('./tyreoptionsmodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbModal,
            _services_1.AuthenticationService,
            ng_bootstrap_1.NgbActiveModal,
            platform_browser_1.DomSanitizer,
            storage_service_1.StorageService])
    ], TyreOptionsModalComponent);
    return TyreOptionsModalComponent;
}());
exports.TyreOptionsModalComponent = TyreOptionsModalComponent;
