"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.headMINIComponent = void 0;
var core_1 = require("@angular/core");
var $ = require("jquery");
var _services_1 = require("@/_services");
var router_1 = require("@angular/router");
var storage_service_1 = require("@/_services/storage-service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var insertid_mdl_component_1 = require("../../_components/ondemand/insertid_mdl/insertid_mdl.component");
var service_online_service_1 = require("@/_services/service-online.service");
var vehicle_details_service_1 = require("@/_services/vehicle-details.service");
var headMINIComponent = /** @class */ (function () {
    function headMINIComponent(router, authenticationService, activatedRoute, storageService, modalService, vehicleDetailsService, serviceOnlineService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.activatedRoute = activatedRoute;
        this.storageService = storageService;
        this.modalService = modalService;
        this.vehicleDetailsService = vehicleDetailsService;
        this.serviceOnlineService = serviceOnlineService;
        this.display = 'none';
        this.navbarOpen = false;
        this.solVehiclesDetails = [];
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.get_gcdm_config = this.authenticationService.get_config();
        // this.activatedRoute.params.subscribe(params => {
        //     console.log("get the headComponent info ::: ", params);
        // });
    }
    headMINIComponent.prototype.toggleNavbar = function () {
        this.navbarOpen = !this.navbarOpen;
    };
    headMINIComponent.prototype.loadSolBmwData = function () {
        var _this = this;
        this.vehicleDetailsService.getVehicleInfo().subscribe(function (solVehiclesDetail) {
            _this.solVehiclesDetail = solVehiclesDetail;
            if (_this.solVehiclesDetail.vehicles.findvehicle !== null) {
                _this.solVehiclesDetail.vehicles.findvehicle.forEach(function (o, i) {
                    if (o.currentVehicle === "X") {
                        var listOfVehicle = {
                            "VehicleId": null,
                            "RegistrationNumber": o.vehicleDetails.vehLpno,
                            "ChassisNo": o.vehicleDetails.vinNumber,
                            "MakeName": o.vehicleDetails.brand === 'BM' ? "BMW" : o.vehicleDetails.brand === 'MI' ? "MINI" : "",
                            "MakeCode": o.vehicleDetails.brand === 'BM' ? "B" : o.vehicleDetails.brand === 'MI' ? "M" : "",
                            "ModelName": o.vehicleDetails.modelDesc,
                            "ModelCode": o.vehicleDetails.modelCode,
                            "VariantName": o.vehicleDetails.modelDesc,
                            "VariantCode": o.vehicleDetails.modelCode,
                            "ModelTextDescription": o.vehicleDetails.modelDesc,
                            "Odometer": null,
                            "MOTExpiryDate": null,
                            "IsCompanyVehicle": false,
                            "LastRegisteredDate": null,
                            "TransmissionCode": null,
                            "TransmissionDesc": null,
                            "FuelCode": null,
                            "FuelDesc": null,
                            "EngineSize": null,
                            "Colour": null,
                            "Trim": null,
                            "FreeDesc": null,
                            "AccidentDamage": {
                                "Code": null,
                                "Description": null
                            },
                            "ServiceModelGroup": null
                        };
                        _this.solVehiclesDetails.push(listOfVehicle);
                    }
                });
                var solInfo = {
                    "Brand": "mini",
                    "Title": _this.storageService.getGcdmCustTitle,
                    "FirstName": _this.storageService.getGcdmCustNameFirst,
                    "Surname": _this.storageService.getGcdmCustNameLast,
                    "MobileTelNo": _this.storageService.getPhoneValue,
                    "EmailAddress": _this.storageService.getEmailValue,
                    "Address1": null,
                    "Address2": null,
                    "Address3": null,
                    "PostalCode": null,
                    "WorkTelNo": null,
                    "HomeTelNo": null,
                    "vehicles": _this.solVehiclesDetails
                };
                _this.serviceOnlineService.postToSolApp(solInfo).subscribe(function (sol_output_data) {
                    _this.sol_output_data = sol_output_data;
                    console.log("this.sol_output_data ::::::::::: ", _this.sol_output_data);
                });
            }
        });
    };
    headMINIComponent.prototype.ngOnInit = function () {
    };
    headMINIComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    headMINIComponent.prototype.openModal = function () {
        this.display = "block";
    };
    headMINIComponent.prototype.onCloseHandled = function () {
        this.display = "none";
    };
    headMINIComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    headMINIComponent.prototype.showMSG = function () {
        $('.notification_wrap').addClass('hide');
        $('.full_Msg').removeClass('hide');
        console.log('TEST');
    };
    headMINIComponent.prototype.closeMSG = function () {
        $('.full_Msg').addClass('hide');
        $('.notification_wrap').removeClass('hide');
        $('#MSG_1').removeClass('unread');
    };
    headMINIComponent = __decorate([
        core_1.Component({
            selector: 'app-head-mini',
            template: require('./head-mini.component.html')
        }),
        __metadata("design:paramtypes", [router_1.Router,
            _services_1.AuthenticationService,
            router_1.ActivatedRoute,
            storage_service_1.StorageService,
            ng_bootstrap_1.NgbModal,
            vehicle_details_service_1.VehicleDetailsService,
            service_online_service_1.ServiceOnlineService])
    ], headMINIComponent);
    return headMINIComponent;
}());
exports.headMINIComponent = headMINIComponent;
