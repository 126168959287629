"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appRoutingModule = exports.routes = void 0;
var router_1 = require("@angular/router");
var splash_component_1 = require("./home/splash.component");
var login_component_1 = require("./login/login.component");
var notifications_component_1 = require("./notifications/notifications.component");
var register_component_1 = require("./register/register.component");
var thankyou_component_1 = require("./thankyou/thankyou.component");
var link_component_1 = require("./link/link.component");
var existing_component_1 = require("./link/existing/existing.component");
var home_component_1 = require("./home/home.component");
var prospectHome_component_1 = require("./home/prospectHome.component");
var profile_component_1 = require("./profile/profile.component");
var preferredretailer_component_1 = require("./profile/preferredretailer/preferredretailer.component");
var privacysettings_component_1 = require("./profile/privacysettings/privacysettings.component");
var myaccount_component_1 = require("./myaccount/myaccount.component");
var accountadjustment_component_1 = require("./myaccount/accountadjustment/accountadjustment.component");
var crossborder_component_1 = require("./myaccount/crossborder/crossborder.component");
var documents_component_1 = require("./myaccount/documents/documents.component");
var endofterm_component_1 = require("./myaccount/endofterm/endofterm.component");
var statements_component_1 = require("./myaccount/statements/statements.component");
var _helpers_1 = require("./_helpers");
var faqs1_component_1 = require("./faqs/faqs1/faqs1.component");
var faqs2_component_1 = require("./faqs/faqs2/faqs2.component");
var faqs3_component_1 = require("./faqs/faqs3/faqs3.component");
var faqs4_component_1 = require("./faqs/faqs4/faqs4.component");
var faqs5_component_1 = require("./faqs/faqs5/faqs5.component");
var faqs6_component_1 = require("./faqs/faqs6/faqs6.component");
var faqs7_component_1 = require("./faqs/faqs7/faqs7.component");
var faqs8_component_1 = require("./faqs/faqs8/faqs8.component");
var faqs9_component_1 = require("./faqs/faqs9/faqs9.component");
var mygarage_component_1 = require("./mygarage/mygarage.component");
var serviceinfo_component_1 = require("./mygarage/serviceinfo/serviceinfo.component");
var fullservicehistory_component_1 = require("./mygarage/serviceinfo/fullservicehistory/fullservicehistory.component");
var authentication_component_1 = require("./authentication/authentication.component");
var serviceonline_component_1 = require("./serviceonline/serviceonline/serviceonline.component");
var psteml_component_1 = require("./psteml/psteml.component");
var policy_store_consent_resolver_1 = require("@/_resolver/policy-store-consent.resolver");
var epaas_component_1 = require("./epaas/epaas.component");
exports.routes = [
    {
        path: 'gcdm/index/auth',
        component: authentication_component_1.AuthenticationComponent,
    },
    {
        path: 'home',
        component: home_component_1.HomeComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'prospect',
        component: prospectHome_component_1.ProspectHomeComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'en/footer/footer-section/cookie-policy',
        component: epaas_component_1.EpaasComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'splash',
        component: splash_component_1.SplashComponent,
    },
    {
        path: 'login/:type',
        component: login_component_1.LoginComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'notifications',
        component: notifications_component_1.NotificationsComponent,
        outlet: 'popup',
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'register',
        component: register_component_1.RegisterComponent,
    },
    {
        path: 'psteml/:guid',
        component: psteml_component_1.PostEmailComponent,
    },
    {
        path: 'thankyou/:type',
        component: thankyou_component_1.ThankyouComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'link',
        component: link_component_1.LinkComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'existing',
        component: existing_component_1.ExistingComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'profile',
        component: profile_component_1.ProfileComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'serviceonline',
        component: serviceonline_component_1.ServiceOnlineComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'profile/preferredretailer',
        component: preferredretailer_component_1.PreferredRetailerComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'profile/privacysettings',
        component: privacysettings_component_1.PrivacySettingsComponent,
        canActivate: [_helpers_1.AuthGuard],
        resolve: {
            data: policy_store_consent_resolver_1.PolicyStoreConsentResolve,
        }
    },
    {
        path: 'account/overview',
        component: myaccount_component_1.MyAccountComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'account/accountadjustment',
        component: accountadjustment_component_1.AccountAdjustmentComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'account/crossborder',
        component: crossborder_component_1.CrossBorderComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'account/documents',
        component: documents_component_1.DocumentsComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'account/endofterm',
        component: endofterm_component_1.EndofTermComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'account/statements',
        component: statements_component_1.StatementsComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs',
        redirectTo: 'faqs/acc',
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/acc',
        component: faqs2_component_1.faqs2Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/applicationProceess',
        component: faqs1_component_1.faqs1Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/settlements',
        component: faqs3_component_1.faqs3Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/adjustments',
        component: faqs4_component_1.faqs4Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/Natis',
        component: faqs5_component_1.faqs5Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/selectEOT',
        component: faqs6_component_1.faqs6Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/arrears',
        component: faqs7_component_1.faqs7Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/legal',
        component: faqs8_component_1.faqs8Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'faqs/EOT',
        component: faqs9_component_1.faqs9Component,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'mygarage',
        component: mygarage_component_1.MyGarrageComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'mygarage/serviceinfo',
        component: serviceinfo_component_1.ServiceInfoComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: 'mygarage/serviceinfo/fullservicehistory',
        component: fullservicehistory_component_1.FullServiceHistoryComponent,
        canActivate: [_helpers_1.AuthGuard]
    },
    {
        path: '', redirectTo: 'gcdm/index/auth', pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'gcdm/index/auth',
        pathMatch: 'full'
    }
];
exports.appRoutingModule = router_1.RouterModule.forRoot(exports.routes);
