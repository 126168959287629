"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleCosyImageService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var VehicleCosyImageService = /** @class */ (function () {
    function VehicleCosyImageService(http) {
        this.http = http;
        this.httpClient = http;
        this.DS_env_hostname = process.env.DS_env_hostname;
    }
    VehicleCosyImageService.prototype.getVehicleCosyImage = function (vin) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'accept': 'application/json' });
        var configs = { headers: headers };
        var regObjectJson = {};
        regObjectJson = {
            "cosyUrlRequest": [
                {
                    "bodyno": "",
                    "doNotEncode": "",
                    "exteriorCode": "",
                    "externalIndicator": "X",
                    "interiorCode": "",
                    "modelCode": "",
                    "options": [
                        ""
                    ],
                    "productionDate": "",
                    "prodweek": "",
                    "qualityPercentage": "",
                    "quickviewIndicator": "",
                    "transparentIndicator": "X",
                    "vin": vin,
                    "width": ""
                }
            ]
        };
        return this.http.post(this.DS_env_hostname + "/ds/Rockar/v1/cosyimages", regObjectJson, configs)
            .pipe(operators_1.map(function (VehicleCosyImage) { return VehicleCosyImage; }));
    };
    VehicleCosyImageService.prototype.getVehicleCosyImageForMpanOpt = function (vin) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'accept': 'application/json' });
        var configs = { headers: headers };
        var regObjectJson = {};
        regObjectJson = {
            "cosyUrlRequest": [
                {
                    "bodyno": "",
                    "doNotEncode": "",
                    "exteriorCode": "",
                    "externalIndicator": "X",
                    "interiorCode": "",
                    "modelCode": "",
                    "options": [
                        ""
                    ],
                    "productionDate": "",
                    "prodweek": "",
                    "qualityPercentage": "",
                    "quickviewIndicator": "",
                    "transparentIndicator": "X",
                    "vin": vin,
                    "width": ""
                }
            ]
        };
        return this.http.post(this.DS_env_hostname + "/ds/Rockar/v1/cosyimages", regObjectJson, configs)
            .pipe(operators_1.delay(0), operators_1.map(function (VehicleCosyImage) { return VehicleCosyImage; }));
    };
    VehicleCosyImageService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient])
    ], VehicleCosyImageService);
    return VehicleCosyImageService;
}());
exports.VehicleCosyImageService = VehicleCosyImageService;
