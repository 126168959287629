"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EpaasComponent = void 0;
var core_1 = require("@angular/core");
var EpaasComponent = /** @class */ (function () {
    function EpaasComponent() {
    }
    EpaasComponent.prototype.ngOnInit = function () {
        var firstTime = localStorage.getItem('key');
        if (!firstTime) {
            localStorage.setItem('key', 'loaded');
            window.location.reload();
        }
        else {
            localStorage.removeItem('key');
        }
    };
    EpaasComponent = __decorate([
        core_1.Component({ template: require('./epaas.component.html') }),
        __metadata("design:paramtypes", [])
    ], EpaasComponent);
    return EpaasComponent;
}());
exports.EpaasComponent = EpaasComponent;
