"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtInterceptor = void 0;
var core_1 = require("@angular/core");
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor() {
    }
    JwtInterceptor.prototype.intercept = function (req, next) {
        // if (req.url.startsWith(environment.API_DSP_BASE_URL) || req.url.startsWith(environment.API_GCDM_BASE_URL)
        // || req.url.startsWith(environment.API_DLNET_BASE_URL) || req.url.startsWith(environment.API_SOL_BASE_URL)
        // || req.url.startsWith(environment.API_SOL_MOT_BASE_URL) || req.url.startsWith(environment.API_WEB_API_BASE_URL)
        // || req.url.startsWith(environment.API_CP_API_BASE_URL)) {
        //   return next.handle(req);
        // } else {
        //   req = req.clone({
        //     headers: new HttpHeaders({
        //       'X-Frame-Options': 'sameorigin',
        //       'Strict-Transport-Security': 'max-age=31536000',
        //       'Authorization': 'Basic ' + btoa(environment.BASIC)
        //     })
        //   });
        return next.handle(req);
        // }
    };
    JwtInterceptor = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [])
    ], JwtInterceptor);
    return JwtInterceptor;
}());
exports.JwtInterceptor = JwtInterceptor;
