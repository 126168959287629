"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var _services_1 = require("@/_services");
var $ = require("jquery");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, authenticationService, alertService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.loading = false;
        this.submitted = false;
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["/"]);
        }
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginForm = this.formBuilder.group({
            username: ["", forms_1.Validators.required],
            password: ["", forms_1.Validators.required]
        });
        this.route.paramMap.subscribe(function (params) {
            _this.type = params.get("type");
        });
        if (this.type == "oc") {
            this.accountType = "Owners Circle";
        }
        else {
            this.accountType = "My Account";
        }
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
        if (window.location.href.indexOf("register") != -1) {
            $("body").addClass("login");
        }
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        this.submitted = true;
        this.alertService.clear();
        if (this.loginForm.invalid) {
            return;
        }
        if (this.type == "login") {
            this.loading = true;
            // this.authenticationService
            //   .login(this.f.username.value, this.f.password.value)
            //   .pipe(first())
            //   .subscribe(
            //     results => {
            //       if (results.status != "FAILURE") {
            //         // this.getUser(results.sessionId);
            //       } else {
            //         this.alertService.error(results.message);
            //         this.loading = false;
            //       }
            //     },
            //     error => {
            //       this.alertService.error(error);
            //       this.loading = false;
            //     }
            //   );
        }
        if (this.type == "oc") {
            this.loading = true;
            // this.authenticationService
            //   .link(this.f.username.value, this.f.password.value, this.type)
            //   .pipe(first())
            //   .subscribe(
            //     results => {
            //       if (results.status != "FAILURE") {
            //         // this.getUser(results.sessionId);
            //       } else {
            //         this.alertService.error(results.message);
            //         this.loading = false;
            //       }
            //     },
            //     error => {
            //       this.alertService.error(error);
            //       this.loading = false;
            //     }
            //   );
        }
        else if (this.type == "ma") {
            this.loading = true;
            // this.authenticationService
            //   .link(this.f.username.value, this.f.password.value, this.type)
            //   .pipe(first())
            //   .subscribe(
            //     results => {
            //       if (results.status != "FAILURE") {
            //         this.router.navigate(["/link"]);
            //       } else {
            //         this.alertService.error(results.message);
            //         this.loading = false;
            //       }
            //     },
            //     error => {
            //       this.alertService.error(error);
            //       this.loading = false;
            //     }
            //   );
        }
    };
    LoginComponent = __decorate([
        core_1.Component({ template: require('./login.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            router_1.ActivatedRoute,
            router_1.Router,
            _services_1.AuthenticationService,
            _services_1.AlertService])
    ], LoginComponent);
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
