"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleMotorplanService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var VehicleMotorplanService = /** @class */ (function () {
    function VehicleMotorplanService(http, authenticationService, tokenExtractor) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.tokenExtractor = tokenExtractor;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    VehicleMotorplanService.prototype.getVehicleMotorplan = function (vin) {
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/${vehicleId}/motorplan?vehicleId=" + vin)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    VehicleMotorplanService.prototype.getVehicleMotorplanDetails = function (vin) {
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/${vehicleId}/motorplan-details?vehicleId=" + vin)
            .pipe(operators_1.delay(10), operators_1.map(function (data) { return data; }));
    };
    VehicleMotorplanService.prototype.getMotorPlanOptionalContracts = function (vin) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
        var configs = { headers: headers };
        var add = { "vin": vin };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/motor-plan/get-optional-contracts", add, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    VehicleMotorplanService.prototype.postToOptionalContractApp = function (motorplaninfo, vehicleInfo, contractTypeSelected, cosyImage, vin, dealerid, emailValue) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
        var configs = { headers: headers };
        var cosyurl = "";
        if (cosyImage === "") {
            cosyurl = cosyImage;
        }
        else {
            cosyurl = cosyImage.cosyImageUrlList[0];
        }
        var add = { "additionalData": { "vin": vin,
                "fwdapikey": process.env.flag,
                "modelDesc": vehicleInfo.modelDesc,
                "modelYear": vehicleInfo.modelYear,
                "dealercode": dealerid,
                "redirectUrl": this.get_gcdm_config['CP_hostname'],
                "cosyImage": cosyurl,
                "customerEmail": emailValue,
                "currentConStartDate": motorplaninfo.startDate,
                "currentConEndDate": motorplaninfo.expiryDate,
                "currentConExpiryKms": motorplaninfo.expiryKm,
                "contracttype": contractTypeSelected
            }
        };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/motor-plan/post-to-optional-contract-app", JSON.stringify(add), configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    VehicleMotorplanService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, _services_1.AuthenticationService, http_1.HttpXsrfTokenExtractor])
    ], VehicleMotorplanService);
    return VehicleMotorplanService;
}());
exports.VehicleMotorplanService = VehicleMotorplanService;
