"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var $ = require("jquery");
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, router, location, authenticationService, userService, alertService, activateRoute) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.router = router;
        this.location = location;
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.alertService = alertService;
        this.activateRoute = activateRoute;
        this.loading = false;
        this.exist = false;
        this.submitted = false;
        this.redirectUrl = "";
        this.model = {};
        this.passwordWeak = false;
        this.lower = false;
        this.upper = false;
        this.number = false;
        this.length = false;
        this.special = false;
        this.allowed = false;
        this.isDataLoaded = false;
        this.isSuccess = false;
        this.usageDefinitions = [];
        this.usageTexts = [];
        this.usages = [];
        this.policyStoreConsentData = [];
        this.policyConsentData = [];
        this.termsAndConditions = [];
        this.isMandatoryCheckBox = false;
        // If logged in through GCDM redirect to the home page
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["/"]);
        }
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.userService
            .policyStoreReg()
            .subscribe(function (policyStoreConsent) {
            _this.policyStoreConsentData = policyStoreConsent;
            _this.getRegPolicy();
            _this.isDataLoaded = true;
        });
    }
    RegisterComponent.prototype.getRegPolicy = function () {
        var _this = this;
        this.policyStoreConsentData.textBlocks.forEach(function (o, i) {
            _this.usageDefinitions = o.usageDefinitions;
            if (o.usageDefinitions !== undefined && o.usageText !== undefined) {
                o.usageText.forEach(function (usageText, i) {
                    var isPresent = o.usageDefinitions[i].usages.find(function (p) { return p.variant === 'MANDATORY' || p.mandatory; });
                    if (isPresent) {
                        o.usageDefinitions[i].purposes.forEach(function (p, i) {
                            _this.perpose = p.purpose.name;
                        });
                        _this.termsAndConditions.push({ primaryText: o.usageDefinitions[i].textPrimary + "*",
                            textUsage: usageText.value, isMandatory: true, id: i, purpose: _this.perpose, validate: 'is-invalid' });
                    }
                    else {
                        o.usageDefinitions[i].purposes.forEach(function (p, i) {
                            _this.perpose = p.purpose.name;
                        });
                        _this.termsAndConditions.push({ primaryText: o.usageDefinitions[i].textPrimary,
                            textUsage: usageText.value, isMandatory: false, id: i, purpose: _this.perpose, validate: 'is-valid' });
                    }
                });
            }
        });
        this.termsAndConditions.forEach(function (o, i) {
            var control = new forms_1.FormControl(false);
            _this.registerForm.controls.termsAndConditions.push(control);
        });
    };
    RegisterComponent.prototype.redirect_home = function () {
        window.location.href = this.gcdm_env_hostname + "/one/signin.html?client_id=" + this.gcdm_client_id + "&response_type=code&redirect_uri=https:%2F%2F" + this.get_gcdm_config['redirect_hostname'] + "%2Fgcdm%2Findex%2Fauth&scope=authenticate_user&state=" + this.get_gcdm_config['state'] + "&locale=" + this.get_gcdm_config['locale'];
    };
    RegisterComponent.prototype.toggleVisibility = function (event, isMandtory, num, perpose) {
        // console.log("num ::::::: ", num, perpose, event.target.checked, isMandtory)
        if (isMandtory) {
            this.isMandatoryCheckBox = true;
            this.perpose = perpose;
            this.consent = "REJECT";
        }
        if (!isMandtory && event.target.checked) {
            this.consent = "ACCEPT";
        }
        if (!isMandtory && !event.target.checked) {
            this.consent = "REJECT";
        }
    };
    RegisterComponent.prototype.deserialize = function (input) {
        Object.assign(this, input);
        return this;
    };
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registerForm = this.formBuilder.group({
            mail: ["", forms_1.Validators.required],
            password: ["", [forms_1.Validators.required]],
            formOfAddress: ["", forms_1.Validators.required],
            confirmPassword: ["", confirmPasswordMatch],
            firstName: ["", forms_1.Validators.required],
            surname: ["", forms_1.Validators.required],
            termsAndConditions: this.formBuilder.array([], [this.minSelectedCheckboxes])
            // termsAndConditions: new FormArray([], this.minSelectedCheckboxes(1))
        });
        //Custom abstract validator for confirm password
        function confirmPasswordMatch(control) {
            if (control.value == "") {
                return { passwordMismatch: true };
            }
            return null;
        }
        this.activateRoute.queryParams.subscribe(function (params) {
            _this.redirectUrl = params["returnUrl"];
        });
        this.router.events
            .pipe(operators_1.filter(function (e) { return e instanceof router_1.RoutesRecognized; }), operators_1.pairwise())
            .subscribe(function (e) {
            console.log(e[0].urlAfterRedirects); // previous url
        });
        if (window.location.href.indexOf("register") != -1) {
            $("body").addClass("exs_bg1");
        }
    };
    Object.defineProperty(RegisterComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    RegisterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        this.alertService.clear();
        if (this.registerForm.invalid) {
            return;
        }
        if (this.passwordWeak || this.allowed) {
            return;
        }
        if (this.exist == true) {
            return;
        }
        this.loading = true;
        this.touchpointId = "DEALER_WEB_SITE_ZA";
        this.country = this.policyStoreConsentData.country;
        this.majorVersion = this.policyStoreConsentData.majorVersion;
        this.minorVersion = this.policyStoreConsentData.minorVersion;
        this.policyId = this.policyStoreConsentData.name;
        this.language = this.policyStoreConsentData.textBlocks[0].language;
        var datePipe = new common_1.DatePipe("en-US");
        this.usageDefinitions.forEach(function (def, i) {
            def.usages.forEach(function (usg, i) {
                def.legalEntities.forEach(function (leg, i) {
                    def.purposes.forEach(function (perp, i) {
                        if (_this.isMandatoryCheckBox && _this.perpose === perp.purpose.name) {
                            var contactPolicyConsent = {
                                consent: "ACCEPT",
                                consentDate: datePipe.transform(new Date(), "yyy-MM-ddThh:mm:ss"),
                                country: _this.country,
                                language: _this.language,
                                legalEntityId: leg.name,
                                majorVersion: _this.majorVersion,
                                minorVersion: _this.minorVersion,
                                policyId: _this.policyId,
                                touchpointId: _this.touchpointId,
                                purposeId: perp.purpose.name,
                                usageId: usg.usage.name
                            };
                            _this.policyConsentData.push(contactPolicyConsent);
                        }
                        else {
                            var contactPolicyConsent = {
                                consent: _this.consent,
                                consentDate: datePipe.transform(new Date(), "yyy-MM-ddThh:mm:ss"),
                                country: _this.country,
                                language: _this.language,
                                legalEntityId: leg.name,
                                majorVersion: _this.majorVersion,
                                minorVersion: _this.minorVersion,
                                policyId: _this.policyId,
                                touchpointId: _this.touchpointId,
                                purposeId: perp.purpose.name,
                                usageId: usg.usage.name
                            };
                            _this.policyConsentData.push(contactPolicyConsent);
                        }
                    });
                });
            });
        });
        this.userService
            .register(this.registerForm.value, this.policyConsentData)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            _this.yourEmail = _this.registerForm.value.mail;
            // this.alertService.success("Registration successful", true);
            _this.isSuccess = true;
            _this.isDataLoaded = false;
            //this.router.navigate(['/thankyou/reg']);
        }, function (error) {
            // this.alertService.error(error);
            _this.loading = false;
        });
    };
    RegisterComponent.prototype.checkMail = function (email) {
        var _this = this;
        this.alertService.clear();
        this.userService
            .checkEmail(email)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            if (data[0] == email) {
                _this.exist = true;
            }
            else {
                _this.exist = false;
            }
        }, function (error) {
            _this.alertService.error(error);
            _this.exist = false;
        });
    };
    RegisterComponent.prototype.minSelectedCheckboxes = function (control) {
        var returnValue = false;
        for (var i = 0; i < control.value.length; i++) {
            // console.log("control.value[i] :::: ",control.value[i], i)
            if (control.value[i] && i === 0) {
                returnValue = true; // If any of the controls is checked, returnValue will be set to true at some point in this loop
            }
        }
        if (!returnValue) {
            return { controlStatus: { status: 'invalid' } }; // Returning an object of some sort, doesn't matter what, is how you indicate the control is not valid.
        }
        else {
            return null; // Returning null is how you indicate the control is valid.
        }
    };
    RegisterComponent.prototype.checkPassword = function (password) {
        //custom password strength for GCDM password complexity
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        var testLower = new RegExp("^(?=.*[a-z])");
        var testUpper = new RegExp("^(?=.*[A-Z])");
        var testNumber = new RegExp("^(?=.*[0-9])");
        var testLength = new RegExp("^(?=.{8,})");
        //var testSpecial = new RegExp("^(?=.*[*@#\\&\*])");
        var notAllowed = new RegExp("^(?=.*[!$%^~`?><])");
        //check lower
        if (testLower.test(password)) {
            this.lower = false;
        }
        else {
            this.lower = true;
        }
        //check upper
        if (testUpper.test(password)) {
            this.upper = false;
        }
        else {
            this.upper = true;
        }
        //checkNumbers
        if (testNumber.test(password)) {
            this.number = false;
        }
        else {
            this.number = true;
        }
        //check special characters
        //if(testSpecial.test(password)){this.special = false;}else{this.special = true;}
        //check length
        if (testLength.test(password)) {
            this.length = false;
        }
        else {
            this.length = true;
        }
        if (notAllowed.test(password)) {
            this.allowed = true;
        }
        else {
            this.allowed = false;
        }
        if (strongRegex.test(password)) {
            this.passwordWeak = false;
        }
        else {
            this.passwordWeak = true;
        }
    };
    RegisterComponent.prototype.resetPassword = function (email) {
        var _this = this;
        this.userService
            .resetPassword(email)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            // this.alertService.success('Password reset link sent to '+email, true);
            //this.location.back();
            _this.router.navigate(['/thankyou/reset']);
        }, function (error) {
            _this.alertService.error(error);
        });
        this.exist = true;
    };
    RegisterComponent = __decorate([
        core_1.Component({ template: require('./register.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            router_1.Router,
            common_1.Location,
            _services_1.AuthenticationService,
            _services_1.UserService,
            _services_1.AlertService,
            router_1.ActivatedRoute])
    ], RegisterComponent);
    return RegisterComponent;
}());
exports.RegisterComponent = RegisterComponent;
