"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OndemandLoginBpService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var storage_service_1 = require("@/_services/storage-service");
var OndemandLoginBpService = /** @class */ (function () {
    function OndemandLoginBpService(http, storageService, authenticationService, tokenExtractor) {
        this.http = http;
        this.storageService = storageService;
        this.authenticationService = authenticationService;
        this.tokenExtractor = tokenExtractor;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    OndemandLoginBpService.prototype.postCustomerRecordsToBeMerge = function (mergeCustomerRecords) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Content-type': 'application/json',
            "X-XSRF-TOKEN": csrfToken
        });
        var configs = { headers: headers };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/customer-record-to-be-merge", mergeCustomerRecords, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    OndemandLoginBpService.prototype.sfProfile = function () {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Content-type': 'application/json',
            "X-XSRF-TOKEN": csrfToken
        });
        var configs = { headers: headers };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/finance/user/9611150035082/profile", configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    OndemandLoginBpService.prototype.postActivateOnDamnd = function (guid) {
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/activation/" + guid)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    OndemandLoginBpService.prototype.postOnDemandStatus = function () {
        var headers = new http_1.HttpHeaders({
            'Accept': 'application/json',
            'Content-type': 'application/json'
        });
        var configs = { headers: headers };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/status/save/" + this.storageService.getGcdmCustBp(), configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    OndemandLoginBpService.prototype.getOnDamndStatus = function (token) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
        var configs = { headers: headers };
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/status", configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    OndemandLoginBpService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, storage_service_1.StorageService, _services_1.AuthenticationService, http_1.HttpXsrfTokenExtractor])
    ], OndemandLoginBpService);
    return OndemandLoginBpService;
}());
exports.OndemandLoginBpService = OndemandLoginBpService;
