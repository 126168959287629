"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CsrfInterceptor = void 0;
var http_1 = require("@angular/common/http");
var core_1 = require("@angular/core");
var CsrfInterceptor = /** @class */ (function () {
    function CsrfInterceptor(tokenExtractor) {
        this.tokenExtractor = tokenExtractor;
    }
    CsrfInterceptor.prototype.intercept = function (req, next) {
        // const cookieheaderName = 'X-XSRF-TOKEN';
        // let csrfToken = this.tokenExtractor.getToken() as string;
        // if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
        //     req = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
        // }
        return next.handle(req);
    };
    CsrfInterceptor = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [http_1.HttpXsrfTokenExtractor])
    ], CsrfInterceptor);
    return CsrfInterceptor;
}());
exports.CsrfInterceptor = CsrfInterceptor;
