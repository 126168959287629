"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = exports.searchService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var searchService = /** @class */ (function () {
    function searchService(http) {
        this.http = http;
        this.httpClient = http;
    }
    searchService.prototype.getVehicleAccounts = function () {
        return this.http.get("http://127.0.0.1:8081/api/v1/accounts/")
            .pipe(operators_1.map(function (data) { return data; }));
    };
    searchService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient])
    ], searchService);
    return searchService;
}());
exports.searchService = searchService;
exports.List = [
    {
        vehReg: 'WVR 933 GP',
        vehModel: 'BMW 320i',
        dealRef: '87452146',
    }, {
        vehReg: 'WR 93 WS GP',
        vehModel: 'BMW 335i',
        dealRef: '87452546',
    }, {
        vehReg: 'AF 14 GD GP',
        vehModel: 'BMW 130i',
        dealRef: '12548546',
    }, {
        vehReg: 'JC 97 CU GP',
        vehModel: 'BMW 10i',
        dealRef: '12548546',
    }
];
