"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthGuard = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var _services_1 = require("@/_services");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userService) {
        this.router = router;
        this.userService = userService;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        if (window.location.href.indexOf("localhost") != -1) {
            return true;
        }
        else {
            var userServiceObservable = this.userService.getGcdmCustomerProfile();
            userServiceObservable.subscribe(function (customerDetails) {
                customerDetails.forEach(function (o, i) {
                    if (o.businessPartner !== undefined) {
                        return true;
                    }
                    else {
                        if (window.location.href.indexOf("bmw-motorrad") != -1) {
                            _this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
                            _this.brand = "bmwmotorrad";
                        }
                        else if (window.location.href.indexOf("mini") != -1) {
                            _this.state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
                            _this.brand = "mini";
                        }
                        else if (window.location.href.indexOf("bmw") != -1) {
                            _this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
                            _this.brand = "bmw";
                        }
                        _this.router.navigate(["/"]).then(function (result) { window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.brand + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + window.location.origin + "%2Fgcdm%2Findex%2Fauth&state=" + _this.state; });
                        return false;
                    }
                }, function (error) {
                    if (window.location.href.indexOf("bmw-motorrad") != -1) {
                        _this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
                        _this.brand = "bmwmotorrad";
                    }
                    else if (window.location.href.indexOf("mini") != -1) {
                        _this.state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
                        _this.brand = "mini";
                    }
                    else if (window.location.href.indexOf("bmw") != -1) {
                        _this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
                        _this.brand = "bmw";
                    }
                    _this.router.navigate(["/"]).then(function (result) { window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.brand + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + window.location.origin + "%2Fgcdm%2Findex%2Fauth&state=" + _this.state; });
                    return false;
                });
            });
            return true;
        }
    };
    AuthGuard = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [router_1.Router,
            _services_1.UserService])
    ], AuthGuard);
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
