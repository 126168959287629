"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOnlineComponent = void 0;
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var _services_1 = require("@/_services");
var storage_service_1 = require("@/_services/storage-service");
var vehicle_details_service_1 = require("@/_services/vehicle-details.service");
var service_online_service_1 = require("@/_services/service-online.service");
var vehicle_cosy_image_service_1 = require("@/_services/vehicle-cosy.image.service");
var ServiceOnlineComponent = /** @class */ (function () {
    function ServiceOnlineComponent(sanitizer, authenticationService, storageService, vehicleDetailsService, serviceOnlineService, vehicleCosyImageService) {
        this.sanitizer = sanitizer;
        this.authenticationService = authenticationService;
        this.storageService = storageService;
        this.vehicleDetailsService = vehicleDetailsService;
        this.serviceOnlineService = serviceOnlineService;
        this.vehicleCosyImageService = vehicleCosyImageService;
        this.loading = false;
        this.serviceFlag = false;
        this.pageFlag = false;
        this.solVehiclesDetails = [];
        this.vehicleCosyImages = [];
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    ServiceOnlineComponent.prototype.loadSolBmwData = function () {
        var _this = this;
        this.loginData = JSON.parse(sessionStorage.getItem('loginData'));
        var solInfo = {
            "Brand": this.get_gcdm_config['brand'] === 'BM' ? "bmw" : this.get_gcdm_config['brand'] === 'MI' ? "mini" : "",
            "Title": this.storageService.getGcdmCustTitle(),
            "FirstName": this.storageService.getGcdmCustNameFirst(),
            "Surname": this.storageService.getGcdmCustNameLast(),
            "MobileTelNo": this.storageService.getPhoneValue(),
            "EmailAddress": this.storageService.getEmailValue(),
            "Address1": null,
            "Address2": null,
            "Address3": null,
            "PostalCode": null,
            "WorkTelNo": null,
            "HomeTelNo": null,
            "vehicles": this.loginData.vehicles
        };
        this.pageFlag = false;
        this.serviceOnlineService.postToSolApp(solInfo).subscribe(function (sol_output_data) {
            _this.sol_output_data = sol_output_data;
            _this.pageFlag = true;
            if (_this.storageService.getServiceOnlineUnAvail() === 'serviceUnAvail') {
                if (_this.get_gcdm_config['brand'] === 'MO') {
                    _this.serviceFlag = false;
                    _this.urlSafe = _this.sanitizer.bypassSecurityTrustResourceUrl(_this.sol_output_data.redirectToken);
                }
                else {
                    _this.serviceFlag = true;
                    _this.serviceOnlineError = 'Service Booking Error. Please log out and try again. Alternatively, you can contact us on 0800 600 555 or <a title="customer.infoservice@bmw.co.za" href="mailto:customer.infoservice@bmw.co.za" target="_blank" rel="noopener noreferrer">customer.infoservice@bmw.co.za</a>';
                }
            }
            else {
                _this.serviceFlag = false;
                _this.urlSafe = _this.sanitizer.bypassSecurityTrustResourceUrl(_this.sol_output_data.redirectToken);
            }
        });
    };
    ServiceOnlineComponent.prototype.ngOnInit = function () {
        if (this.get_gcdm_config['brand'] === 'MO' || this.get_gcdm_config['brand'] === 'MI') {
            this.serviceFlag = false;
            this.pageFlag = true;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.get_gcdm_config['SOL_hostname']);
        }
        else {
            this.loadSolBmwData();
        }
        //       if(this.storageService.getServiceOnlineUnAvail() === 'serviceUnAvail'){
        //         if(this.get_gcdm_config['brand'] === 'MO'){
        //           this.serviceFlag = false;
        //           this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.get_gcdm_config['SOL_hostname']);
        //         } else {
        //           this.serviceFlag = true;
        //           this.serviceOnlineError = 'Service Booking Error. Please log out and try again. Alternatively, you can contact us on 0800 600 555 or <a title="customer.infoservice@bmw.co.za" href="mailto:customer.infoservice@bmw.co.za" target="_blank" rel="noopener noreferrer">customer.infoservice@bmw.co.za</a>';
        //         }
        //       } else {
        //         this.serviceFlag = false;
        //         this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.get_gcdm_config['SOL_hostname']);
        //       }
        //     const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        //     input !== null && input.tagName === 'IFRAME';
        //
        //     //Message listener
        //     var i = 0;
        //     window.addEventListener("message", () =>{
        //       this.loginData=JSON.parse(sessionStorage.getItem('loginData'));
        //     this.SOLToken=sessionStorage.getItem('solToken');
        //     this.solReg = JSON.parse(sessionStorage.getItem('registerDets'));
        //     this.message = {
        //       "loginData":this.loginData,
        //       "solToken": this.SOLToken,
        //       "customerInfo": this.solReg
        //     }
        //       if(i < 1){
        //         let frame = document.getElementById('serviceFrame');
        //         if (isIFrame(frame) && frame.contentWindow) {
        //             frame.contentWindow.postMessage(this.message, '*');
        //         }
        //           i++;
        //       }
        //   }, false)
    };
    ServiceOnlineComponent = __decorate([
        core_1.Component({
            selector: 'service-online',
            template: require('./serviceonline.component.html')
        }),
        __metadata("design:paramtypes", [platform_browser_1.DomSanitizer,
            _services_1.AuthenticationService,
            storage_service_1.StorageService,
            vehicle_details_service_1.VehicleDetailsService,
            service_online_service_1.ServiceOnlineService,
            vehicle_cosy_image_service_1.VehicleCosyImageService])
    ], ServiceOnlineComponent);
    return ServiceOnlineComponent;
}());
exports.ServiceOnlineComponent = ServiceOnlineComponent;
