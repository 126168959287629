"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileModalComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var _services_1 = require("@/_services");
var ProfileModalComponent = /** @class */ (function () {
    function ProfileModalComponent(activeModal, userService, formBuilder) {
        this.activeModal = activeModal;
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.loading = false;
        this.submitted = false;
        this.loadCustomerDetails();
    }
    ProfileModalComponent.prototype.loadCustomerDetails = function () {
        var _this = this;
        var userServiceObservable = this.userService.getGcdmCustomerProfile();
        userServiceObservable.subscribe(function (customerDetails) {
            customerDetails.forEach(function (o, i) {
                if (o.businessPartner !== undefined) {
                    _this.customerDetails = o.businessPartner;
                }
                if (_this.customerDetails.profile !== undefined) {
                    if (_this.customerDetails.profile.personalIdentifications !== undefined) {
                        _this.customerDetails.profile.personalIdentifications.personalIdentifications.forEach(function (o, i) {
                            _this.idNumber = o.id;
                        });
                    }
                }
            });
            _this.createForm(_this.customerDetails, _this.idNumber);
        });
    };
    Object.defineProperty(ProfileModalComponent.prototype, "f", {
        get: function () {
            return this.ProfileEditForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    ProfileModalComponent.prototype.createForm = function (customerDetails, idNumber) {
        this.ProfileEditForm = this.formBuilder.group({
            title: [customerDetails.salutation, forms_1.Validators.required],
            names: [customerDetails.givenName, forms_1.Validators.required],
            surname: [customerDetails.surname, forms_1.Validators.required],
        });
    };
    ProfileModalComponent.prototype.submitForm = function () {
        this.submitted = true;
        if (this.ProfileEditForm.invalid) {
            this.loading = false;
            return;
        }
        this.activeModal.close(this.ProfileEditForm.value);
    };
    ProfileModalComponent.prototype.ngOnInit = function () {
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ProfileModalComponent.prototype, "my_modal_title", void 0);
    ProfileModalComponent = __decorate([
        core_1.Component({
            selector: 'app-profilemodal',
            template: require('./profilemodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            _services_1.UserService,
            forms_1.FormBuilder])
    ], ProfileModalComponent);
    return ProfileModalComponent;
}());
exports.ProfileModalComponent = ProfileModalComponent;
