"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDetailsService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var VehicleDetailsService = /** @class */ (function () {
    function VehicleDetailsService(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    VehicleDetailsService.prototype.getVehicleInfo = function () {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
        var configs = { headers: headers };
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp", configs)
            .pipe(operators_1.map(function (data) { return data; }), operators_1.delay(1000));
    };
    VehicleDetailsService.prototype.getVehicleForProcepect = function (token) {
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
        var configs = { headers: headers };
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp", configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    VehicleDetailsService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, _services_1.AuthenticationService])
    ], VehicleDetailsService);
    return VehicleDetailsService;
}());
exports.VehicleDetailsService = VehicleDetailsService;
