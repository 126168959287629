"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MockModule = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var mock_interceptor_1 = require("./mock.interceptor");
var MockModule = /** @class */ (function () {
    function MockModule() {
    }
    MockModule = __decorate([
        core_1.NgModule({
            declarations: [],
            imports: [],
            providers: [
                {
                    provide: http_1.HTTP_INTERCEPTORS,
                    useClass: mock_interceptor_1.HttpMockApiInterceptor,
                    multi: true
                }
            ]
        })
    ], MockModule);
    return MockModule;
}());
exports.MockModule = MockModule;
