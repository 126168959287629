"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var LinkComponent = /** @class */ (function () {
    function LinkComponent(formBuilder, router, authenticationService, userService, alertService, activateRoute) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.alertService = alertService;
        this.activateRoute = activateRoute;
        this.loading = false;
        this.submitted = false;
        this.firstName = "";
        this.surname = "";
        this.mail = "";
        this.formOfAddress = "";
        this.exist = false;
        this.model = {};
        this.contactPolicyConsents = [];
        this.currentBp = this.authenticationService.currentBpValue;
    }
    LinkComponent.prototype.ngOnInit = function () {
        this.registerForm = this.formBuilder.group({
            formOfAddress: ['', forms_1.Validators.required],
            firstName: ['', forms_1.Validators.required],
            surname: ['', forms_1.Validators.required],
            mail: ['', forms_1.Validators.required],
            password: ['', [forms_1.Validators.required, forms_1.Validators.minLength(6)]],
            confirmPassword: ['', confirmPasswordMatch]
        });
        if (typeof this.currentBp.per !== 'undefined') {
            this.firstName = this.currentBp.per.nameFirst;
            this.surname = this.currentBp.per.nameLast;
            this.formOfAddress = ("SAL_" + this.currentBp.title).toUpperCase();
        }
        else {
            this.firstName = this.currentBp.name;
            this.surname = this.currentBp.surname;
            this.formOfAddress = ("SAL_" + this.currentBp.title).toUpperCase();
        }
        function confirmPasswordMatch(control) {
            if (control.value == "") {
                return { 'passwordMismatch': true };
            }
            return null;
        }
    };
    Object.defineProperty(LinkComponent.prototype, "f", {
        get: function () { return this.registerForm.controls; },
        enumerable: false,
        configurable: true
    });
    LinkComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        this.alertService.clear();
        if (this.registerForm.invalid) {
            return;
        }
        if (this.exist == true) {
            return;
        }
        this.loading = true;
        this.userService.register(this.registerForm.value, this.contactPolicyConsents)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            _this.alertService.success('Account Activated Successfully', true);
            _this.router.navigate(['/profile']);
        }, function (error) {
            _this.alertService.error(error);
            _this.loading = false;
        });
    };
    LinkComponent.prototype.checkMail = function (email) {
        var _this = this;
        this.alertService.clear();
        this.userService.checkEmail(email)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            if (data[0] == email) {
                _this.exist = true;
            }
            else {
                _this.exist = false;
            }
        }, function (error) {
            _this.alertService.error(error);
            _this.exist = false;
        });
    };
    LinkComponent.prototype.resetPassword = function (email) {
        var _this = this;
        this.userService.resetPassword(email)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            _this.alertService.success('Reset password link sent to ' + email, true);
            _this.exist = false;
        }, function (error) {
            _this.alertService.error(error);
            _this.exist = false;
        });
    };
    LinkComponent = __decorate([
        core_1.Component({ template: require('./link.component.html') }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            router_1.Router,
            _services_1.AuthenticationService,
            _services_1.UserService,
            _services_1.AlertService,
            router_1.ActivatedRoute])
    ], LinkComponent);
    return LinkComponent;
}());
exports.LinkComponent = LinkComponent;
