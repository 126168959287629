"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../environments/environment");
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
        this.get_config = function () {
            var brand = this.get_bmw_brand;
            if (window.location.href.indexOf("localhost") != -1) {
                brand = this.get_bmw_brand;
                require("style-loader!./../_content/BMW.less");
            }
            if (window.location.href.indexOf("bmw-motorrad") != -1) {
                brand = this.get_motorrad_brand;
                require("style-loader!./../_content/MOT.less");
            }
            else if (window.location.href.indexOf("mini") != -1) {
                brand = this.get_mini_brand;
                require("style-loader!./../_content/MINI.less");
            }
            else if (window.location.href.indexOf("bmw") != -1) {
                brand = this.get_bmw_brand;
                require("style-loader!./../_content/BMW.less");
            }
            return brand;
        };
        this.gcdmLogin = function (code) {
            var _this = this;
            var headers = new http_1.HttpHeaders({ 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + btoa(this.get_gcdm_client_id + ':' + this.get_gcdm_secret_id)
            });
            // let configs = { headers: headers };
            // let uri = this.get_gcdm_config["CP_hostname"] + "/gcdm/index/auth";
            var regObjectJson = "grant_type=authorization_code&code=" + code + "&redirect_uri=" + this.get_gcdm_config["CP_hostname"] + "/gcdm/index/auth";
            return this.http.post(this.gcdm_env_hostname + "/gcdm/oauth/token", regObjectJson, { headers: headers })
                .pipe(operators_1.map(function (results) {
                _this.gcdmAuth = results;
                if (_this.gcdmAuth && _this.gcdmAuth.access_token) {
                    sessionStorage.setItem('CP_OAUTH_BEARER_TOKEN', _this.gcdmAuth.access_token);
                }
                return _this.gcdmAuth.access_token;
            }));
        };
        //GCDM Logged in User
        this.currentUserSubject = new rxjs_1.BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //Business Partner from OC and My Account
        this.currentBpSubject = new rxjs_1.BehaviorSubject(JSON.parse(localStorage.getItem('currentBp')));
        this.currentBp = this.currentBpSubject.asObservable();
        this.get_gcdm_client_id = process.env.gcdm_client_id;
        this.get_gcdm_secret_id = process.env.gcdm_secret_id;
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.get_bmw_brand = process.env.bmw;
        this.get_mini_brand = process.env.mini;
        this.get_motorrad_brand = process.env.motorrad;
        this.get_gcdm_config = this.get_config();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "currentBpValue", {
        get: function () {
            return this.currentBpSubject.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "userProfileValue", {
        get: function () {
            return this.userProfileSubject.value;
        },
        enumerable: false,
        configurable: true
    });
    AuthenticationService.prototype.cpLogin = function () {
        var headers = new http_1.HttpHeaders({
            'X-Frame-Options': 'sameorigin',
            'Strict-Transport-Security': 'max-age=31536000',
            'Authorization': 'Basic ' + btoa(environment_1.environment.BASIC)
        });
        var configs = { headers: headers };
        return this.http.get(this.get_gcdm_config["CP_hostname"], configs)
            .pipe(operators_1.map(function (results) {
            return results;
        }));
    };
    ;
    AuthenticationService.prototype.gcdmLogoff = function () {
        var _this = this;
        var headers = new http_1.HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(this.get_gcdm_client_id + ':' + this.get_gcdm_secret_id)
        });
        var configs = { headers: headers };
        var regObjectJson = "token=" + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN');
        // let regObjectJson = {"token": sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN')};
        return this.http.post(this.gcdm_env_hostname + "/gcdm/oauth/revoke", regObjectJson, configs)
            .pipe(operators_1.map(function (results) {
            _this.gcdmAuth = results;
            console.log("logoff to gcdm ", _this.gcdmAuth);
            return _this.gcdmAuth;
        }));
    };
    ;
    AuthenticationService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient])
    ], AuthenticationService);
    return AuthenticationService;
}());
exports.AuthenticationService = AuthenticationService;
