"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountAdjustmentComponent = void 0;
var core_1 = require("@angular/core");
var $ = require("jquery");
var _services_1 = require("@/_services");
var vehicle_account_service_1 = require("@/_services/vehicle-account.service");
var AccountAdjustmentComponent = /** @class */ (function () {
    function AccountAdjustmentComponent(authenticationService, userService, vehicleAccountService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.vehicleAccountService = vehicleAccountService;
        this.currentUser = this.authenticationService.currentUserValue;
        this.loadVehicleAccount();
    }
    AccountAdjustmentComponent.prototype.loadVehicleAccount = function () {
        this.loading = true;
        // let vehicleAccountObservable = this.vehicleAccountService.getVehicleAccount("JORDAAN00098205");
        // vehicleAccountObservable.subscribe((vehicleAccount: VehicleAccount) => {this.vehicleAccount = vehicleAccount; console.log("this.vehicleAccount :: ",vehicleAccount);});
        // this.setVehicleAccount(vehicleAccountObservable);
    };
    AccountAdjustmentComponent.prototype.setVehicleAccount = function (vehicleAccount) {
        this.vehicleAccount = vehicleAccount;
        this.loading = false;
    };
    AccountAdjustmentComponent.prototype.ngOnInit = function () {
        // $( "#adj_balloon" ).click(function() {
        //     $('#adj_balloon').addClass('show');
        //     $('#adj_payment').addClass('hide');
        // });
        // $( "#adj_payment" ).click(function() {
        //     $('#adj_payment').addClass('show');
        //     $('#adj_balloon').addClass('hide');
        // })
        $('#monthly').on('click', this.showMonthly);
        $('#balloon').on('click', this.showBalloon);
        $('#term').on('click', this.showTerm);
    };
    AccountAdjustmentComponent.prototype.showMonthly = function () {
        $('#adj_payment').removeClass('hide').addClass('show');
        $('#adj_balloon').removeClass('show').addClass('hide');
        $('#adj_term').removeClass('show').addClass('hide');
    };
    AccountAdjustmentComponent.prototype.showBalloon = function () {
        $('#adj_balloon').removeClass('hide').addClass('show');
        $('#adj_payment').removeClass('show').addClass('hide');
        $('#adj_term').removeClass('show').addClass('hide');
    };
    AccountAdjustmentComponent.prototype.showTerm = function () {
        $('#adj_term').removeClass('hide').addClass('show');
        $('#adj_payment').removeClass('show').addClass('hide');
        $('#adj_balloon').removeClass('show').addClass('hide');
    };
    AccountAdjustmentComponent = __decorate([
        core_1.Component({ template: require('./accountadjustment.component.html') }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService, _services_1.UserService, vehicle_account_service_1.VehicleAccountService])
    ], AccountAdjustmentComponent);
    return AccountAdjustmentComponent;
}());
exports.AccountAdjustmentComponent = AccountAdjustmentComponent;
