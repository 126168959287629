"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TechnicalCampaignComponent = void 0;
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var platform_browser_1 = require("@angular/platform-browser");
var TechnicalCampaignComponent = /** @class */ (function () {
    function TechnicalCampaignComponent(activeModal, sanitizer) {
        this.activeModal = activeModal;
        this.sanitizer = sanitizer;
    }
    TechnicalCampaignComponent.prototype.ngOnInit = function () {
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TechnicalCampaignComponent.prototype, "my_modal_title", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TechnicalCampaignComponent.prototype, "vehicleCampaignMessage", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TechnicalCampaignComponent.prototype, "vehicleCampaignDetails", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], TechnicalCampaignComponent.prototype, "vehicleModelDesc", void 0);
    TechnicalCampaignComponent = __decorate([
        core_1.Component({
            selector: 'technical-campaign',
            template: require('./technicalcampaign.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            platform_browser_1.DomSanitizer])
    ], TechnicalCampaignComponent);
    return TechnicalCampaignComponent;
}());
exports.TechnicalCampaignComponent = TechnicalCampaignComponent;
