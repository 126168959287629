"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginDetailsModalComponent = void 0;
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var _services_1 = require("@/_services");
var LoginDetailsModalComponent = /** @class */ (function () {
    function LoginDetailsModalComponent(activeModal, userService, formBuilder, alertService) {
        this.activeModal = activeModal;
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.alertService = alertService;
        this.loading = false;
        this.submitted = false;
        this.lower = false;
        this.upper = false;
        this.number = false;
        this.passwordWeak = false;
        this.length = false;
        this.allowed = false;
        this.exist = false;
        this.loadCustomerDetails();
    }
    LoginDetailsModalComponent.prototype.loadCustomerDetails = function () {
        var _this = this;
        var userServiceObservable = this.userService.getGcdmCustomerProfile();
        userServiceObservable.subscribe(function (customerDetails) {
            customerDetails.forEach(function (o, i) {
                _this.userAccount = o.userAccount;
                if (_this.userAccount !== undefined) {
                    _this.userAccountEmail = _this.userAccount.mail;
                    _this.emailVerification = _this.userAccount.mail;
                }
            });
            _this.createForm(_this.userAccountEmail);
        });
    };
    LoginDetailsModalComponent.prototype.createForm = function (userAccountEmail) {
        this.LoginDetailsEditForm = this.formBuilder.group({
            email: [userAccountEmail, forms_1.Validators.required],
            confirmPassword: ['', this.confirmPasswordMatch],
            password: ['', forms_1.Validators.required],
            oldPassword: ['', forms_1.Validators.required]
        });
    };
    LoginDetailsModalComponent.prototype.submitForm = function () {
        this.submitted = true;
        if (this.LoginDetailsEditForm.invalid) {
            this.loading = false;
            return;
        }
        if (this.exist == true) {
            this.loading = false;
            return;
        }
        this.activeModal.close(this.LoginDetailsEditForm.value);
    };
    LoginDetailsModalComponent.prototype.checkMail = function (email) {
        var _this = this;
        this.alertService.clear();
        console.log("this.userAccountEmail ::: ", this.userAccountEmail, "email ::: ", email);
        if (this.userAccountEmail !== email) {
            this.userService
                .checkEmail(email)
                .pipe(operators_1.first())
                .subscribe(function (data) {
                if (data[0] == email) {
                    _this.exist = true;
                }
                else {
                    _this.exist = false;
                }
            }, function (error) {
                _this.alertService.error(error);
                _this.exist = false;
            });
        }
        else {
            this.exist = false;
        }
    };
    LoginDetailsModalComponent.prototype.confirmPasswordMatch = function (control) {
        if (control.value == "") {
            return { passwordMismatch: true };
        }
        return null;
    };
    LoginDetailsModalComponent.prototype.checkPassword = function (password) {
        //custom password strength for GCDM password complexity
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        var testLower = new RegExp("^(?=.*[a-z])");
        var testUpper = new RegExp("^(?=.*[A-Z])");
        var testNumber = new RegExp("^(?=.*[0-9])");
        var testLength = new RegExp("^(?=.{8,})");
        //var testSpecial = new RegExp("^(?=.*[*@#\\&\*])");
        var notAllowed = new RegExp("^(?=.*[!$%^~`?><])");
        //check lower
        if (testLower.test(password)) {
            this.lower = false;
        }
        else {
            this.lower = true;
        }
        //check upper
        if (testUpper.test(password)) {
            this.upper = false;
        }
        else {
            this.upper = true;
        }
        //checkNumbers
        if (testNumber.test(password)) {
            this.number = false;
        }
        else {
            this.number = true;
        }
        //check special characters
        //if(testSpecial.test(password)){this.special = false;}else{this.special = true;}
        //check length
        if (testLength.test(password)) {
            this.length = false;
        }
        else {
            this.length = true;
        }
        if (notAllowed.test(password)) {
            this.allowed = true;
        }
        else {
            this.allowed = false;
        }
        if (strongRegex.test(password)) {
            this.passwordWeak = false;
        }
        else {
            this.passwordWeak = true;
        }
    };
    Object.defineProperty(LoginDetailsModalComponent.prototype, "f", {
        get: function () {
            return this.LoginDetailsEditForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    LoginDetailsModalComponent.prototype.ngOnInit = function () {
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], LoginDetailsModalComponent.prototype, "my_modal_title", void 0);
    LoginDetailsModalComponent = __decorate([
        core_1.Component({
            selector: 'app-logindetailsmodal',
            template: require('./logindetailsmodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            _services_1.UserService,
            forms_1.FormBuilder,
            _services_1.AlertService])
    ], LoginDetailsModalComponent);
    return LoginDetailsModalComponent;
}());
exports.LoginDetailsModalComponent = LoginDetailsModalComponent;
