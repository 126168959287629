"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
require("rxjs/add/operator/debounceTime");
require("rxjs/add/operator/distinctUntilChanged");
require("rxjs/add/operator/switchMap");
var search_service_1 = require("@/_services/search.service");
var SearchComponent = /** @class */ (function () {
    function SearchComponent(_searchService) {
        this._searchService = _searchService;
        this.Vehlist = search_service_1.List;
        this.filters = [];
        this.queryField = new forms_1.FormControl();
    }
    SearchComponent.prototype.ngOnInit = function () {
        //     this.queryField.valueChanges
        //     .debounceTime(200)
        //     .distinctUntilChanged()
        //     .switchMap((query) =>  this._searchService.search(query))
        //     .subscribe( result => { if (result.status === 400) { return; } else {   this.results = result.json().artists.items; }
        //   });
        // console.log('LIST: '+ this.Vehlist[0]);
    };
    SearchComponent = __decorate([
        core_1.Component({
            selector: 'app-search',
            template: require('./search.component.html')
        }),
        __metadata("design:paramtypes", [search_service_1.searchService])
    ], SearchComponent);
    return SearchComponent;
}());
exports.SearchComponent = SearchComponent;
