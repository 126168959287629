"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOptionsComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var serviceoptionsmodal_component_1 = require("./serviceoptionsmodal/serviceoptionsmodal.component");
var ServiceOptionsComponent = /** @class */ (function () {
    function ServiceOptionsComponent(
    // public activeModal: NgbActiveModal,
    authenticationService, userService, modalService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.modalService = modalService;
        this.loading = false;
        this.services = [];
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
    }
    ServiceOptionsComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    ServiceOptionsComponent.prototype.ngOnInit = function () {
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
    };
    ServiceOptionsComponent.prototype.openServiceOptionsModal = function (childServiceOptions) {
        var modalRef = this.modalService.open(serviceoptionsmodal_component_1.ServiceOptionsModalComponent);
        modalRef.componentInstance.my_modal_title = 'SERVICE OPTIONS';
        modalRef.componentInstance.serviceOptions = childServiceOptions.defects;
        modalRef.componentInstance.servRest = childServiceOptions.servRest;
        modalRef.componentInstance.serviceOptionsTerms = childServiceOptions.terms;
        modalRef.result.then(function (result) {
        }).catch(function (error) {
            // console.log(error);
        });
        // this.activeModal.close();
    };
    ServiceOptionsComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ServiceOptionsComponent.prototype, "childServiceOptions", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Boolean)
    ], ServiceOptionsComponent.prototype, "childIsEmptyServiceOption", void 0);
    ServiceOptionsComponent = __decorate([
        core_1.Component({
            selector: 'service-options',
            template: require('./serviceoptions.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            ng_bootstrap_1.NgbModal])
    ], ServiceOptionsComponent);
    return ServiceOptionsComponent;
}());
exports.ServiceOptionsComponent = ServiceOptionsComponent;
