"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
exports.default = {
    GET: {
        'https://customer-i.bmwgroup.com/gcdm/protected/v4/customerportal/ZA-BMW-en-dev/customers': {
            handler: getGcdmCustomerProfile,
            getGcdmCustomerProfileOnePage: getGcdmCustomerProfileOnePage
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp': {
            handler: getVehicleInfo
        },
    },
    PUT: {
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/crm/customer/get-maintain/dealer': {
            handler: getServiceCodeDealer
        }
    },
    POST: {
        'https://customer-i.bmwgroup.com/gcdm/oauth/token': {
            handler: gcdmLogin
        }
    }
};
function gcdmLogin() {
    return rxjs_1.of(new http_1.HttpResponse({
        status: 200,
        body: {
            access_token: "A9hQXdE-6yBxhrl-xyI6B6b0KOY",
            expires_in: 3599,
            gcid: "b09ba166-8578-4526-b0da-34307895b1fc",
            id_token: "eyJ0eXAiOiJKV1QiLCJraWQiOiJ0K0NmRnRVMkd2VzMzMGpodnVCQUNZeHJqdkE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiSlA1YVZVZXZsOGZ5R3ktcE82UUZfdyIsInN1YiI6ImIwOWJhMTY2LTg1NzgtNDUyNi1iMGRhLTM0MzA3ODk1YjFmYyIsImF1ZGl0VHJhY2tpbmdJZCI6ImU0NzBmZWIwLTA3ZjYtNDJlMy1iNTBlLTBlYjAzZjQ2YmU0ZC0xMDE4ODQ1IiwiZ2NpZCI6ImIwOWJhMTY2LTg1NzgtNDUyNi1iMGRhLTM0MzA3ODk1YjFmYyIsInN1Ym5hbWUiOiJiMDliYTE2Ni04NTc4LTQ1MjYtYjBkYS0zNDMwNzg5NWIxZmMiLCJpc3MiOiJodHRwczovL2N1c3RvbWVyLWkuYm13Z3JvdXAuY29tL2FtL29hdXRoMiIsInRva2VuTmFtZSI6ImlkX3Rva2VuIiwic2lkIjoicVJFUFdCdEgyL2J5RGNZbkU1ZFJjRFN0cjlEemZub2M2cmZKbGFsOU5wcz0iLCJhdWQiOiJmNjA2M2QzYy00ZGIwLTQ0N2YtYTRiNy1iMGRjYjYyYzNjODYiLCJjX2hhc2giOiJjYWhUaW5yWnJoMkdnVU1ac3pfbHlRIiwiYWNyIjoiMCIsIm9yZy5mb3JnZXJvY2sub3BlbmlkY29ubmVjdC5vcHMiOiItMkdkS2xIU2Foa3NPRE9SX3hnbGNMZm9HNGsiLCJzX2hhc2giOiJsUGZzalg2Wmx1YjFNcW16UE91YWpRIiwibG9naW5fbmFtZSI6ImRpY2suYmVuLnRlc3RAYnlvbS5kZSIsImF6cCI6ImY2MDYzZDNjLTRkYjAtNDQ3Zi1hNGI3LWIwZGNiNjJjM2M4NiIsImF1dGhfdGltZSI6MTY0ODcwOTgwOSwicmVhbG0iOiIvY3VzdG9tZXIiLCJleHAiOjE2NDg3MTM0MTIsInRva2VuVHlwZSI6IkpXVFRva2VuIiwiaWF0IjoxNjQ4NzA5ODEyfQ.nA_hC5gMbl8kjAmBWbv3dkitf1ie-HXmdgXgGWDGl_UdcEIAIfPce-vcgc6vdE5JKeKMfv5Ze5HR9YLikaIjZOzTIky0EkhmUapbp1cp-V7yNLp6LdOvWMfb4kZ29jkn8VGN6Jy5E70YAMiXyZKMrq-NTz7vyCpNxRXZfnTICEab27dMFlPuP8Z4duA4zj79rheHSjWlHlms53BOepX3ohSAThRGGSQmGj-xYuThoQcUqKbPUmLPU0jzi70KsTvBJHxpZ7RX59YgQaGKZERIcDFl8DZe4hPbh3xpaqt4KaAeFLdMA7vDLv2QiGi-T7f7lMd9qQpXNH22wonMw1KyPQ",
            refresh_token: "04CSdbRYRt3_NNoluHJc-HhobEo",
            scope: "openid authenticate_user",
            token_type: "Bearer"
        }
    }));
}
function getServiceCodeDealer() {
    return rxjs_1.of(new http_1.HttpResponse({
        status: 200,
        body: {
            "evDealerRelation": {
                "bpNo": "0009000920",
                "relationCat": "",
                "dealerId": "48548",
                "validDate": "0000-00-00"
            },
            "evMessage": "Success",
            "evResult": "S"
        }
    }));
}
function getVehicleInfo() {
    return rxjs_1.of(new http_1.HttpResponse({
        status: 200,
        body: {
            "vehicles": {
                "findvehicle": [
                    {
                        "bpOwner": "0013252985",
                        "bpDriver": "0013252985",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0LT85911",
                        "vinNumber": "WBAFH82090LT85911",
                        "brand": "BM",
                        "modelCode": "B-FH82",
                        "modelYear": "2015",
                        "intVehNo": "LT85911",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE91ED5918C81A0C07E8F34",
                            "validFrom": "20171104170512",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "150600101002321",
                            "zsfAssetCode": "05065480",
                            "zsfAssetDescr": "BMW X6 M50d",
                            "zsfAssetInsurer": "08",
                            "zsfAssetPolno": "1183851-1",
                            "zsfStartDate": "2015-06-24",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "90110755",
                            "zsfChassisNo": "WBAFH82090LT85911",
                            "zsfRegistrNo": "N003275",
                            "zsfRegistrYear": "2015",
                            "zsfNewUsed": "N",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "0LT85911",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "LT85911",
                        "productGuid": "00163E103BE01EE3BBA83F5E4AB558D7",
                        "related": "",
                        "vehLpno": "",
                        "modelDesc": "E71 X6 M50d Sport Activity Coupé"
                    },
                    {
                        "bpOwner": "0013252985",
                        "bpDriver": "0013252985",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0ET52601",
                        "vinNumber": "WBABK72070ET52601",
                        "brand": "BM",
                        "modelCode": "B-BK72",
                        "modelYear": "1998",
                        "intVehNo": "ET52601",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "ET52601",
                        "productGuid": "46761843C8C5008801000000AC103C32",
                        "related": "",
                        "vehLpno": "DXB328GP",
                        "modelDesc": "E36 328i Convertible"
                    },
                    {
                        "bpOwner": "0013252985",
                        "bpDriver": "0013252985",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0AU75307",
                        "vinNumber": "ZBACD88060AU75307",
                        "brand": "BM",
                        "modelCode": "B-CD88",
                        "modelYear": "1997",
                        "intVehNo": "AU75307",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE81EE6838BD295EE0F90EB",
                            "validFrom": "20171104210124",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "LIPSCHI97102856",
                            "zsfAssetCode": "318A",
                            "zsfAssetDescr": "BMW 318I AUTO",
                            "zsfAssetInsurer": "19",
                            "zsfAssetPolno": "8230/0293",
                            "zsfStartDate": "1997-10-21",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "0030B608",
                            "zsfChassisNo": "0AU75307",
                            "zsfRegistrNo": "CGN680GP",
                            "zsfRegistrYear": "1997",
                            "zsfNewUsed": "U",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "0AU75307",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "AU75307",
                        "productGuid": "46761A324619003300000000AC103C90",
                        "related": "",
                        "vehLpno": "CGN680GP",
                        "modelDesc": "E36 318is Sedan"
                    },
                    {
                        "bpOwner": "0013252985",
                        "bpDriver": "0013252985",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0AU00298",
                        "vinNumber": "ZBACB78080AU00298",
                        "brand": "BM",
                        "modelCode": "B-CB78",
                        "modelYear": "1996",
                        "intVehNo": "AU00298",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "AU00298",
                        "productGuid": "46765166A119007E00000000AC103C8F",
                        "related": "",
                        "vehLpno": "FDY068GP",
                        "modelDesc": "E36 323i Sedan"
                    }
                ]
            },
            "bpNo": "0013252985"
        }
    }));
}
function getGcdmCustomerProfile() {
    return rxjs_1.of(new http_1.HttpResponse({
        status: 200,
        body: [{
                "businessPartner": {
                    "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                    "ucid": "C477319542",
                    "partnerType": "PAR_TYP_PRIVATE",
                    "partnerCategory": "PERSON",
                    "surname": "Smith",
                    "givenName": "Tes",
                    "salutation": "SAL_DR",
                    "initialsName": "T",
                    "birthday": "1984-10-12",
                    "gender": "MALE",
                    "correspondLanguageISO": "EN",
                    "customerStatus": "ACTIVE",
                    "homeMarket": "ZA",
                    "profile": {
                        "personalIdentifications": {
                            "personalIdentifications": [{
                                    "id": "20201023001",
                                    "type": "PER_IDE_IDENTIFICATION_NUMBER"
                                }]
                        }, "communicationTypePreferred": "TEL"
                    },
                    "addresses": {
                        "addresses": [{
                                "addressGUID": "d3bf4833-c2a0-4652-8070-21583a5b745e",
                                "street": "P.O. Box 2517",
                                "strSuppl2": "Roodepoort",
                                "postalCode": "1724",
                                "city": "Roodekrans Ext 1",
                                "districtName": "Roodepoort",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "f97fdc39-e8b8-4a6a-9a95-070cd2fc80fd",
                                "street": "345 Voortrekker Street",
                                "strSuppl1": "Roode",
                                "strSuppl2": "Roodepoort",
                                "postalCode": "2410",
                                "city": "Balfour",
                                "districtName": "Balfour",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "0620cecf-ea4d-4532-8efa-103263d932e4",
                                "street": "678 Nqonqoza Street",
                                "postalCode": "5321",
                                "city": "Blue Crane Route",
                                "districtName": "Bongweni",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "2a2db0fa-1530-49e5-8858-0c9c07d24a05",
                                "street": "453 Bushwillow Avenue",
                                "postalCode": "0157",
                                "city": "Centurion",
                                "districtName": "The Reeds Ext 34",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "8cfc23a2-0380-48c9-8660-8d0ef79879aa",
                                "street": "4569 Rihlampfu Street",
                                "postalCode": "0510",
                                "city": "Modimolle-Mookgophong",
                                "districtName": "Phomolong Ext 5",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "f841c5f9-79ed-45fd-bed1-3160d3e4a4c8",
                                "street": "347 Aletta Avenue",
                                "postalCode": "0157",
                                "city": "Centurion",
                                "districtName": "Raslouw AH",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "aac5230e-20ba-4523-9b21-91e021eec3f1",
                                "street": "349 Petunia Road",
                                "postalCode": "7764",
                                "city": "Cape Town",
                                "districtName": "Bridgetown",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "ae8022c8-3a2a-4911-883e-de141e54c3ad",
                                "street": "896 Makgale Street",
                                "postalCode": "2551",
                                "city": "City of Matlosana",
                                "districtName": "Khuma Proper",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "e7e8e3ea-7286-4e5d-9c17-dea9ebeaa54e",
                                "street": "196 Cuba Street",
                                "postalCode": "1632",
                                "city": "Tembisa",
                                "districtName": "Umthambeka",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "64b4e4bc-c936-4c1a-8d48-85fc9988a4fd",
                                "street": "285 Cuba Street",
                                "postalCode": "1632",
                                "city": "Tembisa",
                                "districtName": "Umthambeka",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "aa8fee39-7c58-456a-9fd3-90ebde9138da",
                                "street": "234 16th Avenue",
                                "postalCode": "2090",
                                "city": "Alexandra",
                                "districtName": "Alexandra Ext 22",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": true
                            }]
                    },
                    "communications": {
                        "communicationPhones": [{
                                "consGUID": "eaf8979b-3396-476f-be92-e62738ed9f10",
                                "communicationType": "TEL",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4567"
                            }, {
                                "consGUID": "1adc3d5a-d68d-40af-a278-b5fff674fb51",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4567"
                            }, {
                                "consGUID": "e07b9d68-2df6-41eb-aa2c-4279b2765323",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4563"
                            }, {
                                "consGUID": "7b14a695-f60f-47ff-9975-8901221b250a",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4565"
                            }, {
                                "consGUID": "671508a3-f18b-49b0-9c53-d30c4f3e0ee7",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4510"
                            }, {
                                "consGUID": "37ecb7b6-686a-41f6-b663-bbd0b9058663",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": true,
                                "value": "+27 82 123 2344"
                            }],
                        "communicationEMails": [{
                                "consGUID": "4ee913ba-3140-43e0-8287-f58a7c6c7ac2",
                                "communicationStatus": "PRIVATE",
                                "preferred": true,
                                "value": "prospect@byom.de",
                                "communicationType": "EMAIL"
                            }],
                        "availability": null
                    },
                    "dealers": {
                        "dealers": [{
                                "distributionPartnerId": "21730",
                                "outletId": "01",
                                "dealerGUID": "9baf67f2-be6f-42db-ae79-c5247fee02c6"
                            }, {
                                "distributionPartnerId": "23389",
                                "dealerGUID": "1ef5f0ce-3d43-46a7-9003-d490c18041e1"
                            }, {
                                "distributionPartnerId": "08384",
                                "outletId": "01",
                                "dealerGUID": "fa4b8ee7-eac5-43f0-bc5a-355ab7ab6439"
                            }, {
                                "distributionPartnerId": "02980",
                                "outletId": "01",
                                "dealerGUID": "c9f22cda-fb22-47dd-9a79-e33720292c5d"
                            }, {
                                "distributionPartnerId": "30773",
                                "outletId": "01",
                                "dealerGUID": "fee6fbf2-d42f-43d9-8440-476192eb67c0"
                            }, {
                                "distributionPartnerId": "02979",
                                "dealerGUID": "109c0004-234a-4ab9-999c-eaf66d3264b3"
                            }, {
                                "distributionPartnerId": "10237",
                                "outletId": "04",
                                "dealerGUID": "2247e25b-9c00-4445-84ad-6656b92aa112"
                            }, {
                                "distributionPartnerId": "45848",
                                "dealerGUID": "81a26e5e-6ad6-4b05-874a-1278bf051335"
                            }, {
                                "distributionPartnerId": "08384",
                                "dealerGUID": "fad58e95-062d-429d-a747-239652adb2de"
                            }, {
                                "distributionPartnerId": "42174",
                                "dealerGUID": "8c3e9b3f-49cd-4676-b5ac-7ab5fae11272"
                            }, {
                                "distributionPartnerId": "44046",
                                "dealerGUID": "dc196c37-d8cb-426e-a360-ae44ca80d22d"
                            }, {
                                "distributionPartnerId": "10237",
                                "dealerGUID": "d29fad8a-4e29-4dc8-a40d-744bf60e909c"
                            }, { "distributionPartnerId": "09616", "dealerGUID": "4e535510-9b82-4024-8859-fe6f2e5c2ed9" }]
                    },
                    "vehicles": {
                        "vehicles": [{
                                "brand": "BMW",
                                "series": "X6 Sport Activity Coupï¿½",
                                "vin": "WBAFH82090LT85911",
                                "constructionYear": "2015",
                                "vehicleGUID": "bac14503-a948-47a3-a31f-7bdc8ee689a4",
                                "bodyType": "Sport Activ",
                                "country": "ZA"
                            }]
                    },
                    "customExtension": {
                        "parameters": [{
                                "key": "CD_accountType",
                                "value": "PRIVATE"
                            }, { "key": "preferredCommunication", "value": "EMAIL" }, { "key": "CD_brand", "value": "BM" }]
                    },
                    "lastUpdated": { "at": "2020-11-09T14:12:56.889Z", "bySource": "CUSTOMER" },
                    "claims": [{ "id": "ZA", "timestamp": "2020-10-20T11:54:29.181Z" }]
                },
                "userAccount": {
                    "mail": "prospect@byom.de",
                    "notificationLevel": "NEW_DEVICE",
                    "authenticationLevel": "ONE_FACTOR",
                    "pwPolicyConform": true,
                    "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                    "secretKnowledgeActivated": false,
                    "status": "ACTIVATED"
                },
                "contactPolicyConsents": [{
                        "consent": "ACCEPT",
                        "consentDate": "2020-10-28T10:59:59.591Z",
                        "country": "ZA",
                        "expirationDate": "3019-10-29T10:59:59.591Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 0,
                        "policyId": "BMW_CONNECTED_DRIVE_PORTAL_TAC",
                        "purposeId": "TERMS_AND_CONDITIONS",
                        "touchpointId": "CDIP",
                        "usageId": "CONNECTED_DRIVE",
                        "language": "en",
                        "brand": "BMW"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "REJECT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }],
                "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                "ucid": "C477319542"
            }]
    }));
}
function getGcdmCustomerProfileOnePage() {
    return rxjs_1.of(new http_1.HttpResponse({
        status: 200,
        body: [{
                "businessPartner": {
                    "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                    "ucid": "C477319542",
                    "partnerType": "PAR_TYP_PRIVATE",
                    "partnerCategory": "PERSON",
                    "surname": "Smith",
                    "givenName": "Tes",
                    "salutation": "SAL_DR",
                    "initialsName": "T",
                    "birthday": "1984-10-12",
                    "gender": "MALE",
                    "correspondLanguageISO": "EN",
                    "customerStatus": "ACTIVE",
                    "homeMarket": "ZA",
                    "profile": {
                        "personalIdentifications": {
                            "personalIdentifications": [{
                                    "id": "20201023001",
                                    "type": "PER_IDE_IDENTIFICATION_NUMBER"
                                }]
                        }, "communicationTypePreferred": "TEL"
                    },
                    "addresses": {
                        "addresses": [{
                                "addressGUID": "d3bf4833-c2a0-4652-8070-21583a5b745e",
                                "street": "P.O. Box 2517",
                                "strSuppl2": "Roodepoort",
                                "postalCode": "1724",
                                "city": "Roodekrans Ext 1",
                                "districtName": "Roodepoort",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "f97fdc39-e8b8-4a6a-9a95-070cd2fc80fd",
                                "street": "345 Voortrekker Street",
                                "strSuppl1": "Roode",
                                "strSuppl2": "Roodepoort",
                                "postalCode": "2410",
                                "city": "Balfour",
                                "districtName": "Balfour",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "0620cecf-ea4d-4532-8efa-103263d932e4",
                                "street": "678 Nqonqoza Street",
                                "postalCode": "5321",
                                "city": "Blue Crane Route",
                                "districtName": "Bongweni",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "2a2db0fa-1530-49e5-8858-0c9c07d24a05",
                                "street": "453 Bushwillow Avenue",
                                "postalCode": "0157",
                                "city": "Centurion",
                                "districtName": "The Reeds Ext 34",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "8cfc23a2-0380-48c9-8660-8d0ef79879aa",
                                "street": "4569 Rihlampfu Street",
                                "postalCode": "0510",
                                "city": "Modimolle-Mookgophong",
                                "districtName": "Phomolong Ext 5",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "f841c5f9-79ed-45fd-bed1-3160d3e4a4c8",
                                "street": "347 Aletta Avenue",
                                "postalCode": "0157",
                                "city": "Centurion",
                                "districtName": "Raslouw AH",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "aac5230e-20ba-4523-9b21-91e021eec3f1",
                                "street": "349 Petunia Road",
                                "postalCode": "7764",
                                "city": "Cape Town",
                                "districtName": "Bridgetown",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "ae8022c8-3a2a-4911-883e-de141e54c3ad",
                                "street": "896 Makgale Street",
                                "postalCode": "2551",
                                "city": "City of Matlosana",
                                "districtName": "Khuma Proper",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "e7e8e3ea-7286-4e5d-9c17-dea9ebeaa54e",
                                "street": "196 Cuba Street",
                                "postalCode": "1632",
                                "city": "Tembisa",
                                "districtName": "Umthambeka",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "64b4e4bc-c936-4c1a-8d48-85fc9988a4fd",
                                "street": "285 Cuba Street",
                                "postalCode": "1632",
                                "city": "Tembisa",
                                "districtName": "Umthambeka",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": false
                            }, {
                                "addressGUID": "aa8fee39-7c58-456a-9fd3-90ebde9138da",
                                "street": "234 16th Avenue",
                                "postalCode": "2090",
                                "city": "Alexandra",
                                "districtName": "Alexandra Ext 22",
                                "country": "ZA",
                                "addressStatus": "PRIVATE",
                                "preferred": true
                            }]
                    },
                    "communications": {
                        "communicationPhones": [{
                                "consGUID": "eaf8979b-3396-476f-be92-e62738ed9f10",
                                "communicationType": "TEL",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4567"
                            }, {
                                "consGUID": "1adc3d5a-d68d-40af-a278-b5fff674fb51",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4567"
                            }, {
                                "consGUID": "e07b9d68-2df6-41eb-aa2c-4279b2765323",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4563"
                            }, {
                                "consGUID": "7b14a695-f60f-47ff-9975-8901221b250a",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4565"
                            }, {
                                "consGUID": "671508a3-f18b-49b0-9c53-d30c4f3e0ee7",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": false,
                                "value": "+27 82 123 4510"
                            }, {
                                "consGUID": "37ecb7b6-686a-41f6-b663-bbd0b9058663",
                                "communicationType": "MOB",
                                "communicationStatus": "PRIVATE",
                                "preferred": true,
                                "value": "+27 82 123 2344"
                            }],
                        "communicationEMails": [{
                                "consGUID": "4ee913ba-3140-43e0-8287-f58a7c6c7ac2",
                                "communicationStatus": "PRIVATE",
                                "preferred": true,
                                "value": "prospect@byom.de",
                                "communicationType": "EMAIL"
                            }],
                        "availability": null
                    },
                    "dealers": {
                        "dealers": [{
                                "distributionPartnerId": "21730",
                                "outletId": "01",
                                "dealerGUID": "9baf67f2-be6f-42db-ae79-c5247fee02c6"
                            }, {
                                "distributionPartnerId": "23389",
                                "dealerGUID": "1ef5f0ce-3d43-46a7-9003-d490c18041e1"
                            }, {
                                "distributionPartnerId": "08384",
                                "outletId": "01",
                                "dealerGUID": "fa4b8ee7-eac5-43f0-bc5a-355ab7ab6439"
                            }, {
                                "distributionPartnerId": "02980",
                                "outletId": "01",
                                "dealerGUID": "c9f22cda-fb22-47dd-9a79-e33720292c5d"
                            }, {
                                "distributionPartnerId": "30773",
                                "outletId": "01",
                                "dealerGUID": "fee6fbf2-d42f-43d9-8440-476192eb67c0"
                            }, {
                                "distributionPartnerId": "02979",
                                "dealerGUID": "109c0004-234a-4ab9-999c-eaf66d3264b3"
                            }, {
                                "distributionPartnerId": "10237",
                                "outletId": "04",
                                "dealerGUID": "2247e25b-9c00-4445-84ad-6656b92aa112"
                            }, {
                                "distributionPartnerId": "45848",
                                "dealerGUID": "81a26e5e-6ad6-4b05-874a-1278bf051335"
                            }, {
                                "distributionPartnerId": "08384",
                                "dealerGUID": "fad58e95-062d-429d-a747-239652adb2de"
                            }, {
                                "distributionPartnerId": "42174",
                                "dealerGUID": "8c3e9b3f-49cd-4676-b5ac-7ab5fae11272"
                            }, {
                                "distributionPartnerId": "44046",
                                "dealerGUID": "dc196c37-d8cb-426e-a360-ae44ca80d22d"
                            }, {
                                "distributionPartnerId": "10237",
                                "dealerGUID": "d29fad8a-4e29-4dc8-a40d-744bf60e909c"
                            }, { "distributionPartnerId": "09616", "dealerGUID": "4e535510-9b82-4024-8859-fe6f2e5c2ed9" }]
                    },
                    "vehicles": {
                        "vehicles": [{
                                "brand": "BMW",
                                "series": "X6 Sport Activity Coupï¿½",
                                "vin": "WBAFH82090LT85911",
                                "constructionYear": "2015",
                                "vehicleGUID": "bac14503-a948-47a3-a31f-7bdc8ee689a4",
                                "bodyType": "Sport Activ",
                                "country": "ZA"
                            }]
                    },
                    "customExtension": {
                        "parameters": [{
                                "key": "CD_accountType",
                                "value": "PRIVATE"
                            }, { "key": "preferredCommunication", "value": "EMAIL" }, { "key": "CD_brand", "value": "BM" }]
                    },
                    "lastUpdated": { "at": "2020-11-09T14:12:56.889Z", "bySource": "CUSTOMER" },
                    "claims": [{ "id": "ZA", "timestamp": "2020-10-20T11:54:29.181Z" }]
                },
                "userAccount": {
                    "mail": "prospect@byom.de",
                    "notificationLevel": "NEW_DEVICE",
                    "authenticationLevel": "ONE_FACTOR",
                    "pwPolicyConform": true,
                    "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                    "secretKnowledgeActivated": false,
                    "status": "ACTIVATED"
                },
                "contactPolicyConsents": [{
                        "consent": "ACCEPT",
                        "consentDate": "2020-10-28T10:59:59.591Z",
                        "country": "ZA",
                        "expirationDate": "3019-10-29T10:59:59.591Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 0,
                        "policyId": "BMW_CONNECTED_DRIVE_PORTAL_TAC",
                        "purposeId": "TERMS_AND_CONDITIONS",
                        "touchpointId": "CDIP",
                        "usageId": "CONNECTED_DRIVE",
                        "language": "en",
                        "brand": "BMW"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-10T10:59:31Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-11T10:59:31Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 2,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }, {
                        "consent": "REJECT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "MAIL",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "ANALYTICS",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "DATA_PRIVACY",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "DATAPROCESSING",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "IN_CAR",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "PHONE_MESSENGER_SERVICES",
                        "language": "en"
                    }, {
                        "consent": "ACCEPT",
                        "consentDate": "2020-11-09T03:09:13Z",
                        "country": "ZA",
                        "expirationDate": "3019-11-10T03:09:13Z",
                        "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "majorVersion": 2,
                        "minorVersion": 1,
                        "policyId": "Privacy_Statement",
                        "purposeId": "MARKETING",
                        "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                        "usageId": "EMAIL",
                        "language": "en"
                    }],
                "gcid": "8d4844f3-c9ce-461a-bc9c-d106c56419a3",
                "ucid": "C477319542"
            }]
    }));
}
