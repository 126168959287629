"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerdetailsPolicystoremodalComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var policy_store_consent_service_1 = require("@/_services/policy-store-consent.service");
var storage_service_1 = require("@/_services/storage-service");
var customerdetailsPolicystoremodalComponent = /** @class */ (function () {
    function customerdetailsPolicystoremodalComponent(formBuilder, router, location, authenticationService, userService, alertService, activateRoute, storageService, policyStoreConsentService, modalService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.router = router;
        this.location = location;
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.alertService = alertService;
        this.activateRoute = activateRoute;
        this.storageService = storageService;
        this.policyStoreConsentService = policyStoreConsentService;
        this.modalService = modalService;
        this.loading = false;
        this.exist = false;
        this.submitted = false;
        this.redirectUrl = "";
        this.model = {};
        this.passwordWeak = false;
        this.lower = false;
        this.upper = false;
        this.number = false;
        this.length = false;
        this.special = false;
        this.allowed = false;
        this.isDataLoaded = false;
        this.isSuccess = false;
        this.usageDefinitions = [];
        this.usageTexts = [];
        this.usages = [];
        this.policyStoreConsentData = [];
        this.policyConsentData = [];
        this.termsAndConditions = [];
        this.isMandatoryCheckBox = false;
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["/"]);
        }
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.userService
            .policyStoreReg()
            .subscribe(function (policyStoreConsent) {
            _this.policyStoreConsentData = policyStoreConsent;
            _this.getRegPolicy();
            _this.isDataLoaded = true;
        });
    }
    customerdetailsPolicystoremodalComponent.prototype.getRegPolicy = function () {
        var _this = this;
        this.policyStoreConsentData.textBlocks.forEach(function (o, i) {
            _this.usageDefinitions = o.usageDefinitions;
            if (o.usageDefinitions !== undefined && o.usageText !== undefined) {
                o.usageText.forEach(function (usageText, i) {
                    var isPresent = o.usageDefinitions[i].usages.find(function (p) { return p.variant === 'MANDATORY' || p.mandatory; });
                    if (isPresent) {
                        o.usageDefinitions[i].purposes.forEach(function (p, i) {
                            _this.perpose = p.purpose.name;
                        });
                        _this.termsAndConditions.push({ primaryText: o.usageDefinitions[i].textPrimary + "*",
                            textUsage: usageText.value, isMandatory: true, id: i, purpose: _this.perpose, validate: 'is-invalid' });
                    }
                    else {
                        o.usageDefinitions[i].purposes.forEach(function (p, i) {
                            _this.perpose = p.purpose.name;
                        });
                        _this.termsAndConditions.push({ primaryText: o.usageDefinitions[i].textPrimary,
                            textUsage: usageText.value, isMandatory: false, id: i, purpose: _this.perpose, validate: 'is-valid' });
                    }
                });
            }
        });
        this.termsAndConditions.forEach(function (o, i) {
            var control = new forms_1.FormControl(false);
            _this.registerForm.controls.termsAndConditions.push(control);
        });
    };
    customerdetailsPolicystoremodalComponent.prototype.toggleVisibility = function (event, isMandtory, num, perpose) {
        if (isMandtory) {
            this.isMandatoryCheckBox = true;
            this.perpose = perpose;
            this.consent = "REJECT";
        }
        if (!isMandtory && event.target.checked) {
            this.consent = "ACCEPT";
        }
        if (!isMandtory && !event.target.checked) {
            this.consent = "REJECT";
        }
    };
    customerdetailsPolicystoremodalComponent.prototype.deserialize = function (input) {
        Object.assign(this, input);
        return this;
    };
    customerdetailsPolicystoremodalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registerForm = this.formBuilder.group({
            termsAndConditions: this.formBuilder.array([], [this.minSelectedCheckboxes])
        });
        this.activateRoute.queryParams.subscribe(function (params) {
            _this.redirectUrl = params["returnUrl"];
        });
        this.router.events
            .pipe(operators_1.filter(function (e) { return e instanceof router_1.RoutesRecognized; }), operators_1.pairwise())
            .subscribe(function (e) {
            console.log(e[0].urlAfterRedirects); // previous url
        });
        if (window.location.href.indexOf("register") != -1) {
            $("body").addClass("exs_bg1");
        }
    };
    Object.defineProperty(customerdetailsPolicystoremodalComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    customerdetailsPolicystoremodalComponent.prototype.onReject = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    customerdetailsPolicystoremodalComponent.prototype.onSubmit = function (pageRendering) {
        var _this = this;
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.touchpointId = "CUSTOMER_PORTAL_WEB_SITE_ZA";
        this.country = this.policyStoreConsentData.country;
        this.majorVersion = this.policyStoreConsentData.majorVersion;
        this.minorVersion = this.policyStoreConsentData.minorVersion;
        this.policyId = this.policyStoreConsentData.name;
        this.language = this.policyStoreConsentData.textBlocks[0].language;
        var datePipe = new common_1.DatePipe("en-US");
        this.usageDefinitions.forEach(function (def, i) {
            def.usages.forEach(function (usg, i) {
                def.legalEntities.forEach(function (leg, i) {
                    def.purposes.forEach(function (perp, i) {
                        if (_this.isMandatoryCheckBox && _this.perpose === perp.purpose.name) {
                            var contactPolicyConsent = {
                                consent: "ACCEPT",
                                consentDate: datePipe.transform(new Date(), "yyy-MM-ddThh:mm:ss"),
                                country: _this.country,
                                language: _this.language,
                                legalEntityId: leg.name,
                                majorVersion: _this.majorVersion,
                                minorVersion: _this.minorVersion,
                                policyId: _this.policyId,
                                touchpointId: _this.touchpointId,
                                purposeId: perp.purpose.name,
                                usageId: usg.usage.name
                            };
                            _this.policyConsentData.push(contactPolicyConsent);
                        }
                        else {
                            var contactPolicyConsent = {
                                consent: _this.consent,
                                consentDate: datePipe.transform(new Date(), "yyy-MM-ddThh:mm:ss"),
                                country: _this.country,
                                language: _this.language,
                                legalEntityId: leg.name,
                                majorVersion: _this.majorVersion,
                                minorVersion: _this.minorVersion,
                                policyId: _this.policyId,
                                touchpointId: _this.touchpointId,
                                purposeId: perp.purpose.name,
                                usageId: usg.usage.name
                            };
                            _this.policyConsentData.push(contactPolicyConsent);
                        }
                    });
                });
            });
        });
        this.policyStoreConsentService
            .updatePolicyStoreConsent(this.policyConsentData)
            .pipe(operators_1.first())
            .subscribe(function (data) {
            _this.isDataLoaded = false;
            _this.modalService.dismissAll();
            _this.router.navigate(["/" + pageRendering]);
        }, function (error) {
            _this.loading = false;
        });
    };
    customerdetailsPolicystoremodalComponent.prototype.minSelectedCheckboxes = function (control) {
        var returnValue = false;
        for (var i = 0; i < control.value.length; i++) {
            // console.log("control.value[i] :::: ",control.value[i], i)
            if (control.value[i] && i === 0) {
                returnValue = true; // If any of the controls is checked, returnValue will be set to true at some point in this loop
            }
        }
        if (!returnValue) {
            return { controlStatus: { status: 'invalid' } }; // Returning an object of some sort, doesn't matter what, is how you indicate the control is not valid.
        }
        else {
            return null; // Returning null is how you indicate the control is valid.
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", String)
    ], customerdetailsPolicystoremodalComponent.prototype, "pageRendering", void 0);
    customerdetailsPolicystoremodalComponent = __decorate([
        core_1.Component({
            template: require('./customerdetailspolicystoremodal.component.html')
        }),
        __metadata("design:paramtypes", [forms_1.FormBuilder,
            router_1.Router,
            common_1.Location,
            _services_1.AuthenticationService,
            _services_1.UserService,
            _services_1.AlertService,
            router_1.ActivatedRoute,
            storage_service_1.StorageService,
            policy_store_consent_service_1.PolicyStoreConsentService,
            ng_bootstrap_1.NgbModal])
    ], customerdetailsPolicystoremodalComponent);
    return customerdetailsPolicystoremodalComponent;
}());
exports.customerdetailsPolicystoremodalComponent = customerdetailsPolicystoremodalComponent;
