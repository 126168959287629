"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleCarouselComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var storage_service_1 = require("@/_services/storage-service");
var addvehiclemodal_component_1 = require("./addvehiclemodal/addvehiclemodal.component");
var add_remove_vehicle_service_1 = require("@/_services/add-remove-vehicle.service");
var _services_1 = require("@/_services");
var _services_2 = require("@/_services/");
var ngx_useful_swiper_1 = require("ngx-useful-swiper");
var VehicleCarouselComponent = /** @class */ (function () {
    function VehicleCarouselComponent(storageService, modalService, addRemoveVehicleService, authenticationService, alertService) {
        this.storageService = storageService;
        this.modalService = modalService;
        this.addRemoveVehicleService = addRemoveVehicleService;
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.config = {
            pagination: { el: '.swiper-pagination', clickable: true },
            slidesPerView: 1,
            autoHeight: true,
            allowTouchMove: true,
            watchOverflow: true,
            breakpoints: {
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            loop: false
        };
        this.vehicleDetailsFromCarousel = new core_1.EventEmitter();
        this.sortReverse = true;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.loginBrand = this.get_gcdm_config['brand'];
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
    }
    VehicleCarouselComponent.prototype.nextSlide = function () {
        this.usefulSwiper.swiper.slideNext();
    };
    VehicleCarouselComponent.prototype.previousSlide = function () {
        this.usefulSwiper.swiper.slidePrev();
    };
    VehicleCarouselComponent.prototype.slideToThis = function (index) {
        this.usefulSwiper.swiper.slideTo(index);
    };
    VehicleCarouselComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    VehicleCarouselComponent.prototype.trackByMethod = function (index, el) {
        return index;
    };
    VehicleCarouselComponent.prototype.onSelect = function (vehicleDetails) {
        if (this.loginBrand !== vehicleDetails.brand) {
            this.storageService.removeItems();
            this.storageService.clearStore();
            sessionStorage.clear();
            this.storageService.setCurrentVehicles('');
            window.location.href = this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + this.ssoBrandSwitch(vehicleDetails.brand) + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + this.ssoPageSwitch(vehicleDetails.brand) + "%2Fgcdm%2Findex%2Fauth&state=" + this.get_gcdm_config['state'];
        }
        else {
            this.vehicleDetail = vehicleDetails.vinNumber;
            this.storageService.setVin(vehicleDetails.vinNumber);
            this.storageService.setSelectedVehicle(vehicleDetails);
            this.vehicleDetailsFromCarousel.emit(vehicleDetails);
        }
    };
    VehicleCarouselComponent.prototype.ssoBrandSwitch = function (val) {
        var answer = "";
        switch (val) {
            case "MI":
                answer = "mini";
                break;
            case "BM":
                answer = "bmw";
                break;
            case "MO":
                answer = "bmwmotorrad";
                break;
        }
        return answer;
    };
    VehicleCarouselComponent.prototype.ssoPageSwitch = function (val) {
        var url = "";
        switch (val) {
            case "MI":
                url = process.env.MIbrand;
                break;
            case "BM":
                url = process.env.BMbrand;
                break;
            case "MO":
                url = process.env.MObrand;
                break;
        }
        return url;
    };
    VehicleCarouselComponent.prototype.ngOnInit = function () {
        this.vehicleDetail = this.storageService.getVin();
    };
    VehicleCarouselComponent.prototype.openAddVehicleModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(addvehiclemodal_component_1.AddVehicleModalComponent);
        modalRef.componentInstance.my_modal_title = 'ADD A VEHICLE';
        modalRef.result.then(function (result) {
            _this.addRemoveVehicleService.addVehicle(result)
                .subscribe(function (data) {
                _this.alertService.success("Request submitted. We will contact you shortly to complete your vehicle addition.", true);
            }, function (error) {
                _this.alertService.error(error);
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    VehicleCarouselComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    __decorate([
        core_1.ViewChild('usefulSwiper', { static: false }),
        __metadata("design:type", ngx_useful_swiper_1.SwiperComponent)
    ], VehicleCarouselComponent.prototype, "usefulSwiper", void 0);
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], VehicleCarouselComponent.prototype, "childCosyImages", void 0);
    __decorate([
        core_1.Output(),
        __metadata("design:type", Object)
    ], VehicleCarouselComponent.prototype, "vehicleDetailsFromCarousel", void 0);
    VehicleCarouselComponent = __decorate([
        core_1.Component({
            selector: 'vehicle-carousel',
            template: require('./vehiclecarousel.component.html')
        }),
        __metadata("design:paramtypes", [storage_service_1.StorageService,
            ng_bootstrap_1.NgbModal,
            add_remove_vehicle_service_1.AddRemoveVehicleService,
            _services_2.AuthenticationService,
            _services_1.AlertService])
    ], VehicleCarouselComponent);
    return VehicleCarouselComponent;
}());
exports.VehicleCarouselComponent = VehicleCarouselComponent;
