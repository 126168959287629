"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceInfoComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var vehicle_service_option_service_1 = require("@/_services/vehicle-service-option.service");
var ServiceOptDescriptions = require("@/_content/serviceOptionsData/ServiceOptionsData.json");
var vehicle_details_service_1 = require("@/_services/vehicle-details.service");
var storage_service_1 = require("@/_services/storage-service");
var vehicle_cosy_image_service_1 = require("@/_services/vehicle-cosy.image.service");
var vehicle_cosy_image_1 = require("@/_models/vehicle-cosy-image");
var vehicle_service_history_service_1 = require("@/_services/vehicle-service-history.service");
var vehicle_tyre_search_service_1 = require("@/_services/vehicle-tyre-search.service");
var _services_1 = require("@/_services");
var ServiceInfoComponent = /** @class */ (function () {
    function ServiceInfoComponent(activatedRoute, vehicleCosyImageService, vehicleServiceOptionService, vehicleDetailsService, storageService, vehicleServiceHistorysService, vehiclTyreSearchService, router, authenticationService) {
        this.activatedRoute = activatedRoute;
        this.vehicleCosyImageService = vehicleCosyImageService;
        this.vehicleServiceOptionService = vehicleServiceOptionService;
        this.vehicleDetailsService = vehicleDetailsService;
        this.storageService = storageService;
        this.vehicleServiceHistorysService = vehicleServiceHistorysService;
        this.vehiclTyreSearchService = vehiclTyreSearchService;
        this.router = router;
        this.authenticationService = authenticationService;
        this.loading = false;
        this.selectedItems = [];
        this.vehicleDefects = [];
        this.vehicles = [];
        this.vehicleCosyImages = [];
        this.vehicleServiceHistory = [];
        this.isNavShown = false;
        this.isHideServiceOption = false;
        this.isHideTyreSearch = false;
        this.isHideVHistory = false;
        this.parentIsEmptyServiceOption = false;
        this.parentIsEmptyTyreSearch = false;
        this.serviceOptDescriptions = ServiceOptDescriptions;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.loginBrand = this.get_gcdm_config['brand'];
        this.loadVehicleVehicleInfo();
    }
    ServiceInfoComponent.prototype.loadVehicleVehicleInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.vehicleDetails = this.storageService.getCurrentVehicles();
                this.loadVehicleCosyImage(this.vehicleDetails);
                return [2 /*return*/];
            });
        });
    };
    ServiceInfoComponent.prototype.loadVehicleCosyImage = function (vehicleDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.loading = true;
                if (vehicleDetails.vehicles.findvehicle !== null) {
                    this.vehicles = vehicleDetails.vehicles.findvehicle;
                    this.vehicles.forEach(function (o, i) {
                        if (_this.storageService.getVin() === o.vinNumber) {
                            _this.passVehicleDetails(o, true);
                        }
                        var vehicleCosyImageObservable = _this.vehicleCosyImageService.getVehicleCosyImage(o.vinNumber);
                        vehicleCosyImageObservable.subscribe(function (vehicleCosyImage) {
                            _this.vehicleCosyImage = vehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X") {
                                _this.vehicleCosyImage.cosyImageUrlList.forEach(function (l, i) {
                                    if (l.indexOf("http") !== -1 || l.indexOf("https") !== -1) {
                                        _this.vehicleCosyImage.vehicleDetails = o;
                                        // if(l.indexOf("bmwgroup.net") !== -1){
                                        //     l = l.replace('bmwgroup.net', 'bmwgroup.com');
                                        //     // l = l.replace('http://', 'https://');
                                        // }
                                        _this.vehicleCosyImage.cosyImageUrlList = [l];
                                        if (_this.storageService.getVin() === o.vinNumber) {
                                            _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                        }
                                        else {
                                            _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                        }
                                    }
                                    else {
                                        if (o.brand === 'MI') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/mini-cosySec.png')];
                                        }
                                        if (o.brand === 'BM') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/bmw-cosySec.png')];
                                        }
                                        if (o.brand === 'MO') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/cosyMotorrad.png')];
                                        }
                                        if (_this.storageService.getVin() === o.vinNumber) {
                                            _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                        }
                                        else {
                                            _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                        }
                                    }
                                });
                            }
                        }, function (error) {
                            _this.vehicleCosyImage = new vehicle_cosy_image_1.VehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X") {
                                if (o.brand === 'MI') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/mini-cosySec.png')];
                                }
                                if (o.brand === 'BM') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/bmw-cosySec.png')];
                                }
                                if (o.brand === 'MO') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../../_content/imgs/Cosy/cosyMotorrad.png')];
                                }
                                if (_this.storageService.getVin() === o.vinNumber) {
                                    _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                }
                                else {
                                    _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                }
                            }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ServiceInfoComponent.prototype.loadVehicleTyreSearch = function (vinNumber) {
        var _this = this;
        var vehicleServiceTyreSearchObservable = this.vehiclTyreSearchService.getVehicleTyreSearch(vinNumber);
        vehicleServiceTyreSearchObservable.subscribe(function (vehicleTyreSearch) {
            _this.vehicleTyreSearch = vehicleTyreSearch;
            if (_this.vehicleTyreSearch.etReturn === null) {
                _this.vehicleTyreSearch.z55TyreInfo.forEach(function (o, i) {
                    if (o.rimdescr.indexOf("Please supply") !== -1) {
                        _this.isHideTyreSearch = true;
                    }
                    else {
                        _this.isHideTyreSearch = false;
                        _this.parentIsEmptyTyreSearch = false;
                        var frontPrice = 0;
                        var rearPrice = 0;
                        var fullPrice = 0;
                        if (o.fitted === 'F') {
                            if (i === 0) {
                                frontPrice = (Number(o.retailPrice) * 2) * 1.15;
                                var isPresent = _this.vehicleTyreSearch.z55TyreInfo.find(function (p) { return p.manufacturer === o.manufacturer && p.fitted === 'R'; });
                                rearPrice = (Number(isPresent.retailPrice) * 2) * 1.15;
                                fullPrice = frontPrice + rearPrice;
                                _this.vehicleTyreSearchParent = { tyerInfomation: o, retailPrice: fullPrice, vehicleTyreSearch: _this.vehicleTyreSearch };
                            }
                        }
                        else if (o.fitted === 'A') {
                            if (i === 0) {
                                _this.vehicleTyreSearchParent = { tyerInfomation: o, retailPrice: (Number(o.retailPrice) * 4) * 1.15, vehicleTyreSearch: _this.vehicleTyreSearch };
                            }
                        }
                    }
                });
            }
            else {
                _this.isHideTyreSearch = true;
            }
        });
    };
    ServiceInfoComponent.prototype.loadVehicleHistory = function (vinNumber) {
        var _this = this;
        var vehicleServiceHistoryObservable = this.vehicleServiceHistorysService.getVehicleServiceHistory(vinNumber);
        vehicleServiceHistoryObservable.subscribe(function (vehicleServiceHistory) {
            _this.vehicleServiceHistory = vehicleServiceHistory;
            if (_this.vehicleServiceHistory && _this.vehicleServiceHistory.length) {
                _this.vehicleServiceHistory.forEach(function (o, i) {
                    if (i === 0) {
                        _this.isHideVHistory = true;
                        _this.loading = true;
                        var year = o.clmDateRepaired;
                        var getYear = year.slice(0, 4);
                        var month = o.clmDateRepaired;
                        var getMonth = month.slice(4, 6);
                        var day = o.clmDateRepaired;
                        var getDay = day.slice(6, 8);
                        _this.vehicleServiceHistParent = { clmDateRepaired: getYear + "-" + getMonth + "-" + getDay, serviceHistory: o };
                    }
                });
            }
            else {
                _this.isHideVHistory = false;
            }
        });
    };
    ServiceInfoComponent.prototype.passVehicleDetails = function (vehicleDetail, check) {
        this.parentIsEmptyServiceOption = true;
        this.parentIsEmptyTyreSearch = true;
        this.storageService.setVin(vehicleDetail.vinNumber);
        this.loadVehicleHistory(vehicleDetail.vinNumber);
        this.loadVehicleTyreSearch(vehicleDetail.vinNumber);
        this.loadServiceOptions(vehicleDetail.bodyNumber, vehicleDetail.modelYear);
    };
    ServiceInfoComponent.prototype.loadServiceOptions = function (bodyNo, modelYear) {
        return __awaiter(this, void 0, void 0, function () {
            var frontBrake, rearBrake, serviceReset, ServiceOptionsObservable;
            var _this = this;
            return __generator(this, function (_a) {
                frontBrake = 0;
                rearBrake = 0;
                serviceReset = 0;
                ServiceOptionsObservable = this.vehicleServiceOptionService.getVehicleServiceOption(bodyNo, this.storageService.getDealerId(), modelYear);
                ServiceOptionsObservable.subscribe(function (serviceOptions) {
                    _this.serviceOptions = serviceOptions;
                    _this.vehicleDefects = [];
                    _this.loading = true;
                    if (_this.serviceOptions.defect !== undefined) {
                        _this.isHideServiceOption = false;
                        _this.parentIsEmptyServiceOption = false;
                        _this.serviceOptions.defect.forEach(function (o, i) {
                            if (o.defect === "99110475MP") {
                                frontBrake = o.inclVat;
                                _this.selectedItems.push(o);
                            }
                            if (o.defect === "99210429MP") {
                                rearBrake = o.inclVat;
                                _this.selectedItems.push(o);
                            }
                            if (o.defect === "99600999MP") {
                                serviceReset = o.inclVat;
                                _this.servRest = o;
                                _this.selectedItems.push(o);
                            }
                            var inclPrice;
                            var seqnr;
                            _this.serviceOptDescriptions.serviceDescriptions.forEach(function (p, j) {
                                if (p.id === "99600999MP") {
                                    if (o.defect === p.id) {
                                        inclPrice = o.inclVat;
                                        seqnr = o.seqnr;
                                        _this.servRest = { defects: p, inclPrice: inclPrice, seqnr: seqnr };
                                    }
                                }
                                if (o.defect === p.id) {
                                    inclPrice = o.inclVat;
                                    seqnr = o.seqnr;
                                    if (o.defect !== "99600999MP") {
                                        _this.vehicleDefects.push({ defects: p, inclPrice: inclPrice, seqnr: seqnr });
                                    }
                                }
                            });
                        });
                    }
                    else {
                        _this.isHideServiceOption = true;
                    }
                    _this.vehicleDefects.sort(function (a, b) {
                        return a.displayOrder > b.displayOrder;
                    });
                    _this.parentServiceOptions = { defects: _this.vehicleDefects, brakesFrontRear: Number(+frontBrake + +rearBrake + +serviceReset).toFixed(2), terms: _this.serviceOptDescriptions.terms, servRest: _this.servRest };
                });
                return [2 /*return*/];
            });
        });
    };
    ServiceInfoComponent.prototype.toggleNavShow = function () {
        this.isNavShown = !this.isNavShown;
    };
    ServiceInfoComponent.prototype.ngOnInit = function () {
        this.parentCosyImages = this.vehicleCosyImages;
        if (this.storageService.getOndemandDone() === 'dont-show') {
            this.hideOndemand = 'dont-show';
        }
    };
    ServiceInfoComponent = __decorate([
        core_1.Component({ template: require('./serviceinfo.component.html') }),
        __metadata("design:paramtypes", [router_1.ActivatedRoute,
            vehicle_cosy_image_service_1.VehicleCosyImageService,
            vehicle_service_option_service_1.VehicleServiceOptionService,
            vehicle_details_service_1.VehicleDetailsService,
            storage_service_1.StorageService,
            vehicle_service_history_service_1.VehicleServiceHistorysService,
            vehicle_tyre_search_service_1.VehiclTyreSearchService,
            router_1.Router,
            _services_1.AuthenticationService])
    ], ServiceInfoComponent);
    return ServiceInfoComponent;
}());
exports.ServiceInfoComponent = ServiceInfoComponent;
