"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var customeractivatemodal_component_1 = require("@/validatecustdetails/customerdetailsactivationmodal/customeractivatemodal.component");
var customerdetailspolicystoremodal_component_1 = require("@/validatecustdetails/customerdetailspolicystoremodal/customerdetailspolicystoremodal.component");
var storage_service_1 = require("@/_services/storage-service");
var service_online_service_1 = require("@/_services/service-online.service");
var onDemand_login_bp_service_1 = require("@/_services/onDemand-login-bp.service");
var _services_1 = require("@/_services");
var vehicle_details_service_1 = require("@/_services/vehicle-details.service");
var AuthenticationComponent = /** @class */ (function () {
    function AuthenticationComponent(router, modalService, route, authenticationService, serviceOnlineService, userService, storageService, ondemandLoginBpService, vehicleDetailsService) {
        this.router = router;
        this.modalService = modalService;
        this.route = route;
        this.authenticationService = authenticationService;
        this.serviceOnlineService = serviceOnlineService;
        this.userService = userService;
        this.storageService = storageService;
        this.ondemandLoginBpService = ondemandLoginBpService;
        this.vehicleDetailsService = vehicleDetailsService;
        this.submitted = false;
        this.policyStoreConsentData = [];
        this.loading = false;
        this.vehicles = [];
        this.isGcdmDoc = false;
        this.data = null;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
    }
    AuthenticationComponent.prototype.ssoBrandSwitch = function (val) {
        var answer = "";
        switch (val) {
            case "MI":
                answer = "mini";
                break;
            case "BM":
                answer = "bmw";
                break;
            case "MO":
                answer = "bmwmotorrad";
                break;
        }
        return answer;
    };
    AuthenticationComponent.prototype.ssoPageSwitch = function (val) {
        var url = "";
        switch (val) {
            case "MI":
                url = process.env.MIbrand;
                break;
            case "BM":
                url = process.env.BMbrand;
                break;
            case "MO":
                url = process.env.MObrand;
                break;
        }
        return url;
    };
    AuthenticationComponent.prototype.loadCheckProcepect = function (access_token, customerDetails) {
        var _this = this;
        this.vehicleDetailsService.getVehicleForProcepect(access_token).subscribe(function (procepect) {
            _this.loading = true;
            _this.procepect = procepect;
            _this.storageService.setGcdmCustBp(_this.procepect.bpNo);
            if (_this.procepect.bpNo === "empty") {
                _this.loading = true;
                _this.pageRendering = 'prospect';
            }
            else {
                var isPresent = null;
                if (_this.procepect.vehicles.findvehicle !== null) {
                    isPresent = _this.procepect.vehicles.findvehicle.find(function (p) { return p.currentVehicle === "X"; });
                }
                if (!isPresent) {
                    _this.loading = true;
                    _this.pageRendering = 'prospect';
                }
                else {
                    var isTheCorrectBrand = _this.procepect.vehicles.findvehicle.find(function (p) { return p.brand === _this.get_gcdm_config['brand']; });
                    if (!isTheCorrectBrand) {
                        _this.storageService.removeItems();
                        _this.storageService.clearStore();
                        sessionStorage.clear();
                        _this.storageService.setCurrentVehicles('');
                        window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.ssoBrandSwitch(isPresent.brand) + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.ssoPageSwitch(isPresent.brand) + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                    }
                    _this.storageService.setCurrentVehicles(_this.procepect);
                    if (_this.get_gcdm_config['brand'] !== "MO") {
                        //         this.loadGiveSol(access_token);
                    }
                    _this.loading = true;
                    _this.pageRendering = 'home';
                }
            }
            _this.getCustomerProfile(customerDetails, _this.pageRendering);
        }, function (error) {
            console.error(error);
            _this.logout();
        });
    };
    AuthenticationComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    AuthenticationComponent.prototype.GetParamAccess = function (code) {
        this.loading = true;
        var results = new RegExp('[\\?&#]' + code + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return '';
        }
        return results[1] || '';
    };
    AuthenticationComponent.prototype.GetParamExpire = function (expires_in) {
        this.loading = true;
        var results = new RegExp('[\\?&]' + expires_in + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return '';
        }
        return results[1] || '';
    };
    AuthenticationComponent.prototype.loadOnDemandStatus = function (access_token) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.ondemandLoginBpService.getOnDamndStatus(access_token)
                    .subscribe(function (data) {
                    if (data.bpMarkAttr !== null) {
                        if (data.bpMarkAttr.ma !== null) {
                            _this.storageService.setOndemandDone("dont-show");
                        }
                        else {
                            _this.storageService.setOndemandDone("show");
                        }
                    }
                    else {
                        _this.storageService.setOndemandDone("show");
                    }
                }, function (error) {
                    // this.alertService.error(error);
                });
                return [2 /*return*/];
            });
        });
    };
    AuthenticationComponent.prototype.loadGiveSol = function (token) {
        var _this = this;
        this.serviceOnlineService.getSolKey(token).subscribe(function (sol) {
            _this.loading = true;
            _this.sol = sol;
            _this.loadRequestSOLToken(_this.sol);
        }, function (error) {
            console.error(error);
        });
    };
    AuthenticationComponent.prototype.loadRequestSOLToken = function (sol) {
        var _this = this;
        this.serviceOnlineService.postRequestTokenServiceOnline(sol).subscribe(function (requestTokenResults) {
            _this.loading = true;
            _this.requestTokenResults = requestTokenResults;
            sessionStorage.setItem('regToken', _this.requestTokenResults.Token);
            if (_this.requestTokenResults.Token) {
                _this.loadCheckSOLpassword(_this.requestTokenResults.Token, sol);
            }
        }, function (error) {
            console.error(error);
        });
    };
    AuthenticationComponent.prototype.loadRequestSOLTokenCust = function (custmerEmail, sol) {
        var _this = this;
        this.serviceOnlineService.postRequestTokenServiceOnlineCust(custmerEmail).subscribe(function (requestTokenResults) {
            _this.loading = true;
            _this.requestTokenResults = requestTokenResults;
            sessionStorage.setItem('solToken', requestTokenResults.Token);
            _this.loadCheckSOLpasswordCust(requestTokenResults, custmerEmail, sol);
        }, function (error) {
            console.error(error);
        });
    };
    AuthenticationComponent.prototype.loadCheckSOLpassword = function (token, sol) {
        var _this = this;
        this.serviceOnlineService.postCheckPasswordServiceOnline(token, sol).subscribe(function (data) {
            _this.loading = true;
            _this.loadRegisterCustomerServiceOnline(sessionStorage.getItem('regToken'), sol);
            _this.storageService.setServiceOnlineUnAvail("");
        }, function (err) {
            _this.storageService.setServiceOnlineUnAvail("serviceUnAvail");
            _this.loading = false;
        });
    };
    AuthenticationComponent.prototype.loadCheckSOLpasswordCust = function (requestTokenResults, custmerEmail, sol) {
        var _this = this;
        var token = requestTokenResults.Token;
        this.serviceOnlineService.postCheckPasswordServiceOnlineCustomer(requestTokenResults.Token, custmerEmail, sol).subscribe(function (data) {
            _this.loading = true;
            _this.checCustomerPassword(data, token);
            _this.storageService.setServiceOnlineUnAvail("");
        }, function (err) {
            _this.loading = false;
            _this.storageService.setServiceOnlineUnAvail("serviceUnAvail");
            _this.checCustomerPassword({ Message: 'Authorization has been denied for this request.' }, token);
        });
    };
    AuthenticationComponent.prototype.checCustomerPassword = function (_checkPassword, token) {
        if (_checkPassword.ErrorCode === 0) {
            this.loadcheckSolUser(this.storageService.getGcdmEmail(), token);
        }
        else {
            if (_checkPassword.Message === 'Authorization has been denied for this request.') {
                this.loadcheckSolUser(this.storageService.getGcdmEmail(), token);
            }
            // alert('An error has occured. Please try again later.');
        }
        ;
    };
    AuthenticationComponent.prototype.loadcheckSolUser = function (custmerEmail, token) {
        var _this = this;
        this.serviceOnlineService.postGetRooftopListServiceOnline(token, custmerEmail).subscribe(function (data) {
            _this.loading = true;
            sessionStorage.setItem('RoofTopList', JSON.stringify(data.RooftopList));
        }), function (error) {
            _this.loading = false;
            console.error(error);
        };
    };
    AuthenticationComponent.prototype.listTilte = function (title) {
        if (title === 'SAL_MR') {
            return 'Mr';
        }
        if (title === 'SAL_MS') {
            return 'Ms';
        }
        if (title === 'SAL_MRS') {
            return 'Mrs';
        }
        if (title === 'SAL_MISS') {
            return 'Miss';
        }
        if (title === 'SAL_DR') {
            return 'Dr';
        }
    };
    AuthenticationComponent.prototype.loadRegisterCustomerServiceOnline = function (token, sol) {
        var _this = this;
        this.serviceOnlineService.postRegisterCustomerServiceOnline(this.storageService.getGcdmEmail(), this.listTilte(this.storageService.getGcdmCustTitle()), this.storageService.getGcdmCustNameFirst(), this.storageService.getGcdmCustNameLast(), this.storageService.getDistrictName(), this.storageService.getCity(), this.storageService.getStreet(), "", this.storageService.getPostalCode(), this.storageService.getCountry(), this.storageService.getPhoneValue(), this.storageService.getPhoneValue(), this.storageService.getPhoneValue(), token, sol).subscribe(function (data) {
            _this.loading = true;
        }, function (error) {
            console.error(error);
        });
        this.loadRequestSOLTokenCust(this.storageService.getGcdmEmail(), sol);
    };
    AuthenticationComponent.prototype.GetParam = function (code) {
        this.loading = true;
        var results = new RegExp('[\\?&]' + code + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return '';
        }
        return results[1] || '';
    };
    AuthenticationComponent.prototype.getCustomerProfile = function (customerDetails, pageRendering) {
        var _this = this;
        var ngbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        sessionStorage.setItem('loginData', JSON.stringify(customerDetails));
        customerDetails.forEach(function (o, i) {
            // localStorage.setItem('GCDM_Key', o.businessPartner.gcid);
            _this.storageService.setGcdmEmail(o.userAccount.mail);
            _this.storageService.setGcdmCustNameFirst(o.businessPartner.givenName);
            _this.storageService.setGcdmCustNameLast(o.businessPartner.surname);
            _this.storageService.setGcdmCustTitle(_this.listTilte(o.businessPartner.salutation));
            if (o.businessPartner.addresses !== undefined) {
                if (o.businessPartner.addresses.addresses !== undefined) {
                    o.businessPartner.addresses.addresses.forEach(function (a, i) {
                        if (a.preferred) {
                            _this.storageService.setCity(a.city);
                            _this.storageService.setDistrictName(a.districtName);
                            _this.storageService.setPostalCode(a.postalCode);
                            _this.storageService.setStrSuppl2(a.strSuppl2);
                            _this.storageService.setStreet(a.street);
                            _this.storageService.setCountry(a.country);
                        }
                    });
                }
            }
            else {
                _this.storageService.setCity('');
                _this.storageService.setDistrictName('');
                _this.storageService.setPostalCode('');
                _this.storageService.setStrSuppl2('');
                _this.storageService.setStreet('');
                _this.storageService.setCountry('');
            }
            if (o.businessPartner.communications !== undefined) {
                if (o.businessPartner.communications.communicationEMails !== undefined) {
                    o.businessPartner.communications.communicationEMails.forEach(function (c, i) {
                        if (c.preferred) {
                            _this.storageService.setEmailValue(c.value);
                        }
                    });
                }
            }
            else {
                _this.storageService.setEmailValue('');
            }
            if (o.businessPartner.communications !== undefined) {
                if (o.businessPartner.communications.communicationPhones !== undefined) {
                    o.businessPartner.communications.communicationPhones.forEach(function (c, i) {
                        if (c.preferred) {
                            _this.storageService.setPhoneValue(c.value);
                        }
                    });
                }
            }
            else {
                _this.storageService.setPhoneValue('');
            }
            if (o.businessPartner.profile !== undefined) {
                if (o.businessPartner.profile.personalIdentifications !== undefined) {
                    o.businessPartner.profile.personalIdentifications.personalIdentifications.forEach(function (p, i) {
                        _this.storageService.setGcdmIdNo(p.id);
                    });
                }
            }
            if (o.userAccount.status === 'UNCONFIRMED') {
                var modalRef = _this.modalService.open(customeractivatemodal_component_1.CustomerActivateModalComponent, ngbModalOptions);
                modalRef.componentInstance.customerEmail = o.userAccount.mail;
                return;
            }
            _this.userService.policyStoreReg().subscribe(function (policyStoreConsent) {
                if (o.contactPolicyConsents === undefined) {
                    var modalRef = _this.modalService.open(customerdetailspolicystoremodal_component_1.customerdetailsPolicystoremodalComponent, ngbModalOptions);
                    modalRef.componentInstance.pageRendering = _this.pageRendering;
                }
                var minorVersion = policyStoreConsent.minorVersion;
                _this.storageService.setPolicyMinorVersion(String(minorVersion));
                if (o.contactPolicyConsents !== undefined) {
                    var isPresent = o.contactPolicyConsents.find(function (p) { return p.country === policyStoreConsent.country
                        && p.policyId === policyStoreConsent.name
                        && p.majorVersion === policyStoreConsent.majorVersion; });
                    if (isPresent) {
                        if (pageRendering === 'prospect') {
                            _this.loading = true;
                            _this.router.navigate(['/prospect']);
                            return;
                        }
                        else {
                            _this.loading = true;
                            _this.router.navigate(['/home']);
                            return;
                        }
                    }
                    else {
                        var modalRef = _this.modalService.open(customerdetailspolicystoremodal_component_1.customerdetailsPolicystoremodalComponent, ngbModalOptions);
                        modalRef.componentInstance.pageRendering = _this.pageRendering;
                        return;
                    }
                }
            }, function (error) {
                _this.loading = true;
                _this.logout();
            });
        }, function (err) {
            _this.loading = false;
            _this.logout();
            console.log('Error getting token', err);
        });
    };
    AuthenticationComponent.prototype.loadCPData = function (custDetails) {
        this.loadCheckProcepect(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN'), custDetails);
    };
    AuthenticationComponent.prototype.ngOnInit = function () {
    };
    AuthenticationComponent = __decorate([
        core_1.Component({ selector: 'app-auth', template: require('./authentication.component.html') }),
        __metadata("design:paramtypes", [router_1.Router,
            ng_bootstrap_1.NgbModal,
            router_1.ActivatedRoute,
            _services_1.AuthenticationService,
            service_online_service_1.ServiceOnlineService,
            _services_1.UserService,
            storage_service_1.StorageService,
            onDemand_login_bp_service_1.OndemandLoginBpService,
            vehicle_details_service_1.VehicleDetailsService])
    ], AuthenticationComponent);
    return AuthenticationComponent;
}());
exports.AuthenticationComponent = AuthenticationComponent;
