"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinanceOverviewComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var vehicle_account_service_1 = require("@/_services/vehicle-account.service");
var vehicle_settlement_quote_service_1 = require("@/_services/vehicle-settlement-quote.service");
var _services_1 = require("@/_services");
var FinanceOverviewComponent = /** @class */ (function () {
    function FinanceOverviewComponent(authenticationService, userService, vehicleAccountService, vehicleSettlementQuoteService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.vehicleAccountService = vehicleAccountService;
        this.vehicleSettlementQuoteService = vehicleSettlementQuoteService;
        this.loading = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.loadVehicleAccount();
    }
    FinanceOverviewComponent.prototype.setVehicleAccounts = function (vehicleAccounts) {
        var vehicleAccountsTmp = vehicleAccounts;
        if (vehicleAccountsTmp.length > 0) {
            this.vehicleAccount = vehicleAccounts[0];
        }
        // this.vehicleAccountsMap = new Map(vehicleAccountsTmp.map(i => [i.termsConcluded, i]));
        this.loading = false;
    };
    FinanceOverviewComponent.prototype.downloadVehicleSettlementQuote = function (event) {
        // this.vehicleSettlementQuoteService.getVehicleSettlementQuote("190700102102895")
        //   .subscribe(response => {    
        //     this.saveFile(response.body, "settlement-qoute.pdf");
        //   });
    };
    FinanceOverviewComponent.prototype.loadVehicleAccount = function () {
        this.loading = true;
        // let vehicleAccountObservable = this.vehicleAccountService.getVehicleAccount("JORDAAN00098205");
        // vehicleAccountObservable.subscribe((vehicleAccount: VehicleAccount) => {this.vehicleAccount = vehicleAccount; console.log("this.vehicleAccount :: ",vehicleAccount);});
        // this.setVehicleAccount(vehicleAccountObservable);
    };
    FinanceOverviewComponent.prototype.setVehicleAccount = function (vehicleAccount) {
        this.vehicleAccount = vehicleAccount;
        this.loading = false;
    };
    FinanceOverviewComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    FinanceOverviewComponent.prototype.ngOnInit = function () {
    };
    FinanceOverviewComponent = __decorate([
        core_1.Component({
            selector: 'finance-overview',
            template: require('./financeoverview.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            vehicle_account_service_1.VehicleAccountService,
            vehicle_settlement_quote_service_1.VehicleSettlementQuoteService])
    ], FinanceOverviewComponent);
    return FinanceOverviewComponent;
}());
exports.FinanceOverviewComponent = FinanceOverviewComponent;
