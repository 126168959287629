"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyAccountComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var vehicle_account_service_1 = require("@/_services/vehicle-account.service");
var vehicle_settlement_quote_service_1 = require("@/_services/vehicle-settlement-quote.service");
var MyAccountComponent = /** @class */ (function () {
    function MyAccountComponent(authenticationService, userService, vehicleAccountService, alertService, vehicleSettlementQuoteService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.vehicleAccountService = vehicleAccountService;
        this.alertService = alertService;
        this.vehicleSettlementQuoteService = vehicleSettlementQuoteService;
        this.currentUser = this.authenticationService.currentUserValue;
        // this.loadVehicleAccounts();
        // this.loadRandomVehicleAccounts();
        this.loadVehicleAccount();
    }
    MyAccountComponent.prototype.downloadVehicleSettlementQuote = function (event) {
        // this.vehicleSettlementQuoteService.getVehicleSettlementQuote("190700102102895")
        //   .subscribe(response => {    
        //     this.saveFile(response.body, "settlement-qoute.pdf");
        //   });
    };
    MyAccountComponent.prototype.loadVehicleAccount = function () {
        this.loading = true;
        // let vehicleAccountObservable = this.vehicleAccountService.getVehicleAccount("JORDAAN00098205");
        // vehicleAccountObservable.subscribe((vehicleAccount: VehicleAccount) => {this.vehicleAccount = vehicleAccount; console.log("this.vehicleAccount :: ",vehicleAccount);});
        // this.setVehicleAccount(vehicleAccountObservable);
    };
    MyAccountComponent.prototype.setVehicleAccount = function (vehicleAccount) {
        this.vehicleAccount = vehicleAccount;
        this.loading = false;
    };
    // private loadVehicleAccounts() {
    //     this.loading = true;
    //     this.vehicleAccountService.getVehicleAccounts()
    //         .subscribe(
    //             vehicleAccounts => {
    //                 this.setVehicleAccounts(vehicleAccounts);
    //             }
    //             , () => this.onError("Could not load vehicle account")
    //         );
    // }
    // private loadRandomVehicleAccounts() {
    //     this.loading = true;
    //     this.setVehicleAccounts(this.vehicleAccountService.getRandomVehicleAccounts());
    // }
    // private setVehicleAccounts(vehicleAccounts: VehicleAccount[]) {
    //     let vehicleAccountsTmp = vehicleAccounts;
    //     if (vehicleAccountsTmp.length > 0) {
    //         this.vehicleAccount = vehicleAccounts[0];
    //     }
    //     this.vehicleAccountsMap = new Map(vehicleAccountsTmp.map(i => [i.termsConcluded, i]));
    //     this.loading = false
    // }
    MyAccountComponent.prototype.onError = function (error) {
        this.loading = false;
        this.alertService.error(error);
    };
    MyAccountComponent.prototype.ngOnInit = function () {
    };
    MyAccountComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    MyAccountComponent = __decorate([
        core_1.Component({ template: require('./myaccount.component.html') }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            vehicle_account_service_1.VehicleAccountService,
            _services_1.AlertService,
            vehicle_settlement_quote_service_1.VehicleSettlementQuoteService])
    ], MyAccountComponent);
    return MyAccountComponent;
}());
exports.MyAccountComponent = MyAccountComponent;
