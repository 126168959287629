"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InsertIDmdlComponent = void 0;
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var customer_profile_service_1 = require("@/_services/customer-profile.service");
var _services_1 = require("@/_services");
var onDemand_login_bp_service_1 = require("@/_services/onDemand-login-bp.service");
var storage_service_1 = require("@/_services/storage-service");
var _services_2 = require("@/_services");
var InsertIDmdlComponent = /** @class */ (function () {
    function InsertIDmdlComponent(activeModal, formBuilder, _formBuilder, storageService, customerProfileService, alertService, ondemandLoginBpService, authenticationService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this._formBuilder = _formBuilder;
        this.storageService = storageService;
        this.customerProfileService = customerProfileService;
        this.alertService = alertService;
        this.ondemandLoginBpService = ondemandLoginBpService;
        this.authenticationService = authenticationService;
        this.isLinear = false;
        this.emailList = [];
        this.ondemand_merge_accounts = [];
        this.customerEmailToBeMerge = [];
        this.loading = false;
        this.submitted = false;
        this.isEditable = false;
        this.idVersion = "ID";
        this.submitForm = function (stepper) {
            var _this = this;
            if (this.firstFormGroup.invalid) {
                return;
            }
            var idformat = /^[0-9]+$/;
            if (this.firstFormGroup.value.idtype === "SAID") {
                if (!idformat.test(this.firstFormGroup.value.firstCtrl) == true || this.firstFormGroup.value.firstCtrl.length !== 13) {
                    this.errorMessage = "ID Number must be numeric and 13 digits.";
                    stepper.reset();
                    return;
                }
            }
            if (this.storageService.getGcdmIdNo() !== null && this.storageService.getGcdmIdNo() !== this.firstFormGroup.value.firstCtrl) {
                this.errorMessage = "ID number does not match, please insert a correct ID number.";
                stepper.reset();
                return;
            }
            else {
                this.customerProfileService.getOnDemandGetCustInfo(this.firstFormGroup.value.firstCtrl, this.firstFormGroup.value.idtype).pipe(operators_1.first())
                    .subscribe(function (data) {
                    if (data.bpFound !== null) {
                        data.bpFound.forEach(function (o, i) {
                            if (o.emailAddr !== '' && _this.validateEmail(o.emailAddr)) {
                                var list = { maskEmail: _this.obscure_email(o.emailAddr), emailAddress: o.emailAddr, disabled: false, checked: false, bpNo: o.bpNo };
                                if (o.nameLast.trim().toUpperCase() === _this.storageService.getGcdmCustNameLast().trim().toUpperCase()
                                    && o.bpNo !== _this.storageService.getGcdmCustBp()) {
                                    _this.emailList.push(list);
                                }
                            }
                        });
                        if (_this.emailList.length === 0) {
                            _this.errorMessage = 'Your details do not match the account information, contact us on 0800 600 555 for assistance.';
                            stepper.reset();
                            return;
                        }
                        _this.emailList.forEach(function (o, i) {
                            var control = new forms_1.FormControl(false);
                            _this.secondFormGroup.controls.emailList.push(control);
                        });
                    }
                    else {
                        _this.errorMessage = 'Your details do not match the account information, contact us on 0800 600 555 for assistance.';
                        stepper.reset();
                    }
                }, function (error) {
                    _this.alertService.error(error);
                });
                this.loading = true;
            }
        };
        this.ondemand_submit = function () {
            var _this = this;
            this.submitted = true;
            if (this.secondFormGroup.invalid) {
                return;
            }
            var pbsToMergeobj = [];
            this.ondemand_merge_accounts.forEach(function (o, i) {
                if (o.checked) {
                    _this.customerEmailToBeMerge.push(o);
                    pbsToMergeobj.push({ emailAddress: o.emailAddress, bpTobeMerge: o.bpNo });
                }
            });
            var objToBeMerge = { emailList: pbsToMergeobj, titleNameSurname: this.storageService.getGcdmCustTitle() + " " + this.storageService.getGcdmCustNameLast(),
                customer_email: this.storageService.getGcdmEmail(), customer_pb: this.storageService.getGcdmCustBp(),
                brand: this.get_gcdm_config['brand'], urlLink: this.get_gcdm_config['CP_hostname'] + "/psteml/" };
            this.ondemandLoginBpService.postCustomerRecordsToBeMerge(objToBeMerge).pipe(operators_1.first()).subscribe(function (data) {
                // this.activeModal.close(this.InsertIDFormFld.value);
            }, function (error) {
            });
            // this.activeModal.close(this.InsertIDFormFld.value);
        };
        this.createForm();
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    InsertIDmdlComponent.prototype.createForm = function () {
        this.InsertIDFormFld = this.formBuilder.group({
            ondemand_id_type: '',
            ondemand_id: '',
        });
    };
    InsertIDmdlComponent.prototype.obscure_email = function (email) {
        var skipFirstChars = 3;
        var firstThreeChar = email.slice(0, skipFirstChars);
        var domainIndexStart = email.lastIndexOf("@");
        var maskedEmail = email.slice(skipFirstChars, domainIndexStart);
        maskedEmail = maskedEmail.replace(/./g, '*');
        var domain = email.slice(domainIndexStart, email.length);
        return firstThreeChar.concat(maskedEmail).concat(domain);
    };
    InsertIDmdlComponent.prototype.validateEmail = function (email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    Object.defineProperty(InsertIDmdlComponent.prototype, "f", {
        get: function () {
            return this.secondFormGroup.controls;
        },
        enumerable: false,
        configurable: true
    });
    InsertIDmdlComponent.prototype.minSelectedCheckboxes = function (min) {
        if (min === void 0) { min = 1; }
        var validator = function (formArray) {
            var totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(function (control) { return control.value; })
                // total up the number of checked checkboxes
                .reduce(function (prev, next) { return (next ? prev + next : prev); }, 0);
            // if the total is not greater than the minimum, return the error message
            return totalSelected >= min ? null : { required: true };
        };
        return validator;
    };
    InsertIDmdlComponent.prototype.list_change = function (event, emailAddress, bpNo) {
        var _this = this;
        var list = { emailAddress: emailAddress, checked: event.target.checked, bpNo: bpNo, maskEmail: this.obscure_email(emailAddress) };
        this.ondemand_merge_accounts.push(list);
        this.ondemand_merge_accounts.forEach(function (o, i) {
            if (!event.target.checked && o.emailAddress === emailAddress) {
                _this.ondemand_merge_accounts.splice(i, 1);
            }
        });
    };
    // Passport and SA ID selection
    InsertIDmdlComponent.prototype.passChange = function (idValue) {
        if (idValue === "FS0002") {
            this.idVersion = "Passport";
            return this.idVersion;
        }
        else if (idValue === "SAID") {
            this.idVersion = "SA ID";
            return this.idVersion;
        }
    };
    InsertIDmdlComponent.prototype.ngOnInit = function () {
        this.firstFormGroup = this._formBuilder.group({
            idtype: ['', forms_1.Validators.required],
            firstCtrl: ['', forms_1.Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            emailList: new forms_1.FormArray([], this.minSelectedCheckboxes(1))
        });
        // this.customerEmailToBeMerge = this.ondemand_merge_accounts;
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], InsertIDmdlComponent.prototype, "my_modal_title", void 0);
    InsertIDmdlComponent = __decorate([
        core_1.Component({
            selector: 'app-insertidmdl',
            template: require('./insertid_mdl.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            forms_1.FormBuilder,
            forms_1.FormBuilder,
            storage_service_1.StorageService,
            customer_profile_service_1.CustomerProfileService,
            _services_1.AlertService,
            onDemand_login_bp_service_1.OndemandLoginBpService,
            _services_2.AuthenticationService])
    ], InsertIDmdlComponent);
    return InsertIDmdlComponent;
}());
exports.InsertIDmdlComponent = InsertIDmdlComponent;
