"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactDetailsModalComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var _services_1 = require("@/_services");
var ContactDetailsModalComponent = /** @class */ (function () {
    function ContactDetailsModalComponent(activeModal, userService, formBuilder) {
        this.activeModal = activeModal;
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.loading = false;
        this.submitted = false;
        this.findAddresses = [];
        this.loadCustomerDetails();
    }
    ContactDetailsModalComponent.prototype.loadCustomerDetails = function () {
        var _this = this;
        var userServiceObservable = this.userService.getGcdmCustomerProfile();
        userServiceObservable.subscribe(function (customerDetails) {
            customerDetails.forEach(function (o, i) {
                _this.customerDetails = o.businessPartner;
                if (_this.customerDetails !== undefined) {
                    _this.salutation = _this.customerDetails.salutation;
                    _this.givenName = _this.customerDetails.givenName;
                    _this.surname = _this.customerDetails.surname;
                    _this.birthday = _this.customerDetails.birthday;
                    _this.correspondLanguageISO = _this.customerDetails;
                }
                _this.userAccount = o.userAccount;
                if (_this.customerDetails.profile !== undefined) {
                    if (_this.customerDetails.profile.personalIdentifications !== undefined) {
                        _this.customerDetails.profile.personalIdentifications.personalIdentifications.forEach(function (o, i) {
                            _this.idNumber = o.id;
                        });
                    }
                }
                if (_this.customerDetails.communications !== undefined) {
                    if (_this.customerDetails.communications.communicationPhones !== undefined) {
                        var isPresent = _this.customerDetails.communications.communicationPhones.find(function (p) { return p.communicationType === 'MOB' && p.preferred; });
                        if (isPresent) {
                            _this.phonenumber = isPresent.value;
                        }
                        else {
                            _this.phonenumber = "";
                        }
                    }
                    if (_this.customerDetails.communications.communicationEMails !== undefined) {
                        var isPresent = _this.customerDetails.communications.communicationEMails.find(function (p) { return p.preferred; });
                        if (isPresent) {
                            _this.emailAddress = isPresent.value;
                        }
                        //  else {
                        //     if(isPresent.value !== undefined){
                        //         this.emailAddress = isPresent.value;
                        //     }
                        // }
                    }
                }
                if (_this.customerDetails.addresses !== undefined) {
                    _this.customerDetails.addresses.addresses.forEach(function (o, i) {
                        if (o.preferred) {
                            _this.city = o.city;
                            _this.country = o.country;
                            _this.districtName = o.districtName;
                            _this.postalcode = o.postalCode;
                            _this.strSuppl1 = o.strSuppl1;
                            _this.street = o.street;
                        }
                    });
                }
                if (_this.userAccount !== undefined) {
                    _this.userAccountEmail = _this.userAccount.mail;
                }
            });
            _this.createForm(_this.emailAddress, _this.phonenumber, _this.city, _this.districtName, _this.postalcode, _this.strSuppl1, _this.street);
        });
    };
    ContactDetailsModalComponent.prototype.createForm = function (emailAddress, phonenumber, city, districtName, postalcode, strSuppl1, street) {
        // this.mobileRegEx = '/^\+27\s?[6-8][0-9]\s?[0-9]{3}\s?[0-9]{4}$/';
        // this.postcodeNumber = '/^[0-9]{4}$/';
        var _this = this;
        this.loadAPI = new Promise(function () {
            _this.loadStyles();
            _this.loadScripts();
        });
        this.ContactDetailsEditForm = this.formBuilder.group({
            email: [emailAddress, [forms_1.Validators.required]],
            mobilenumber: [phonenumber, [forms_1.Validators.required, forms_1.Validators.pattern(/^\+27\s?[6-8][0-9]\s?[0-9]{3}\s?[0-9]{4}$/)]],
            street: [street, [forms_1.Validators.required]],
            strSuppl1: strSuppl1,
            districtName: [districtName, [forms_1.Validators.required]],
            city: [city, [forms_1.Validators.required]],
            postalcode: [postalcode, [forms_1.Validators.required, forms_1.Validators.pattern(/^[0-9]{4}$/)]],
            CountryIso2: "ZA"
        });
    };
    ContactDetailsModalComponent.prototype.submitForm = function () {
        this.submitted = true;
        if (document.getElementById('street').value !== '') {
            this.ContactDetailsEditForm.controls.street.setValue(document.getElementById('street').value);
            this.ContactDetailsEditForm.controls.street.setValidators([]);
        }
        if (document.getElementById('districtName').value !== '') {
            this.ContactDetailsEditForm.controls.districtName.setValue(document.getElementById('districtName').value);
        }
        if (document.getElementById('city').value !== '') {
            this.ContactDetailsEditForm.controls.city.setValue(document.getElementById('city').value);
        }
        if (document.getElementById('postalcode').value !== '') {
            this.ContactDetailsEditForm.controls.postalcode.setValue(document.getElementById('postalcode').value);
        }
        if (this.ContactDetailsEditForm.invalid) {
            this.loading = false;
            return;
        }
        this.submitData = { email: document.getElementById('email').value,
            mobilenumber: document.getElementById('mobilenumber').value,
            street: document.getElementById('street').value,
            strSuppl1: document.getElementById('strSuppl1').value,
            districtName: document.getElementById('districtName').value,
            city: document.getElementById('city').value,
            postalcode: document.getElementById('postalcode').value,
            CountryIso2: document.getElementById('CountryIso2').value };
        this.activeModal.close(this.submitData);
    };
    Object.defineProperty(ContactDetailsModalComponent.prototype, "f", {
        get: function () {
            return this.ContactDetailsEditForm.controls;
        },
        enumerable: false,
        configurable: true
    });
    ContactDetailsModalComponent.prototype.ngOnInit = function () {
    };
    ContactDetailsModalComponent.prototype.loadStyles = function () {
        var dynamicStyles = [
            //styles to be loaded
            "https://services.postcodeanywhere.co.uk/css/address-3.91.css"
        ];
        for (var i = 0; i < dynamicStyles.length; i++) {
            var node = document.createElement('link');
            node.href = dynamicStyles[i];
            node.rel = 'stylesheet';
            document.getElementById('styles').appendChild(node);
        }
    };
    ContactDetailsModalComponent.prototype.loadScripts = function () {
        var dynamicScripts = [
            //scripts to be loaded
            "https://code.jquery.com/jquery-3.3.1.js",
            "src/_content/js/utils.js",
            "src/_content/js/intlTelInput.js",
            "src/_content/js/libphone.js",
            "https://services.postcodeanywhere.co.uk/js/address-3.91.js",
            "src/_content/js/Loqate.js"
        ];
        for (var i = 0; i < dynamicScripts.length; i++) {
            var node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementById('scripts').appendChild(node);
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], ContactDetailsModalComponent.prototype, "my_modal_title", void 0);
    ContactDetailsModalComponent = __decorate([
        core_1.Component({
            selector: 'app-contactdetailsmodal',
            template: require('./contactdetailsmodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            _services_1.UserService,
            forms_1.FormBuilder])
    ], ContactDetailsModalComponent);
    return ContactDetailsModalComponent;
}());
exports.ContactDetailsModalComponent = ContactDetailsModalComponent;
