"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProspectHomeComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var onDemand_login_bp_service_1 = require("@/_services/onDemand-login-bp.service");
var storage_service_1 = require("@/_services/storage-service");
// import { CommsMethodBMWModalComponent } from './commsmethodbmwmodal/commsmethodbmwmodal.component';
// import { CommsMethodFSModalComponent } from './commsmethodfsmodal/commsmethodfsmodal.component';
var policy_store_consent_service_1 = require("@/_services/policy-store-consent.service");
var forms_1 = require("@angular/forms");
var ProspectHomeComponent = /** @class */ (function () {
    function ProspectHomeComponent(authenticationService, userService, modalService, formBuilder, ondemandLoginBpService, policyStoreConsentService, alertService, route, router, storageService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.ondemandLoginBpService = ondemandLoginBpService;
        this.policyStoreConsentService = policyStoreConsentService;
        this.alertService = alertService;
        this.route = route;
        this.router = router;
        this.storageService = storageService;
        this.display = 'none';
        this.loading = false;
        this.show = true;
        this.isDataLoaded = false;
        this.isNavShown = false;
        this.contactPolicyConsentsDataNsc = [];
        this.contactPolicyConsentsDataSf = [];
        this.contactPolicyConsentsDataDisplayNsc = [];
        this.contactPolicyConsentsDataDisplaySf = [];
        this.contactPolicyConsent = [];
        this.contactConsentNsc = [];
        this.nscData = [];
        this.fsData = [];
        this.fsSaveData = [];
        this.nscSaveData = [];
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
        this.form = this.formBuilder.group({
            contactPolicyConsentsNsc: new forms_1.FormArray([]),
            contactPolicyConsentsSf: new forms_1.FormArray([])
        });
        this.salutation = this.storageService.getGcdmCustTitle();
        this.surname = this.storageService.getGcdmCustNameLast();
        this.givenName = this.storageService.getGcdmCustNameFirst();
    }
    ProspectHomeComponent.prototype.ngOnInit = function () {
    };
    ProspectHomeComponent.prototype.privacySettings = function () {
        this.router.navigate(['/profile/privacysettings']);
    };
    ProspectHomeComponent = __decorate([
        core_1.Component({ template: require('./prospectHome.component.html') }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.UserService,
            ng_bootstrap_1.NgbModal,
            forms_1.FormBuilder,
            onDemand_login_bp_service_1.OndemandLoginBpService,
            policy_store_consent_service_1.PolicyStoreConsentService,
            _services_1.AlertService,
            router_2.ActivatedRoute,
            router_1.Router,
            storage_service_1.StorageService])
    ], ProspectHomeComponent);
    return ProspectHomeComponent;
}());
exports.ProspectHomeComponent = ProspectHomeComponent;
