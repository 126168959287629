"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeComponent = void 0;
var core_1 = require("@angular/core");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var fileSaver = require("file-saver");
var vehicle_cosy_image_1 = require("@/_models/vehicle-cosy-image");
var vehicle_motorplan_service_1 = require("@/_services/vehicle-motorplan.service");
var vehicle_cosy_image_service_1 = require("@/_services/vehicle-cosy.image.service");
var vehicle_service_option_service_1 = require("@/_services/vehicle-service-option.service");
var ServiceOptDescriptions = require("@/_content/serviceOptionsData/ServiceOptionsData.json");
var storage_service_1 = require("@/_services/storage-service");
var service_dealer_service_1 = require("@/_services/service-dealer.service");
var _services_1 = require("@/_services/");
var contactadealer_component_1 = require("@/_components/contacadealermodal/contactadealer.component");
var vehicle_campaign_service_1 = require("@/_services/vehicle-campaign.service");
var dealer_list_service_1 = require("@/_services/dealer-list.service");
var platform_browser_1 = require("@angular/platform-browser");
var moment = require("moment");
var HomeComponent = /** @class */ (function () {
    function HomeComponent(vehicleMotorplanService, vehicleCosyImageService, sanitizer, vehicleServiceOptionService, storageService, dealerListService, serviceDealerService, authenticationService, modalService, vehicleCampaignService) {
        this.vehicleMotorplanService = vehicleMotorplanService;
        this.vehicleCosyImageService = vehicleCosyImageService;
        this.sanitizer = sanitizer;
        this.vehicleServiceOptionService = vehicleServiceOptionService;
        this.storageService = storageService;
        this.dealerListService = dealerListService;
        this.serviceDealerService = serviceDealerService;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.vehicleCampaignService = vehicleCampaignService;
        this.vehicleCosyImages = [];
        this.loading = false;
        this.selectedItems = [];
        this.vehicleDefects = [];
        this.prospectCustomer = false;
        this.vehicles = [];
        this.vehiclesforSOL = [];
        this.solVehicles = [];
        this.serviceOptDescriptions = ServiceOptDescriptions;
        this.showTechCampaign = false;
        this.isHideServiceOption = false;
        this.isVehilceMotorPlan = true;
        this.isServiceDealerLoaded = true;
        this.isDataLoaded = false;
        this.parentIsEmptyServiceOption = false;
        this.hideOndemand = false;
        this.isExpireMotorplan = false;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.loginBrand = this.get_gcdm_config['brand'];
        this.loadServiceDealerCode();
        this.loadVehicleVehicleInfo();
        this.salutation = this.storageService.getGcdmCustTitle();
        this.surname = this.storageService.getGcdmCustNameLast();
        this.givenName = this.storageService.getGcdmCustNameFirst();
    }
    HomeComponent.prototype.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid.toUpperCase();
    };
    HomeComponent.prototype.random = function (len) {
        var result = Math.floor(Math.random() * Math.pow(10, len));
        return (result.toString().length < len) ? this.random(len) : result;
    };
    HomeComponent.prototype.loginDataFunction = function (vehiclesforSOL, dateValue) {
        var o = vehiclesforSOL;
        var agModel = vehiclesforSOL.vehicleDetails.modelCode.substring(2);
        var images = "";
        var errorImages = "";
        o.cosyImageUrlList.forEach(function (l) {
            if (l.indexOf("http") !== -1 || l.indexOf("https") !== -1) {
                images = l;
            }
            else {
                if (o.vehicleDetails.brand === 'MI') {
                    errorImages = l;
                }
                if (o.vehicleDetails.brand === 'BM') {
                    errorImages = l;
                }
            }
        });
        var listOfVehicle = {
            "VehicleId": this.random(6),
            "RegistrationNumber": o.vehicleDetails.vehLpno !== '' ? o.vehicleDetails.vehLpno : o.vehicleDetails.zsfVehicle.zsfRegistrNo,
            "ChassisNo": o.vehicleDetails.vinNumber,
            "CosyImageURL": images,
            "MakeName": this.get_gcdm_config['brand'] === o.vehicleDetails.brand ? "BMW" : this.get_gcdm_config['brand'] === o.vehicleDetails.brand ? "MINI" : "",
            "MakeCode": this.get_gcdm_config['brand'] === o.vehicleDetails.brand ? "B" : this.get_gcdm_config['brand'] === o.vehicleDetails.brand ? "M" : "",
            "ModelName": o.vehicleDetails.modelDesc,
            "ModelCode": o.modelCode,
            "VariantName": o.vehicleDetails.modelDesc,
            "VariantCode": o.modelCode,
            "ModelTextDescription": o.vehicleDetails.modelDesc,
            "Odometer": null,
            "MOTExpiryDate": null,
            "IsCompanyVehicle": false,
            "LastRegisteredDate": null,
            "TransmissionCode": null,
            "TransmissionDesc": null,
            "FuelCode": null,
            "FuelDesc": null,
            "EngineSize": null,
            "Colour": null,
            "Trim": null,
            "FreeDesc": null,
            "AccidentDamage": {
                "Code": null,
                "Description": null
            },
            "ServiceModelGroup": null
        };
        if (o.vehicleDetails.brand !== "MO" || o.vehicleDetails.brand !== "MI") {
            this.solVehicles.push(listOfVehicle);
        }
        this.loginData = {
            // "brands":[
            //    "MINI",
            //    "BMW"
            // ],
            // "loginResult":"S",
            // "sessionId":this.generateUUID(),
            "vehicles": this.solVehicles
        };
        sessionStorage.setItem('loginData', JSON.stringify(this.loginData));
    };
    HomeComponent.prototype.loadVehiclesInputData = function (vehicleDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.loading = true;
                if (vehicleDetails.vehicles.findvehicle !== null) {
                    vehicleDetails.vehicles.findvehicle.forEach(function (o, i) {
                        var vehicleCosyImageObservable = _this.vehicleCosyImageService.getVehicleCosyImage(o.vinNumber);
                        vehicleCosyImageObservable.subscribe(function (vehicleCosyImage) {
                            _this.vehicleCosyImage = vehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X" && o.brand === _this.loginBrand) {
                                _this.vehicleCosyImage.cosyImageUrlList.forEach(function (l, i) {
                                    if (l.indexOf("http") !== -1 || l.indexOf("https") !== -1) {
                                        _this.vehicleCosyImage.vehicleDetails = o;
                                        // if(l.indexOf("bmwgroup.net") !== -1){
                                        //     l = l.replace('bmwgroup.net', 'bmwgroup.com');
                                        //     // l = l.replace('http://', 'https://');
                                        // }
                                        _this.vehicleCosyImage.cosyImageUrlList = [l];
                                        _this.loginDataFunction(_this.vehicleCosyImage, _this.dateValue);
                                    }
                                    else {
                                        if (o.brand === 'MI') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                        }
                                        if (o.brand === 'BM') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                        }
                                        if (o.brand === 'MO') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                        }
                                        //                     this.loginDataFunction(this.vehicleCosyImage, this.dateValue);
                                    }
                                });
                            }
                        }, function (error) {
                            _this.vehicleCosyImage = new vehicle_cosy_image_1.VehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X" && o.brand === _this.loginBrand) {
                                if (o.brand === 'MI') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                }
                                if (o.brand === 'BM') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                }
                                if (o.brand === 'MO') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                }
                                //                     this.loginDataFunction(this.vehicleCosyImage, this.dateValue);
                            }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.loadServiceDealerCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var serviceDealerServiceObservable;
            var _this = this;
            return __generator(this, function (_a) {
                serviceDealerServiceObservable = this.serviceDealerService.getServiceCodeDealer();
                serviceDealerServiceObservable.subscribe(function (serviceDealerCode) {
                    _this.serviceDealerCode = serviceDealerCode;
                    _this.loading = true;
                    // this.isDataLoaded = true;
                    if (_this.serviceDealerCode.evResult === "S") {
                        _this.isServiceDealerLoaded = true;
                        _this.storageService.setDealerId(_this.serviceDealerCode.evDealerRelation.dealerId);
                        _this.loadServiceDealer(_this.serviceDealerCode.evDealerRelation.dealerId);
                        // this.loadVehicleVehicleInfo();
                    }
                    if (_this.serviceDealerCode.evResult === "E") {
                        _this.isServiceDealerLoaded = false;
                        _this.storageService.setDealerId("");
                        // this.loadVehicleVehicleInfo();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.updateDealerCode = function (dealerCode) {
        this.storageService.setDealerId(dealerCode);
        this.loadServiceDealerCode();
        this.ngOnInit();
    };
    HomeComponent.prototype.loadVehicleVehicleInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isPresent;
            var _this = this;
            return __generator(this, function (_a) {
                this.vehicleDetails = this.storageService.getCurrentVehicles();
                this.loading = true;
                if (this.vehicleDetails.vehicles.findvehicle !== null) {
                    this.vehicleDetails.vehicles.findvehicle.sort(function (a, b) { return a.modelYear > b.modelYear; });
                    isPresent = this.vehicleDetails.vehicles.findvehicle.find(function (p) { return p.brand === _this.loginBrand && p.currentVehicle === "X"; });
                    if (isPresent) {
                        if (this.storageService.getVin() === null) {
                            this.storageService.setVin(isPresent.vinNumber);
                            this.storageService.setSelectedVehicle(isPresent);
                            this.vehicleModelDesc = isPresent.zsfVehicle.zsfAssetDescr;
                            this.loadVehicleCampaign(isPresent.vinNumber);
                            if (isPresent.brand === 'MI') {
                                this.vehicleModel = "MINI " + isPresent.modelDesc;
                            }
                            if (isPresent.brand === 'BM') {
                                this.vehicleModel = "BMW " + isPresent.modelDesc;
                            }
                            if (isPresent.brand === 'MO') {
                                this.vehicleModel = "Motorrad " + isPresent.modelDesc;
                            }
                        }
                    }
                    else {
                        this.vehicleDetails.vehicles.findvehicle.filter(function (o) {
                            if (_this.storageService.getVin() === null) {
                                if (o.currentVehicle === "X") {
                                    _this.storageService.setVin(o.vinNumber);
                                    _this.storageService.setSelectedVehicle(o);
                                    _this.vehicleModelDesc = o.zsfVehicle.zsfAssetDescr;
                                    _this.loadVehicleCampaign(o.vinNumber);
                                    if (o.brand === 'MI') {
                                        _this.vehicleModel = "MINI " + o.modelDesc;
                                    }
                                    if (o.brand === 'BM') {
                                        _this.vehicleModel = "BMW " + o.modelDesc;
                                    }
                                    if (o.brand === 'MO') {
                                        _this.vehicleModel = "Motorrad " + o.modelDesc;
                                    }
                                }
                            }
                        });
                    }
                    this.loadVehicleCosyImage(this.vehicleDetails);
                    this.loadVehiclesInputData(this.vehicleDetails);
                }
                else {
                    this.prospectCustomer = true;
                }
                return [2 /*return*/];
            });
        });
    };
    ;
    HomeComponent.prototype.loadVehicleCosyImage = function (vehicleDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (vehicleDetails.vehicles.findvehicle !== null) {
                    vehicleDetails.vehicles.findvehicle.forEach(function (o, i) {
                        var vehicleCosyImageObservable = _this.vehicleCosyImageService.getVehicleCosyImage(_this.storageService.getVin());
                        vehicleCosyImageObservable.subscribe(function (vehicleCosyImage) {
                            _this.vehicleCosyImage = vehicleCosyImage;
                            _this.loading = true;
                            if (o.vinNumber === _this.storageService.getVin()) {
                                _this.vehicleCosyImage.vehicleDetails = o;
                                if (o.vehLpno === '' || o.vehLpno === undefined) {
                                    o.vehLpno = o.zsfVehicle.zsfRegistrNo;
                                }
                                _this.parentVehicleDetail = { vehicle: o, isHome: true };
                                _this.parentIsEmptyServiceOption = true;
                                _this.loadVehicleMotorplan(o.vinNumber, _this.vehicleCosyImage.vehicleDetails);
                                _this.vehicleModelDesc = o.zsfVehicle.zsfAssetDescr;
                                _this.loadVehicleCampaign(o.vinNumber);
                                if (o.brand === 'MI') {
                                    _this.vehicleModel = "MINI " + o.modelDesc;
                                }
                                if (o.brand === 'BM') {
                                    _this.vehicleModel = "BMW " + o.modelDesc;
                                }
                                if (o.brand === 'MO') {
                                    _this.vehicleModel = "Motorrad " + o.modelDesc;
                                }
                                _this.loading = true;
                                _this.vehicleCosyImage.cosyImageUrlList.forEach(function (l, i) {
                                    if (o.currentVehicle === 'X') {
                                        if (l.indexOf("http") !== -1 || l.indexOf("https") !== -1) {
                                            // if(l.indexOf("bmwgroup.net") !== -1){
                                            //     l = l.replace('bmwgroup.net', 'bmwgroup.com');
                                            //     // l = l.replace('http://', 'https://');
                                            // }
                                            _this.vehicleCosyImage.cosyImageUrlList = [l];
                                        }
                                        else {
                                            if (o.brand === 'MI') {
                                                _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                            }
                                            if (o.brand === 'BM') {
                                                _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                            }
                                            if (o.brand === 'MO') {
                                                _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                            }
                                        }
                                    }
                                });
                                _this.firstVehicleOjb = _this.vehicleCosyImage;
                            }
                        }, function (error) {
                            _this.vehicleCosyImage = new vehicle_cosy_image_1.VehicleCosyImage;
                            if (o.vinNumber === _this.storageService.getVin()) {
                                _this.parentIsEmptyServiceOption = true;
                                _this.vehicleCosyImage.vehicleDetails = o;
                                if (o.vehLpno === '' || o.vehLpno === undefined) {
                                    o.vehLpno = o.zsfVehicle.zsfRegistrNo;
                                }
                                _this.parentVehicleDetail = { vehicle: o, isHome: true };
                                _this.loadVehicleMotorplan(o.vinNumber, _this.vehicleCosyImage.vehicleDetails);
                                // this.loadServiceOptions(o.bodyNumber, dealerCode)
                                _this.vehicleModelDesc = o.zsfVehicle.zsfAssetDescr;
                                _this.loadVehicleCampaign(o.vinNumber);
                                if (o.brand === 'MI') {
                                    _this.vehicleModel = "MINI " + o.modelDesc;
                                }
                                if (o.brand === 'BM') {
                                    _this.vehicleModel = "BMW " + o.modelDesc;
                                }
                                if (o.brand === 'MO') {
                                    _this.vehicleModel = "Motorrad " + o.modelDesc;
                                }
                                _this.loading = true;
                                if (o.currentVehicle === 'X') {
                                    if (o.brand === 'MI') {
                                        _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                    }
                                    if (o.brand === 'BM') {
                                        _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                    }
                                    if (o.brand === 'MO') {
                                        _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                    }
                                }
                                _this.firstVehicleOjb = _this.vehicleCosyImage;
                            }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.loadVehicleMotorplan = function (vinNumber, vehicleInfo) {
        return __awaiter(this, void 0, void 0, function () {
            var vehicleMotorplanObservable;
            var _this = this;
            return __generator(this, function (_a) {
                this.vinNumber = vinNumber;
                vehicleMotorplanObservable = this.vehicleMotorplanService.getVehicleMotorplanDetails(vinNumber);
                vehicleMotorplanObservable.subscribe(function (vehicleMotorplan) {
                    if (vehicleMotorplan !== null) {
                        var isPresent_1 = vehicleMotorplan.find(function (p) { return p.txt04 === "CS04"; });
                        var isMotorPlanOptionalPresent_1 = vehicleMotorplan.find(function (p) { return p.txt04 === "CS04" && p.matnr === "TC003"; });
                        if (isMotorPlanOptionalPresent_1) {
                            isPresent_1 = null;
                            _this.isVehilceMotorPlan = true;
                            var startYear = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartYear = startYear.slice(0, 4);
                            var startMonth = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartMonth = startMonth.slice(4, 6);
                            var startDay = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartDay = startDay.slice(6, 8);
                            var year = isMotorPlanOptionalPresent_1.zendDate;
                            var getYear = year.slice(0, 4);
                            var month = isMotorPlanOptionalPresent_1.zendDate;
                            var getMonth = month.slice(4, 6);
                            var day = isMotorPlanOptionalPresent_1.zendDate;
                            var getDay = day.slice(6, 8);
                            // this.loading = true;
                            var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                            vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                _this.parentMotorPlanOpationals = motorPlanOpationals;
                                var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                if (isOptionalPresent) {
                                    _this.isExpireMotorplan = false;
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isMotorPlanOptionalPresent_1.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent, cosyImage: _this.vehicleCosyImage.cosyImageUrlList }, vehicleInfo: vehicleInfo };
                                }
                                else {
                                    _this.isExpireMotorplan = true;
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isMotorPlanOptionalPresent_1.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                }
                            });
                        }
                        else if (isPresent_1) {
                            _this.isVehilceMotorPlan = true;
                            var startYear = isPresent_1.zstartDate;
                            var getStartYear = startYear.slice(0, 4);
                            var startMonth = isPresent_1.zstartDate;
                            var getStartMonth = startMonth.slice(4, 6);
                            var startDay = isPresent_1.zstartDate;
                            var getStartDay = startDay.slice(6, 8);
                            var year = isPresent_1.zendDate;
                            var getYear = year.slice(0, 4);
                            var month = isPresent_1.zendDate;
                            var getMonth = month.slice(4, 6);
                            var day = isPresent_1.zendDate;
                            var getDay = day.slice(6, 8);
                            _this.loading = true;
                            var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                            vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                _this.parentMotorPlanOpationals = motorPlanOpationals;
                                var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                if (isOptionalPresent) {
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isPresent_1.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent, cosyImage: _this.vehicleCosyImage.cosyImageUrlList }, vehicleInfo: vehicleInfo };
                                }
                                else {
                                    _this.isExpireMotorplan = true;
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isPresent_1.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                }
                            });
                        }
                        else {
                            vehicleMotorplan.forEach(function (o, i) {
                                var startYear = o.zstartDate;
                                var getStartYear = startYear.slice(0, 4);
                                var startMonth = o.zstartDate;
                                var getStartMonth = startMonth.slice(4, 6);
                                var startDay = o.zstartDate;
                                var getStartDay = startDay.slice(6, 8);
                                var year = o.zendDate;
                                var getYear = year.slice(0, 4);
                                var month = o.zendDate;
                                var getMonth = month.slice(4, 6);
                                var day = o.zendDate;
                                var getDay = day.slice(6, 8);
                                _this.loading = true;
                                _this.isExpireMotorplan = _this.compareDate(o.zendDate) === true ? true : false;
                                if (!_this.isExpireMotorplan) {
                                    var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                                    vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                        _this.parentMotorPlanOpationals = motorPlanOpationals;
                                        var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                        if (isOptionalPresent) {
                                            _this.isVehilceMotorPlan = true;
                                            _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(o.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent }, vehicleInfo: vehicleInfo };
                                        }
                                        else {
                                            _this.isExpireMotorplan = true;
                                            // this.isExpireMotorplan = this.compareDate(o.zendDate) === true? true: false;
                                            _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(o.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                        }
                                    });
                                }
                                else {
                                    _this.isVehilceMotorPlan = false;
                                }
                            });
                        }
                    }
                    else {
                        _this.isVehilceMotorPlan = false;
                    }
                }, function (error) {
                    _this.isVehilceMotorPlan = false;
                    console.error(error);
                });
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.compareDate = function (endDate) {
        var year = endDate.slice(0, 4);
        var month = endDate.slice(4, 6);
        var date = endDate.slice(6, 8);
        var RenewalEndDate = moment([year, Number(month) - 1, date]).add(1, 'months');
        var contractRenewalEndDate = moment(RenewalEndDate).get('year') + "-" + (RenewalEndDate.get('month') + 1) + "-" + RenewalEndDate.get("date");
        var currentDate = moment(new Date()).format("YYYY-MM-DD");
        if (currentDate >= contractRenewalEndDate) {
            return true;
        }
        else {
            return false;
        }
    };
    HomeComponent.prototype.loadVehicleCampaign = function (vinNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var vehicleCampaignServiceObservable;
            var _this = this;
            return __generator(this, function (_a) {
                this.vinNumber = vinNumber;
                vehicleCampaignServiceObservable = this.vehicleCampaignService.getVehicleCampaign(vinNumber);
                vehicleCampaignServiceObservable.subscribe(function (vehicleCampaign) {
                    _this.vehicleCampaign = vehicleCampaign;
                    if (_this.vehicleCampaign.recallDetail !== null) {
                        _this.showTechCampaign = true;
                        _this.vehicleCampaign.recallDetail.forEach(function (o, i) {
                            _this.campaignDefect = o.campaign;
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.loadServiceOptions = function (bodyNo, dealerCode, modelYear) {
        return __awaiter(this, void 0, void 0, function () {
            var frontBrake, rearBrake, serviceReset, ServiceOptionsObservable;
            var _this = this;
            return __generator(this, function (_a) {
                frontBrake = 0;
                rearBrake = 0;
                serviceReset = 0;
                ServiceOptionsObservable = this.vehicleServiceOptionService.getVehicleServiceOption(bodyNo, dealerCode, modelYear);
                ServiceOptionsObservable.subscribe(function (serviceOptions) {
                    _this.serviceOptions = serviceOptions;
                    _this.loading = true;
                    if (_this.serviceOptions.defect !== undefined) {
                        _this.isHideServiceOption = false;
                        _this.parentIsEmptyServiceOption = false;
                        _this.serviceOptions.defect.forEach(function (o, i) {
                            if (o.defect === "99110475MP") {
                                frontBrake = o.inclVat;
                                _this.selectedItems.push(o);
                            }
                            if (o.defect === "99210429MP") {
                                rearBrake = o.inclVat;
                                _this.selectedItems.push(o);
                            }
                            if (o.defect === "99600999MP") {
                                serviceReset = o.inclVat;
                                _this.selectedItems.push(o);
                            }
                            var inclPrice;
                            var seqnr;
                            _this.serviceOptDescriptions.serviceDescriptions.forEach(function (p, j) {
                                if (o.defect === p.id) {
                                    inclPrice = o.inclVat;
                                    seqnr = o.seqnr;
                                    if (o.defect !== "99600999MP") {
                                        _this.vehicleDefects.push({ defects: p, inclPrice: inclPrice, seqnr: seqnr });
                                    }
                                }
                            });
                        });
                    }
                    else {
                        _this.isHideServiceOption = true;
                    }
                    _this.vehicleDefects.sort(function (a, b) {
                        return a.displayOrder > b.displayOrder;
                    });
                    _this.parentServiceOptions = { defects: _this.vehicleDefects, brakesFrontRear: Number(+frontBrake + +rearBrake + +serviceReset).toFixed(2), terms: _this.serviceOptDescriptions.terms, isServiceOption: true };
                });
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.loadServiceDealer = function (preferredDealer) {
        return __awaiter(this, void 0, void 0, function () {
            var serviceDealerObservable;
            var _this = this;
            return __generator(this, function (_a) {
                serviceDealerObservable = this.dealerListService.getDealerAgent(this.get_gcdm_config['dealer_brand'], preferredDealer);
                serviceDealerObservable.subscribe(function (serviceDealer) {
                    _this.serviceDealer = serviceDealer;
                    _this.loading = true;
                    var dealerName = "";
                    var dealerTel = "";
                    var dealerWebsite = "";
                    var dealerEmail = "";
                    var dealerFax = "";
                    if (_this.serviceDealer !== null) {
                        dealerName = serviceDealer.name === null ? dealerName : serviceDealer.name;
                        dealerTel = serviceDealer.tellNumber === null ? dealerTel : serviceDealer.contactDetails.tellNumber;
                        dealerFax = serviceDealer.cellNumber === null ? dealerFax : serviceDealer.contactDetails.cellNumber;
                        dealerWebsite = serviceDealer.webPage === null ? dealerWebsite : serviceDealer.webPage;
                        dealerEmail = serviceDealer.emailAddress === null ? dealerEmail : serviceDealer.contactDetails.emailAddress;
                        _this.parentServiceDealer = { dealerName: dealerName, dealerFax: dealerFax, dealerTel: dealerTel, dealerWebsite: dealerWebsite, dealerEmail: dealerEmail, dealerInformation: _this.serviceDealer };
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HomeComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    HomeComponent.prototype.ngOnInit = function () {
        if (this.storageService.getOndemandDone() === 'dont-show') {
            this.hideOndemand = true;
        }
        else {
            this.hideOndemand = false;
        }
    };
    HomeComponent.prototype.openTechnicalCampaignModal = function () {
        var modalRef = this.modalService.open(contactadealer_component_1.ContactADealerComponent);
        modalRef.componentInstance.my_modal_title = 'TECHNICAL CAMPAIGN';
        this.formNotes = "Please contact the customer about the outstanding campaigns, campaign ID " + this.campaignDefect;
        this.dealer = this.storageService.getDealerId();
        this.bodyNo = this.storageService.getVin();
        var apiKey = this.get_gcdm_config['tech_campaign_api_key'];
        var url = 'assets/page/contactForm.html';
        modalRef.componentInstance.urlSafe = this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url + "?wccomment=" + "VIN number: " + this.bodyNo + " " + this.formNotes + "&wcprefdealer=" + this.dealer + "&apiKey=" + apiKey);
        modalRef.result.then(function (result) {
        }).catch(function (error) {
        });
    };
    HomeComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    HomeComponent = __decorate([
        core_1.Component({ selector: 'cp-home', template: require('./home.component.html') }),
        __metadata("design:paramtypes", [vehicle_motorplan_service_1.VehicleMotorplanService,
            vehicle_cosy_image_service_1.VehicleCosyImageService,
            platform_browser_1.DomSanitizer,
            vehicle_service_option_service_1.VehicleServiceOptionService,
            storage_service_1.StorageService,
            dealer_list_service_1.DealerListService,
            service_dealer_service_1.ServiceDealerService,
            _services_1.AuthenticationService,
            ng_bootstrap_1.NgbModal,
            vehicle_campaign_service_1.VehicleCampaignService])
    ], HomeComponent);
    return HomeComponent;
}());
exports.HomeComponent = HomeComponent;
