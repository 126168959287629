"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndofTermComponent = void 0;
var core_1 = require("@angular/core");
var $ = require("jquery");
var _services_1 = require("@/_services");
var vehicle_account_service_1 = require("@/_services/vehicle-account.service");
var EndofTermComponent = /** @class */ (function () {
    function EndofTermComponent(authenticationService, userService, vehicleAccountService) {
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.vehicleAccountService = vehicleAccountService;
        this.display = 'none';
        this.currentUser = this.authenticationService.currentUserValue;
        this.loadVehicleAccount();
    }
    EndofTermComponent.prototype.loadVehicleAccount = function () {
        this.loading = true;
        // let vehicleAccountObservable = this.vehicleAccountService.getVehicleAccount("JORDAAN00098205");
        // vehicleAccountObservable.subscribe((vehicleAccount: VehicleAccount) => {this.vehicleAccount = vehicleAccount; console.log("this.vehicleAccount :: ",vehicleAccount);});
        // this.setVehicleAccount(vehicleAccountObservable);
    };
    EndofTermComponent.prototype.setVehicleAccount = function (vehicleAccount) {
        this.vehicleAccount = vehicleAccount;
        this.loading = false;
    };
    EndofTermComponent.prototype.ngOnInit = function () {
        $('#keepBMW').on('click', function () {
            $('#hm').addClass('hide');
            $('#keep').removeClass('hide');
            window.scrollTo(0, 0);
        });
        $('#rtnBMW').on('click', function () {
            $('#hm').addClass('hide');
            $('#Return').removeClass('hide');
            window.scrollTo(0, 0);
        });
        $('#EOT_bk').on('click', function () {
            $('#keep').addClass('hide');
            $('#hm').removeClass('hide');
        });
        $('#EOT_bk1').on('click', function () {
            $('#Return').addClass('hide');
            $('#hm').removeClass('hide');
        });
        $('#rf1').on('click', function () {
            $('#st1').removeClass('active');
            $('#rf1').addClass('active');
            $('#settle').addClass('hide');
            $('#refinance').removeClass('hide');
            window.scrollTo(0, 0);
        });
        $('#st1').on('click', function () {
            $('#rf1').removeClass('active');
            $('#st1').addClass('active');
            $('#refinance').addClass('hide');
            $('#settle').removeClass('hide');
        });
        $('#s1').on('click', function () {
            $('#s1').addClass('active');
            $('#s2').removeClass('active');
            $('#s3').removeClass('active');
            $('#step1').removeClass('hide');
            $('#step2').addClass('hide');
            $('#step3').addClass('hide');
        });
        $('#s2').on('click', function () {
            $('#s2').addClass('active');
            $('#s1').removeClass('active');
            $('#s3').removeClass('active');
            $('#step1').addClass('hide');
            $('#step2').removeClass('hide');
            $('#step3').addClass('hide');
        });
        $('#s3').on('click', function () {
            $('#s3').addClass('active');
            $('#s1').removeClass('active');
            $('#s2').removeClass('active');
            $('#step1').addClass('hide');
            $('#step2').addClass('hide');
            $('#step3').removeClass('hide');
        });
    };
    EndofTermComponent.prototype.openModal = function () {
        this.display = "block";
    };
    EndofTermComponent.prototype.closeModal = function () {
        this.display = "none";
    };
    EndofTermComponent = __decorate([
        core_1.Component({ template: require('./endofterm.component.html') }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService, _services_1.UserService, vehicle_account_service_1.VehicleAccountService])
    ], EndofTermComponent);
    return EndofTermComponent;
}());
exports.EndofTermComponent = EndofTermComponent;
