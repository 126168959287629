"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppComponent = void 0;
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var $ = require("jquery");
var _services_1 = require("./_services");
var loader_service_1 = require("./_services/loader/loader.service");
var platform_browser_1 = require("@angular/platform-browser");
var storage_service_1 = require("@/_services/storage-service");
var bn_ng_idle_1 = require("bn-ng-idle");
var authentication_1 = require("./authentication");
var AppComponent = /** @class */ (function () {
    function AppComponent(router, userService, authenticationService, loaderService, titleService, storageService, bnIdle, autheitcationComponent) {
        // this.bnIdle.startWatching(400).subscribe((isTimedOut: boolean) => {
        //   console.log('before session expired');
        //   if(isTimedOut) {
        //       this.logout();
        //       console.log('session expired');
        //   }
        // })
        var _this = this;
        this.router = router;
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.loaderService = loaderService;
        this.titleService = titleService;
        this.storageService = storageService;
        this.bnIdle = bnIdle;
        this.autheitcationComponent = autheitcationComponent;
        this.isLoading = this.loaderService.isLoading;
        this.display = "none";
        this.postActivation = false;
        this.registerpage = false;
        this.hideOndemand = false;
        this.favIcon = document.querySelector('#appIcon');
        this.loadAppPage = false;
        this.isEmailLink = false;
        this.isProcepect = true;
        this.serviceOnlineVal = false;
        var code = this.GetParamAccess('code');
        var expires_in = this.GetParamExpire('expires_in');
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                if (event.url.search("psteml") != -1) {
                    _this.loadAppPage = true;
                    _this.isEmailLink = true;
                    _this.postActivation = true;
                }
            }
        });
        this.get_gcdm_config = this.authenticationService.get_config();
        this.gcdm_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        if (code !== null || expires_in !== null || window.location.href.indexOf("localhost") != -1) {
            var authenticationObservable = this.authenticationService.gcdmLogin(code);
            authenticationObservable.subscribe(function (accessToken) {
                sessionStorage.setItem('CP_OAUTH_BEARER_TOKEN', accessToken);
                var userServiceObservable = _this.userService.getGcdmCustomerProfileOnePage(accessToken);
                userServiceObservable.subscribe(function (customerDetails) {
                    _this.autheitcationComponent.loadCPData(customerDetails);
                    customerDetails.forEach(function (o, i) {
                        if (o.businessPartner !== undefined) {
                            _this.loadAppPage = true;
                        }
                        else {
                            _this.loadAppPage = false;
                            // this.router.navigate(['/splash']);
                            window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.get_gcdm_config['brandSyle'] + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.get_gcdm_config['CP_hostname'] + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                        }
                    }, function (error) {
                        _this.loadAppPage = false;
                        // this.router.navigate(['/splash']);
                        window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.get_gcdm_config['brandSyle'] + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.get_gcdm_config['CP_hostname'] + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                    });
                });
            });
        }
        if (!this.isEmailLink) {
            if (code === null) {
                if (sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN')) {
                    this.loadAppPage = true;
                    var userServiceObservable = this.userService.getGcdmCustomerProfile();
                    userServiceObservable.subscribe(function (customerDetails) {
                        customerDetails.forEach(function (o, i) {
                            if (o.businessPartner !== undefined) {
                                _this.loadAppPage = true;
                            }
                            else {
                                _this.loadAppPage = false;
                                // this.router.navigate(['/splash']);
                                window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.get_gcdm_config['brandSyle'] + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.get_gcdm_config['CP_hostname'] + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                            }
                        }, function (error) {
                            _this.loadAppPage = false;
                            // this.router.navigate(['/splash']);
                            window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.get_gcdm_config['brandSyle'] + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.get_gcdm_config['CP_hostname'] + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                        });
                    });
                }
                else {
                    this.router.events.subscribe(function (event) {
                        if (event instanceof router_1.NavigationStart) {
                            if (event.url.search("psteml") != -1) {
                                _this.loadAppPage = true;
                                _this.isEmailLink = true;
                                _this.postActivation = true;
                                return true;
                            }
                            else {
                                _this.loadAppPage = false;
                                // this.router.navigate(['/splash']);
                                window.location.href = _this.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + _this.get_gcdm_config['brandSyle'] + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + _this.get_gcdm_config['CP_hostname'] + "%2Fgcdm%2Findex%2Fauth&state=" + _this.get_gcdm_config['state'];
                            }
                        }
                    });
                }
            }
        }
        this.get_gcdm_config = this.authenticationService.get_config();
        this.setTitle(this.get_gcdm_config['title']);
        this.favIcon.href = this.get_gcdm_config['app_favicon'];
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                if (event.url === "/serviceonline") {
                    if (_this.get_gcdm_config['brand'] === 'MO') {
                        _this.serviceOnlineVal = false;
                    }
                    else {
                        _this.serviceOnlineVal = true;
                    }
                }
                else {
                    _this.serviceOnlineVal = false;
                }
            }
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                if (event.url.search("register") != -1) {
                    _this.registerpage = true;
                }
                if (event.url.search("splash") != -1) {
                    _this.registerpage = true;
                }
                if (event.url.search("prospect") != -1) {
                    _this.isProcepect = true;
                    storageService.setProcepect(String(_this.isProcepect));
                    event.url.search("profile");
                    event.url.search("profile/preferreddealer");
                    event.url.search("/profile/privacysettings");
                    event.url.search("en/footer/footer-section/cookie-policy");
                }
                if (event.url.search("home") != -1) {
                    _this.isProcepect = false;
                    storageService.setProcepect(String(_this.isProcepect));
                    event.url.search("home");
                    event.url.search("profile");
                    event.url.search("profile/preferreddealer");
                    event.url.search("profile/privacysettings");
                    event.url.search("mygarage");
                    event.url.search("serviceonline");
                    event.url.search("mygarage/serviceinfo");
                    event.url.search("en/footer/footer-section/cookie-policy");
                }
                if (event.url.search("serviceonline") != -1) {
                    if (storageService.getProcepect() === "true") {
                        _this.isProcepect = true;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                    if (storageService.getProcepect() === "false") {
                        _this.isProcepect = false;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("mygarage");
                        event.url.search("serviceonline");
                        event.url.search("mygarage/serviceinfo");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                }
                if (event.url.search("mygarage") != -1) {
                    if (storageService.getProcepect() === "true") {
                        _this.isProcepect = true;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                    if (storageService.getProcepect() === "false") {
                        _this.isProcepect = false;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("mygarage");
                        event.url.search("serviceonline");
                        event.url.search("mygarage/serviceinfo");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                }
                if (event.url.search("profile") != -1) {
                    if (storageService.getProcepect() === "true") {
                        _this.isProcepect = true;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                    if (storageService.getProcepect() === "false") {
                        _this.isProcepect = false;
                        event.url.search("profile");
                        event.url.search("profile/preferreddealer");
                        event.url.search("profile/privacysettings");
                        event.url.search("mygarage");
                        event.url.search("serviceonline");
                        event.url.search("mygarage/serviceinfo");
                        event.url.search("en/footer/footer-section/cookie-policy");
                    }
                }
            }
        });
        this.brand = this.get_gcdm_config['brand-head'];
        this.ProfileLinks = [
            {
                lnkTxt: "Personal details",
                rtrLink: "/profile"
            },
            {
                lnkTxt: "Preferred retailer",
                rtrLink: "/profile/preferredretailer"
            },
            {
                lnkTxt: "Privacy settings",
                rtrLink: "/profile/privacysettings"
            }
        ];
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                _this.activationBoard = false;
                if (event.url.search("gcdm/index/auth") != -1) {
                    _this.activationBoard = true;
                }
            }
        });
    }
    AppComponent.prototype.GetParamAccess = function (code) {
        var results = new RegExp('[\\?&#]' + code + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return null;
        }
        return results[1] || null;
    };
    AppComponent.prototype.GetParamExpire = function (expires_in) {
        var results = new RegExp('[\\?&]' + expires_in + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return null;
        }
        return results[1] || null;
    };
    AppComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        // this.router.navigate(['/splash']);
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    AppComponent.prototype.setTitle = function (newTitle) {
        this.titleService.setTitle(newTitle);
    };
    AppComponent.prototype.loadScriptsEpaasUrl = function () {
        var dynamicScripts = [
            //scripts to be loaded
            "https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js",
        ];
        for (var i = 0; i < dynamicScripts.length; i++) {
            var node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
            return document;
        }
    };
    AppComponent.prototype.loadScriptsEpaas = function () {
        var dynamicScripts = [
            //scripts to be loaded
            this.get_gcdm_config['app_epaas'],
        ];
        for (var i = 0; i < dynamicScripts.length; i++) {
            var node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
            return document;
        }
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadAPI = new Promise(function () {
            _this.loadScriptsEpaasUrl();
            _this.loadScriptsEpaas();
        });
    };
    AppComponent.prototype.showNav = function () {
        $("#mbl_nav").css("opacity", "1");
        $("#mbl_nav").css("height", "460px");
        $("#show").toggle();
        $("#hide").toggle();
    };
    AppComponent.prototype.closeNav = function () {
        $("#mbl_nav").css("opacity", "0.85");
        $("#mbl_nav").css("height", "45px");
        $("#show").toggle();
        $("#hide").toggle();
    };
    AppComponent.prototype.onCloseHandled = function () {
        this.display = "none";
    };
    AppComponent.prototype.openModal = function () {
        this.display = "block";
    };
    AppComponent.prototype.sideNav = function () {
        this.showSideBar = false;
        console.log("SidebarGone");
    };
    AppComponent.prototype.showMSG = function () {
        $(".notification_wrap").addClass("hide");
        $(".full_Msg").removeClass("hide");
        console.log("TEST");
    };
    AppComponent.prototype.closeMSG = function () {
        $(".full_Msg").addClass("hide");
        $(".notification_wrap").removeClass("hide");
        $("#MSG_1").removeClass("unread");
    };
    AppComponent = __decorate([
        core_1.Component({
            selector: "cust-portal-app",
            template: require('./app.component.html'),
            styles: [
                "\n    .backdrop {\n      background-color: rgba(0, 0, 0, 0.6);\n      position: fixed;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100vh;\n    }\n\n    "
            ]
        }),
        __metadata("design:paramtypes", [router_1.Router,
            _services_1.UserService,
            _services_1.AuthenticationService,
            loader_service_1.LoaderService,
            platform_browser_1.Title,
            storage_service_1.StorageService,
            bn_ng_idle_1.BnNgIdleService,
            authentication_1.AuthenticationComponent])
    ], AppComponent);
    return AppComponent;
}());
exports.AppComponent = AppComponent;
