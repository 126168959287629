"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoticeMsgComponent = void 0;
var core_1 = require("@angular/core");
var fileSaver = require("file-saver");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var add_remove_vehicle_service_1 = require("@/_services/add-remove-vehicle.service");
var insertid_mdl_component_1 = require("../ondemand/insertid_mdl/insertid_mdl.component");
var addvehiclemodal_1 = require("@/vehiclecarousel/addvehiclemodal");
var NoticeMsgComponent = /** @class */ (function () {
    function NoticeMsgComponent(authenticationService, modalService, alertService, addRemoveVehicleService) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.alertService = alertService;
        this.addRemoveVehicleService = addRemoveVehicleService;
        this.loading = false;
        this.show = true;
        this.currentUser = this.authenticationService.currentUserValue;
        this.currentBp = this.authenticationService.currentBpValue;
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
    }
    NoticeMsgComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    NoticeMsgComponent.prototype.ngOnInit = function () {
    };
    NoticeMsgComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    NoticeMsgComponent.prototype.openAddVehicleModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(addvehiclemodal_1.AddVehicleModalComponent);
        modalRef.componentInstance.my_modal_title = 'ADD A VEHICLE';
        modalRef.result.then(function (result) {
            _this.addRemoveVehicleService.addVehicle(result)
                .subscribe(function (data) {
                _this.alertService.success("Request submitted. We will contact you shortly to complete your vehicle addition.", true);
            }, function (error) {
                _this.alertService.error(error);
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    NoticeMsgComponent = __decorate([
        core_1.Component({
            selector: 'notice-msg',
            template: require('./noticemsg.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            ng_bootstrap_1.NgbModal,
            _services_1.AlertService,
            add_remove_vehicle_service_1.AddRemoveVehicleService])
    ], NoticeMsgComponent);
    return NoticeMsgComponent;
}());
exports.NoticeMsgComponent = NoticeMsgComponent;
