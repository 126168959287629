"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyGarrageComponent = void 0;
var core_1 = require("@angular/core");
var _services_1 = require("@/_services");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var router_1 = require("@angular/router");
var currentmileagemodal_component_1 = require("./currentmileagemodal/currentmileagemodal.component");
var vehicle_cosy_image_service_1 = require("@/_services/vehicle-cosy.image.service");
var vehicle_cosy_image_1 = require("@/_models/vehicle-cosy-image");
var vehicle_motorplan_service_1 = require("@/_services/vehicle-motorplan.service");
var vehicle_ocall_service_1 = require("@/_services/vehicle-ocall.service");
var vehicle_tech_service_1 = require("@/_services/vehicle-tech.service");
var common_1 = require("@angular/common");
var storage_service_1 = require("@/_services/storage-service");
var moment = require("moment");
var MyGarrageComponent = /** @class */ (function () {
    function MyGarrageComponent(authenticationService, modalService, vehicleCosyImageService, vehicleMotorplanService, vehicleOnCallService, vehicleTechService, storageService, router) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.vehicleCosyImageService = vehicleCosyImageService;
        this.vehicleMotorplanService = vehicleMotorplanService;
        this.vehicleOnCallService = vehicleOnCallService;
        this.vehicleTechService = vehicleTechService;
        this.storageService = storageService;
        this.router = router;
        this.display = 'none';
        this.loading = false;
        this.vehicleCosyImages = [];
        this.vehicles = [];
        this.isNavShown = false;
        this.isTechSpecLoaded = false;
        this.isVehilceMotorPlan = true;
        this.isOcallLoaded = false;
        this.isExpireMotorplan = false;
        this.showMotorPlanOptional = false;
        this.currentUser = this.authenticationService.currentUserValue;
        this.get_gcdm_config = this.authenticationService.get_config();
        this.loginBrand = this.get_gcdm_config['brand'];
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop'
        };
        this.loadVehicleVehicleInfo();
    }
    MyGarrageComponent.prototype.loadVehicleVehicleInfo = function () {
        this.loading = true;
        this.vehicleDetails = this.storageService.getCurrentVehicles();
        this.loadVehicleCosyImage(this.vehicleDetails);
    };
    MyGarrageComponent.prototype.loadVehicleCosyImage = function (vehicleDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.loading = true;
                if (vehicleDetails.vehicles.findvehicle !== null) {
                    this.vehicles = vehicleDetails.vehicles.findvehicle;
                    this.vehicles.forEach(function (o, i) {
                        if (_this.storageService.getVin() === o.vinNumber) {
                            _this.passVehicleDetails(o);
                        }
                        var vehicleCosyImageObservable = _this.vehicleCosyImageService.getVehicleCosyImage(o.vinNumber);
                        vehicleCosyImageObservable.subscribe(function (vehicleCosyImage) {
                            _this.vehicleCosyImage = vehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X") {
                                _this.vehicleCosyImage.cosyImageUrlList.forEach(function (l, i) {
                                    if (l.indexOf("http") !== -1 || l.indexOf("https") !== -1) {
                                        _this.vehicleCosyImage.vehicleDetails = o;
                                        // if(l.indexOf("bmwgroup.net") !== -1){
                                        //     l = l.replace('bmwgroup.net', 'bmwgroup.com');
                                        // }
                                        _this.vehicleCosyImage.cosyImageUrlList = [l];
                                        if (_this.storageService.getVin() === o.vinNumber) {
                                            _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                        }
                                        else {
                                            _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                        }
                                    }
                                    else {
                                        if (o.brand === 'MI') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                            _this.get_gcdm_config;
                                        }
                                        if (o.brand === 'BM') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                        }
                                        if (o.brand === 'MO') {
                                            _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                        }
                                        if (_this.storageService.getVin() === o.vinNumber) {
                                            _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                        }
                                        else {
                                            _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                        }
                                    }
                                });
                            }
                        }, function (error) {
                            _this.vehicleCosyImage = new vehicle_cosy_image_1.VehicleCosyImage;
                            _this.vehicleCosyImage.vehicleDetails = o;
                            if (o.currentVehicle === "X") {
                                if (o.brand === 'MI') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/mini-cosySec.png')];
                                }
                                if (o.brand === 'BM') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/bmw-cosySec.png')];
                                }
                                if (o.brand === 'MO') {
                                    _this.vehicleCosyImage.cosyImageUrlList = [require('../_content/imgs/Cosy/cosyMotorrad.png')];
                                }
                                if (_this.storageService.getVin() === o.vinNumber) {
                                    _this.vehicleCosyImages.splice(0, 0, _this.vehicleCosyImage);
                                }
                                else {
                                    _this.vehicleCosyImages.push(_this.vehicleCosyImage);
                                }
                            }
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    MyGarrageComponent.prototype.passVehicleDetails = function (vehicleDetail) {
        if (vehicleDetail.vehLpno === '' || vehicleDetail.vehLpno === undefined) {
            vehicleDetail.vehLpno = vehicleDetail.zsfVehicle.zsfRegistrNo;
        }
        this.parentVehicleDetail = { vehicle: vehicleDetail, isHome: false };
        this.loadVehicleMotorplan(vehicleDetail.vinNumber, vehicleDetail);
        this.loadModelCode(vehicleDetail.vinNumber, vehicleDetail);
    };
    MyGarrageComponent.prototype.loadVehicleMotorplan = function (vinNumber, vehicleInfo) {
        return __awaiter(this, void 0, void 0, function () {
            var vehicleMotorplanObservable;
            var _this = this;
            return __generator(this, function (_a) {
                this.vinNumber = vinNumber;
                vehicleMotorplanObservable = this.vehicleMotorplanService.getVehicleMotorplanDetails(vinNumber);
                vehicleMotorplanObservable.subscribe(function (vehicleMotorplan) {
                    if (vehicleMotorplan !== null) {
                        var isPresent_1 = vehicleMotorplan.find(function (p) { return p.txt04 === "CS04"; });
                        var isMotorPlanOptionalPresent_1 = vehicleMotorplan.find(function (p) { return p.txt04 === "CS04" && p.matnr === "TC003"; });
                        if (isMotorPlanOptionalPresent_1) {
                            isPresent_1 = null;
                            _this.storageService.setActiveContract("hasActiveContract");
                            _this.isVehilceMotorPlan = true;
                            _this.showMotorPlanOptional = false;
                            var startYear = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartYear = startYear.slice(0, 4);
                            var startMonth = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartMonth = startMonth.slice(4, 6);
                            var startDay = isMotorPlanOptionalPresent_1.zstartDate;
                            var getStartDay = startDay.slice(6, 8);
                            var year = isMotorPlanOptionalPresent_1.zendDate;
                            var getYear = year.slice(0, 4);
                            var month = isMotorPlanOptionalPresent_1.zendDate;
                            var getMonth = month.slice(4, 6);
                            var day = isMotorPlanOptionalPresent_1.zendDate;
                            var getDay = day.slice(6, 8);
                            // this.loading = true;
                            var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                            vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                _this.parentMotorPlanOpationals = motorPlanOpationals;
                                var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                if (isOptionalPresent) {
                                    _this.isExpireMotorplan = false;
                                    _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isMotorPlanOptionalPresent_1.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent, cosyImage: _this.vehicleCosyImage.cosyImageUrlList }, vehicleInfo: vehicleInfo };
                                }
                                else {
                                    _this.isExpireMotorplan = true;
                                    _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isMotorPlanOptionalPresent_1.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                }
                            });
                        }
                        else if (isPresent_1) {
                            _this.storageService.setActiveContract("hasActiveContract");
                            _this.isVehilceMotorPlan = true;
                            _this.showMotorPlanOptional = false;
                            var startYear = isPresent_1.zstartDate;
                            var getStartYear = startYear.slice(0, 4);
                            var startMonth = isPresent_1.zstartDate;
                            var getStartMonth = startMonth.slice(4, 6);
                            var startDay = isPresent_1.zstartDate;
                            var getStartDay = startDay.slice(6, 8);
                            var year = isPresent_1.zendDate;
                            var getYear = year.slice(0, 4);
                            var month = isPresent_1.zendDate;
                            var getMonth = month.slice(4, 6);
                            var day = isPresent_1.zendDate;
                            var getDay = day.slice(6, 8);
                            // this.loading = true;
                            var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                            vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                _this.parentMotorPlanOpationals = motorPlanOpationals;
                                var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                if (isOptionalPresent) {
                                    _this.isExpireMotorplan = false;
                                    _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isPresent_1.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent, cosyImage: _this.vehicleCosyImage.cosyImageUrlList }, vehicleInfo: vehicleInfo };
                                }
                                else {
                                    _this.isExpireMotorplan = true;
                                    _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                    _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(isPresent_1.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                }
                            });
                        }
                        else {
                            vehicleMotorplan.forEach(function (o, i) {
                                _this.storageService.setActiveContract("hasNoActiveContract");
                                _this.isVehilceMotorPlan = true;
                                _this.showMotorPlanOptional = false;
                                var startYear = o.zstartDate;
                                var getStartYear = startYear.slice(0, 4);
                                var startMonth = o.zstartDate;
                                var getStartMonth = startMonth.slice(4, 6);
                                var startDay = o.zstartDate;
                                var getStartDay = startDay.slice(6, 8);
                                var year = o.zendDate;
                                var getYear = year.slice(0, 4);
                                var month = o.zendDate;
                                var getMonth = month.slice(4, 6);
                                var day = o.zendDate;
                                var getDay = day.slice(6, 8);
                                _this.loading = true;
                                var vehicleMotorplanServiceObservable = _this.vehicleMotorplanService.getMotorPlanOptionalContracts(vinNumber);
                                vehicleMotorplanServiceObservable.subscribe(function (motorPlanOpationals) {
                                    _this.parentMotorPlanOpationals = motorPlanOpationals;
                                    var isOptionalPresent = motorPlanOpationals.contractTypeList.list.find(function (p) { return p.contractType === "TC003"; });
                                    if (isOptionalPresent) {
                                        _this.isExpireMotorplan = false;
                                        _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                        _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(o.zendKm), isExpired: _this.isExpireMotorplan, motorPlanOptional: isOptionalPresent }, vehicleInfo: vehicleInfo };
                                    }
                                    else {
                                        _this.isExpireMotorplan = true;
                                        _this.loadVehicleOncall(getYear + "-" + getMonth + "-" + getDay);
                                        _this.parentVehicleMotorplan = { motoplan: { startDate: getStartYear + "-" + getStartMonth + "-" + getStartDay, expiryDate: getYear + "-" + getMonth + "-" + getDay, expiryKm: Number(o.zendKm), isExpired: _this.isExpireMotorplan }, vehicleInfo: vehicleInfo };
                                    }
                                });
                            });
                        }
                    }
                    else {
                        _this.isVehilceMotorPlan = false;
                    }
                }, function (error) {
                    _this.isVehilceMotorPlan = false;
                    console.error(error);
                });
                return [2 /*return*/];
            });
        });
    };
    MyGarrageComponent.prototype.loadVehicleOncall = function (oncallExpireDate) {
        if (oncallExpireDate !== undefined) {
            var datePipe = new common_1.DatePipe("en-US");
            this.parentOnCall = { onCallEndDate: datePipe.transform(new Date(oncallExpireDate), "yyyy-MM-dd") };
            if (oncallExpireDate === "") {
                this.isOcallLoaded = false;
            }
            else {
                this.isOcallLoaded = true;
            }
        }
        else {
            this.isOcallLoaded = false;
        }
    };
    MyGarrageComponent.prototype.compareDate = function (endDate) {
        var year = endDate.slice(0, 4);
        var month = endDate.slice(4, 6);
        var date = endDate.slice(6, 8);
        var RenewalEndDate = moment([year, Number(month) - 1, date]).add(1, 'months');
        var contractRenewalEndDate = moment(RenewalEndDate).get('year') + "-" + (RenewalEndDate.get('month') + 1) + "-" + RenewalEndDate.get("date");
        var currentDate = moment(new Date()).format("YYYY-MM-DD");
        if (currentDate >= contractRenewalEndDate) {
            return true;
        }
        else {
            return false;
        }
    };
    MyGarrageComponent.prototype.loadModelCode = function (vinNumber, vehicleDetail) {
        var _this = this;
        var vehicleModelCodeServiceObservable = this.vehicleTechService.getVehicleModelCode(vinNumber);
        vehicleModelCodeServiceObservable.subscribe(function (vehicleModelCode) {
            _this.vehicleModelCode = vehicleModelCode;
            _this.loading = true;
            if (_this.vehicleModelCode.zmodel === "" || _this.vehicleModelCode.zmodel === undefined) {
                _this.isTechSpecLoaded = false;
            }
            else {
                _this.isTechSpecLoaded = true;
                _this.loadVehicleTech(_this.vehicleModelCode.zmodel, vehicleDetail.brand);
            }
        }, function (error) {
            console.error(error);
        });
    };
    MyGarrageComponent.prototype.loadVehicleTech = function (modelCode, brand) {
        var _this = this;
        var vehicleTechServiceObservable = this.vehicleTechService.getVehicleTech(modelCode, brand);
        vehicleTechServiceObservable.subscribe(function (vehicleTech) {
            _this.vehicleTech = vehicleTech;
            _this.loading = true;
            if (_this.vehicleTech.length === 0) {
                _this.isTechSpecLoaded = false;
            }
            else {
                _this.parenVehicletTech = { vehicleTechs: _this.vehicleTech, modelCode: modelCode };
            }
        }, function (error) {
            console.error(error);
        });
    };
    MyGarrageComponent.prototype.toggleNavShow = function () {
        this.isNavShown = !this.isNavShown;
    };
    MyGarrageComponent.prototype.ngOnInit = function () {
        this.parentCosyImages = this.vehicleCosyImages;
        if (this.storageService.getOndemandDone() === 'dont-show') {
            this.hideOndemand = 'dont-show';
        }
    };
    MyGarrageComponent.prototype.openCurrentMileageModal = function () {
        var modalRef = this.modalService.open(currentmileagemodal_component_1.CurrentMileageModalComponent);
        modalRef.componentInstance.my_modal_title = 'CURRENT MILEAGE EDIT';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    MyGarrageComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    MyGarrageComponent = __decorate([
        core_1.Component({
            template: require('./mygarage.component.html')
        }),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            ng_bootstrap_1.NgbModal,
            vehicle_cosy_image_service_1.VehicleCosyImageService,
            vehicle_motorplan_service_1.VehicleMotorplanService,
            vehicle_ocall_service_1.VehicleOnCallService,
            vehicle_tech_service_1.VehicleTechService,
            storage_service_1.StorageService,
            router_1.Router])
    ], MyGarrageComponent);
    return MyGarrageComponent;
}());
exports.MyGarrageComponent = MyGarrageComponent;
