"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.headMOTComponent = void 0;
var core_1 = require("@angular/core");
var $ = require("jquery");
var _services_1 = require("../../_services");
var router_1 = require("@angular/router");
var storage_service_1 = require("@/_services/storage-service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var insertid_mdl_component_1 = require("../../_components/ondemand/insertid_mdl/insertid_mdl.component");
var headMOTComponent = /** @class */ (function () {
    function headMOTComponent(router, authenticationService, activatedRoute, storageService, modalService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.activatedRoute = activatedRoute;
        this.storageService = storageService;
        this.modalService = modalService;
        this.display = 'none';
        this.navbarOpen = false;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.get_gcdm_config = this.authenticationService.get_config();
        // this.activatedRoute.params.subscribe(params => {
        //     console.log("get the headComponent info ::: ", params);
        // });
    }
    // brandMOT: boolean = true;
    headMOTComponent.prototype.toggleNavbar = function () {
        this.navbarOpen = !this.navbarOpen;
    };
    headMOTComponent.prototype.ngOnInit = function () {
    };
    headMOTComponent.prototype.openInsertIDmdl = function () {
        var modalRef = this.modalService.open(insertid_mdl_component_1.InsertIDmdlComponent);
        modalRef.componentInstance.my_modal_title = 'Account Search';
        modalRef.result.then(function (result) {
            // console.log(result);
        }).catch(function (error) {
            // console.log(error);
        });
    };
    headMOTComponent.prototype.openModal = function () {
        this.display = "block";
    };
    headMOTComponent.prototype.onCloseHandled = function () {
        this.display = "none";
    };
    headMOTComponent.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    headMOTComponent.prototype.showMSG = function () {
        $('.notification_wrap').addClass('hide');
        $('.full_Msg').removeClass('hide');
        console.log('TEST');
    };
    headMOTComponent.prototype.closeMSG = function () {
        $('.full_Msg').addClass('hide');
        $('.notification_wrap').removeClass('hide');
        $('#MSG_1').removeClass('unread');
    };
    headMOTComponent = __decorate([
        core_1.Component({
            selector: 'app-head-mot',
            template: require('./head-mot.component.html')
        }),
        __metadata("design:paramtypes", [router_1.Router, _services_1.AuthenticationService,
            router_1.ActivatedRoute, storage_service_1.StorageService, ng_bootstrap_1.NgbModal])
    ], headMOTComponent);
    return headMOTComponent;
}());
exports.headMOTComponent = headMOTComponent;
