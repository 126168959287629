"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddRemoveVehicleService = void 0;
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var _services_1 = require("@/_services");
var storage_service_1 = require("@/_services/storage-service");
var AddRemoveVehicleService = /** @class */ (function () {
    function AddRemoveVehicleService(http, storageService, authenticationService, tokenExtractor) {
        this.http = http;
        this.storageService = storageService;
        this.authenticationService = authenticationService;
        this.tokenExtractor = tokenExtractor;
        this.httpClient = http;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    AddRemoveVehicleService.prototype.removeVehicle = function (bpNo, bodyNo, vin) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
        var configs = { headers: headers };
        var VehicleChangeOfOwnership = { activeContract: this.storageService.getActiveContract(), bpNo: bpNo, bodyNo: bodyNo, vin: vin };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/vehicle-change-ownership", VehicleChangeOfOwnership, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    AddRemoveVehicleService.prototype.addVehicle = function (addNewVehicle) {
        var csrfToken = this.tokenExtractor.getToken();
        var headers = new http_1.HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
        var configs = { headers: headers };
        var add = {
            "bpNo": this.storageService.getGcdmCustBp(),
            "communicationPreference": addNewVehicle.contactpreferences,
            "email": addNewVehicle.contactpreferences === 'E-mail' ? addNewVehicle.communicationMethod : "",
            "phone": addNewVehicle.contactpreferences === 'Phone' ? addNewVehicle.communicationMethod : "",
            "chassis": addNewVehicle.chassisnumber,
            "registration": addNewVehicle.registrationnumber,
            "year": addNewVehicle.year,
            "model": addNewVehicle.model,
            "vin": addNewVehicle.chassisnumber,
            "colour": "",
            "trim": "",
            "purchasedDate": "",
            "purchasedMileage": "",
            "currentMileage": ""
        };
        return this.http.post(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/vehicles/add-vehicle", add, configs)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    AddRemoveVehicleService.prototype.postActivateOnDamnd = function (guid) {
        return this.http.get(this.get_gcdm_config['CP_hostname'] + "/customerportalapi/rest/cp/crm/customer/on-demand/activation/" + guid)
            .pipe(operators_1.map(function (data) { return data; }));
    };
    AddRemoveVehicleService = __decorate([
        core_1.Injectable({ providedIn: 'root' }),
        __metadata("design:paramtypes", [http_1.HttpClient, storage_service_1.StorageService, _services_1.AuthenticationService, http_1.HttpXsrfTokenExtractor])
    ], AddRemoveVehicleService);
    return AddRemoveVehicleService;
}());
exports.AddRemoveVehicleService = AddRemoveVehicleService;
