"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertComponent = void 0;
var core_1 = require("@angular/core");
var _services_1 = require("@/_services");
var AlertComponent = /** @class */ (function () {
    function AlertComponent(alertService) {
        this.alertService = alertService;
        this.isHideAlert = false;
    }
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.removeAfter = 7000;
        this.subscription = this.alertService.getAlert()
            .subscribe(function (message) {
            switch (message && message.type) {
                case 'success':
                    message.cssClass = 'alert alert-success';
                    break;
                case 'error':
                    message.cssClass = 'alert alert-danger';
                    break;
                case 'warning':
                    message.cssClass = 'alert alert-warning';
                    break;
                case 'info':
                    message.cssClass = 'alert alert-info';
                    break;
            }
            if (_this.message !== undefined || _this.message !== '') {
                _this.isHideAlert = false;
            }
            _this.message = message;
            setTimeout(function () {
                _this.isHideAlert = true;
            }, _this.removeAfter);
        });
    };
    AlertComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    AlertComponent = __decorate([
        core_1.Component({ selector: 'alert', template: require('./alert.component.html') }),
        __metadata("design:paramtypes", [_services_1.AlertService])
    ], AlertComponent);
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;
