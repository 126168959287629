"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorInterceptor = void 0;
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var gcdmErrorMessage = require("@/_content/gcdmErrorMessage/gcdmErrorMessage.json");
var storage_service_1 = require("@/_services/storage-service");
var _services_1 = require("@/_services");
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(authenticationService, alertService, storageService) {
        this.authenticationService = authenticationService;
        this.alertService = alertService;
        this.storageService = storageService;
        this.gcdmErrorMessage = gcdmErrorMessage;
        this.gcdm_login_env_hostname = process.env.gcdm_login_env_hostname;
        this.gcdm_client_id = process.env.gcdm_client_id;
        this.get_gcdm_config = this.authenticationService.get_config();
    }
    ErrorInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(operators_1.catchError(function (err) {
            if (err.status === 401) {
                _this.logout();
            }
            _this.gcdmErrorMessage.gcdmErrorMessage.forEach(function (o, i) {
                if (err.error.reasons !== undefined && err.error.reasons[0].value !== null) {
                    _this.alertService.error(err.error.reasons[0].value);
                    return;
                }
                if (err.error.errorcode === o.errorCode) {
                    _this.alertService.error(o.descript);
                    return;
                }
            });
            var error = err.error.message !== undefined ? err.error.message : err.statusText;
            return rxjs_1.throwError(error);
        }));
    };
    ErrorInterceptor.prototype.logout = function () {
        this.storageService.removeItems();
        this.storageService.clearStore();
        sessionStorage.clear();
        this.authenticationService.gcdmLogoff().subscribe();
        window.location.href = this.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2F" + this.get_gcdm_config['logoff_hostname'];
    };
    ErrorInterceptor = __decorate([
        core_1.Injectable(),
        __metadata("design:paramtypes", [_services_1.AuthenticationService,
            _services_1.AlertService, storage_service_1.StorageService])
    ], ErrorInterceptor);
    return ErrorInterceptor;
}());
exports.ErrorInterceptor = ErrorInterceptor;
