"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppModule = void 0;
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var http_1 = require("@angular/common/http");
var angular8_mat_datepicker_1 = require("angular8-mat-datepicker");
var ngx_daterangepicker_material_1 = require("ngx-daterangepicker-material");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var user_service_reslover_1 = require("./_resolver/user-service.reslover");
// import { Angulartics2Module } from 'angulartics2';
// import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
var animations_1 = require("@angular/platform-browser/animations");
var app_routing_1 = require("./app.routing");
var _helpers_1 = require("./_helpers");
var app_component_1 = require("./app.component");
var must_match_directive_1 = require("./_helpers/must-match.directive");
var home_component_1 = require("./home/home.component");
var prospectHome_component_1 = require("./home/prospectHome.component");
var splash_component_1 = require("./home/splash.component");
var login_component_1 = require("./login/login.component");
var register_component_1 = require("./register/register.component");
var notifications_component_1 = require("./notifications/notifications.component");
var link_component_1 = require("./link/link.component");
var existing_component_1 = require("./link/existing/existing.component");
var profile_component_1 = require("./profile/profile.component");
var preferredretailer_component_1 = require("./profile/preferredretailer/preferredretailer.component");
var privacysettings_component_1 = require("./profile/privacysettings/privacysettings.component");
var commsmethodbmwmodal_component_1 = require("./profile/privacysettings/commsmethodbmwmodal/commsmethodbmwmodal.component");
var commsmethodfsmodal_component_1 = require("./profile/privacysettings/commsmethodfsmodal/commsmethodfsmodal.component");
var cofirmmodal_1 = require("./vehicleprofile/cofirmmodal");
var profilemodal_component_1 = require("./profile/profilemodal/profilemodal.component");
var contactdetailsmodal_component_1 = require("./profile/contactdetailsmodal/contactdetailsmodal.component");
var preferredretailermodal_component_1 = require("./profile/preferredretailer/preferredretailermodal/preferredretailermodal.component");
var currentmileagemodal_component_1 = require("./mygarage/currentmileagemodal/currentmileagemodal.component");
var logindetailsmodal_component_1 = require("./profile/logindetailsmodal/logindetailsmodal.component");
var serviceoptionsmodal_component_1 = require("./serviceoptions/serviceoptionsmodal/serviceoptionsmodal.component");
var serviceonline_component_1 = require("./serviceonline/serviceonline/serviceonline.component");
var renewmotorplanmodal_component_1 = require("./motorplancontract/renewmotorplanmodal/renewmotorplanmodal.component");
var blackoilmodal_component_1 = require("./motorplancontract/blackoilmodal/blackoilmodal.component");
var tyreoptionsmodal_component_1 = require("./tyresearch/tyreoptionsmodal/tyreoptionsmodal.component");
var myaccount_component_1 = require("./myaccount/myaccount.component");
var accountadjustment_component_1 = require("./myaccount/accountadjustment/accountadjustment.component");
var crossborder_component_1 = require("./myaccount/crossborder/crossborder.component");
var documents_component_1 = require("./myaccount/documents/documents.component");
var endofterm_component_1 = require("./myaccount/endofterm/endofterm.component");
var statements_component_1 = require("./myaccount/statements/statements.component");
var _components_1 = require("./_components");
var faqs_component_1 = require("./faqs/faqs.component");
var faqs1_component_1 = require("./faqs/faqs1/faqs1.component");
var faqs2_component_1 = require("./faqs/faqs2/faqs2.component");
var faqs3_component_1 = require("./faqs/faqs3/faqs3.component");
var faqs4_component_1 = require("./faqs/faqs4/faqs4.component");
var faqs5_component_1 = require("./faqs/faqs5/faqs5.component");
var faqs6_component_1 = require("./faqs/faqs6/faqs6.component");
var faqs7_component_1 = require("./faqs/faqs7/faqs7.component");
var faqs8_component_1 = require("./faqs/faqs8/faqs8.component");
var faqs9_component_1 = require("./faqs/faqs9/faqs9.component");
var mygarage_component_1 = require("./mygarage/mygarage.component");
var serviceinfo_component_1 = require("./mygarage/serviceinfo/serviceinfo.component");
var search_component_1 = require("@/search/search.component");
var footer_bmw_component_1 = require("./nav/footer/footer-bmw.component");
var footer_mini_component_1 = require("./nav/footer/footer-mini.component");
var footer_mot_component_1 = require("./nav/footer/footer-mot.component");
var thankyou_component_1 = require("./thankyou/thankyou.component");
var head_bmw_component_1 = require("./nav/head/head-bmw.component");
var head_mini_component_1 = require("./nav/head/head-mini.component");
var head_mot_component_1 = require("./nav/head/head-mot.component");
var head_bmw_prospect_component_1 = require("./nav/head/head-bmw-prospect.component");
var head_mini_prospect_component_1 = require("./nav/head/head-mini-prospect.component");
var head_mot_prospect_component_1 = require("./nav/head/head-mot-prospect.component");
var technicalcampaign_component_1 = require("./_components/technicalcampaignmodal/technicalcampaign.component");
var contactadealer_component_1 = require("./_components/contacadealermodal/contactadealer.component");
var epaas_component_1 = require("./epaas/epaas.component");
var policy_store_consent_resolver_1 = require("@/_resolver/policy-store-consent.resolver");
var get_customer_resolver_1 = require("@/_resolver/get-customer.resolver");
var material_1 = require("@angular/material");
var storage_service_1 = require("@/_services/storage-service");
var financeoverview_component_1 = require("./financeoverview/financeoverview.component");
var technicalspecs_component_1 = require("./technicalspecs/technicalspecs.component");
var mottechnicalspecs_component_1 = require("./technicalspecs/mottechnicalspecs.component");
var motorplancontract_component_1 = require("./motorplancontract/motorplancontract.component");
var nomotorplancontract_component_1 = require("./motorplancontract/nomotorplancontract.component");
var motorplanoptionalcontract_component_1 = require("./motorplancontract/motorplanoptionalcontract.component");
var equity_component_1 = require("./equity/equity.component");
var statementoverview_component_1 = require("./statementoverview/statementoverview.component");
var vehicleprofile_component_1 = require("./vehicleprofile/vehicleprofile.component");
var oncall_component_1 = require("./oncall/oncall.component");
var nooncall_component_1 = require("./oncall/nooncall.component");
var serviceoptions_component_1 = require("./serviceoptions/serviceoptions.component");
var motserviceoptions_component_1 = require("./serviceoptions/motserviceoptions.component");
var noserviceoptions_component_1 = require("./serviceoptions/noserviceoptions.component");
var tyresearch_component_1 = require("./tyresearch/tyresearch.component");
var mottyresearch_component_1 = require("./tyresearch/mottyresearch.component");
var notyresearch_component_1 = require("./tyresearch/notyresearch.component");
var servicehistory_component_1 = require("./servicehistory/servicehistory.component");
var noservicehistory_component_1 = require("./servicehistory/noservicehistory.component");
var fullservicehistory_component_1 = require("./mygarage/serviceinfo/fullservicehistory/fullservicehistory.component");
var servicedue_component_1 = require("./servicedue/servicedue.component");
var vehiclecarousel_component_1 = require("./vehiclecarousel/vehiclecarousel.component");
var authentication_component_1 = require("./authentication/authentication.component");
var customeractivatemodal_component_1 = require("@/validatecustdetails/customerdetailsactivationmodal/customeractivatemodal.component");
var customerdetailspolicystoremodal_component_1 = require("@/validatecustdetails/customerdetailspolicystoremodal/customerdetailspolicystoremodal.component");
var addvehiclemodal_component_1 = require("./vehiclecarousel/addvehiclemodal/addvehiclemodal.component");
var endofterm_component_2 = require("./endofterm/endofterm.component");
var app_routing_2 = require("./app.routing");
var router_1 = require("@angular/router");
var loader_service_1 = require("./_services/loader/loader.service");
var loader_interceptor_1 = require("./interceptors/loader/loader.interceptor");
var noticemsg_component_1 = require("./_components/notice_msg/noticemsg.component");
var discover_bmw_component_1 = require("./_components/discover/discover-bmw.component");
var discover_mini_component_1 = require("./_components/discover/discover-mini.component");
var discover_mot_component_1 = require("./_components/discover/discover-mot.component");
var insertid_mdl_component_1 = require("./_components/ondemand/insertid_mdl/insertid_mdl.component");
var preferredretailer_ovrvw_component_1 = require("./_components/preferredretailer-ovrvw/preferredretailer-ovrvw.component");
var nopreferredretailer_ovrvw_component_1 = require("./_components/preferredretailer-ovrvw/nopreferredretailer-ovrvw.component");
var psteml_component_1 = require("./psteml/psteml.component");
var bn_ng_idle_1 = require("bn-ng-idle");
var environment_1 = require("../environments/environment");
var mock_module_1 = require("../mock/mock.module");
var ngx_useful_swiper_1 = require("ngx-useful-swiper");
var csrf_interceptor_1 = require("./_helpers/csrf.interceptor");
// import { HttpXsrfCookieExtractor, XSRF_COOKIE_NAME, XSRF_HEADER_NAME } from './_helpers/app-xhr-manipulation';
var extraModules = environment_1.environment.mockApi ? [mock_module_1.MockModule] : [];
var jwtProvider = !environment_1.environment.mockApi ? [{ provide: http_1.HTTP_INTERCEPTORS, useClass: _helpers_1.JwtInterceptor, multi: true }] : [];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule = __decorate([
        core_1.NgModule({
            imports: __spreadArrays([
                platform_browser_1.BrowserModule,
                forms_1.FormsModule,
                forms_1.ReactiveFormsModule,
                forms_1.FormsModule,
                http_1.HttpClientModule,
                app_routing_1.appRoutingModule,
                // RouterModule.forRoot(routes),
                router_1.RouterModule.forRoot(app_routing_2.routes, {
                    scrollPositionRestoration: 'top'
                }),
                ng_bootstrap_1.NgbModule,
                angular8_mat_datepicker_1.DatepickerModule,
                ngx_daterangepicker_material_1.NgxDaterangepickerMd,
                material_1.MatStepperModule,
                material_1.MatButtonModule,
                material_1.MatFormFieldModule,
                material_1.MatInputModule,
                material_1.MatRippleModule,
                material_1.MatCheckboxModule,
                material_1.MatListModule,
                animations_1.BrowserAnimationsModule,
                ngx_useful_swiper_1.NgxUsefulSwiperModule,
                http_1.HttpClientXsrfModule.withOptions({
                    cookieName: 'XSRF-TOKEN',
                    headerName: 'X-XSRF-TOKEN'
                })
            ], extraModules),
            exports: [
                mygarage_component_1.MyGarrageComponent,
                vehicleprofile_component_1.VehicleProfileComponent,
                material_1.MatStepperModule,
                material_1.MatButtonModule,
                material_1.MatFormFieldModule,
                material_1.MatInputModule,
                material_1.MatRippleModule,
                material_1.MatCheckboxModule,
                material_1.MatListModule,
            ],
            declarations: [
                app_component_1.AppComponent,
                splash_component_1.SplashComponent,
                home_component_1.HomeComponent,
                prospectHome_component_1.ProspectHomeComponent,
                login_component_1.LoginComponent,
                notifications_component_1.NotificationsComponent,
                register_component_1.RegisterComponent,
                link_component_1.LinkComponent,
                existing_component_1.ExistingComponent,
                profile_component_1.ProfileComponent,
                preferredretailer_component_1.PreferredRetailerComponent,
                privacysettings_component_1.PrivacySettingsComponent,
                myaccount_component_1.MyAccountComponent,
                accountadjustment_component_1.AccountAdjustmentComponent,
                crossborder_component_1.CrossBorderComponent,
                documents_component_1.DocumentsComponent,
                endofterm_component_1.EndofTermComponent,
                statements_component_1.StatementsComponent,
                _components_1.AlertComponent,
                must_match_directive_1.MustMatchDirective,
                faqs_component_1.faqsComponent,
                faqs1_component_1.faqs1Component,
                faqs2_component_1.faqs2Component,
                faqs3_component_1.faqs3Component,
                faqs4_component_1.faqs4Component,
                faqs5_component_1.faqs5Component,
                faqs6_component_1.faqs6Component,
                faqs7_component_1.faqs7Component,
                faqs8_component_1.faqs8Component,
                faqs9_component_1.faqs9Component,
                mygarage_component_1.MyGarrageComponent,
                serviceinfo_component_1.ServiceInfoComponent,
                search_component_1.SearchComponent,
                profilemodal_component_1.ProfileModalComponent,
                contactdetailsmodal_component_1.ContactDetailsModalComponent,
                logindetailsmodal_component_1.LoginDetailsModalComponent,
                preferredretailermodal_component_1.PreferredRetailerModalComponent,
                currentmileagemodal_component_1.CurrentMileageModalComponent,
                commsmethodbmwmodal_component_1.CommsMethodBMWModalComponent,
                addvehiclemodal_component_1.AddVehicleModalComponent,
                cofirmmodal_1.ConfirmModalComponent,
                commsmethodfsmodal_component_1.CommsMethodFSModalComponent,
                serviceoptionsmodal_component_1.ServiceOptionsModalComponent,
                serviceonline_component_1.ServiceOnlineComponent,
                renewmotorplanmodal_component_1.RenewMotorplanModalComponent,
                blackoilmodal_component_1.BlackOilModalComponent,
                tyreoptionsmodal_component_1.TyreOptionsModalComponent,
                thankyou_component_1.ThankyouComponent,
                head_bmw_component_1.headBMWComponent,
                head_mini_component_1.headMINIComponent,
                head_mot_component_1.headMOTComponent,
                head_bmw_prospect_component_1.headBMWProspectComponent,
                head_mini_prospect_component_1.headMINIProspectComponent,
                head_mot_prospect_component_1.headMOTProspectComponent,
                footer_bmw_component_1.footerBMWComponent,
                footer_mini_component_1.footerMINIComponent,
                footer_mot_component_1.footerMOTComponent,
                financeoverview_component_1.FinanceOverviewComponent,
                technicalspecs_component_1.TechnicalSpecsComponent,
                mottechnicalspecs_component_1.MotTechnicalSpecsComponent,
                motorplancontract_component_1.MotorplanContractComponent,
                nomotorplancontract_component_1.NoMotorplanContractComponent,
                motorplanoptionalcontract_component_1.NoMotorplanOptionalContractComponent,
                equity_component_1.EquityComponent,
                statementoverview_component_1.StatementOverviewComponent,
                vehicleprofile_component_1.VehicleProfileComponent,
                oncall_component_1.OnCallComponent,
                nooncall_component_1.NoOnCallComponent,
                serviceoptions_component_1.ServiceOptionsComponent,
                noserviceoptions_component_1.NoServiceOptionsComponent,
                motserviceoptions_component_1.MotServiceOptionsComponent,
                tyresearch_component_1.TyreSearchComponent,
                notyresearch_component_1.NoTyreSearchComponent,
                mottyresearch_component_1.MotTyreSearchComponent,
                servicehistory_component_1.ServiceHistoryComponent,
                noservicehistory_component_1.NoServiceHistoryComponent,
                fullservicehistory_component_1.FullServiceHistoryComponent,
                servicedue_component_1.ServiceDueComponent,
                vehiclecarousel_component_1.VehicleCarouselComponent,
                endofterm_component_2.EndOfTermComponent,
                vehiclecarousel_component_1.VehicleCarouselComponent,
                authentication_component_1.AuthenticationComponent,
                customerdetailspolicystoremodal_component_1.customerdetailsPolicystoremodalComponent,
                customeractivatemodal_component_1.CustomerActivateModalComponent,
                noticemsg_component_1.NoticeMsgComponent,
                discover_bmw_component_1.DiscoverBMWComponent,
                discover_mini_component_1.DiscoverMiniComponent,
                discover_mot_component_1.DiscoverMotComponent,
                insertid_mdl_component_1.InsertIDmdlComponent,
                preferredretailer_ovrvw_component_1.PreferredRetailerOvrvwComponent,
                nopreferredretailer_ovrvw_component_1.NoPreferredRetailerOvrvwComponent,
                psteml_component_1.PostEmailComponent,
                technicalcampaign_component_1.TechnicalCampaignComponent,
                contactadealer_component_1.ContactADealerComponent,
                epaas_component_1.EpaasComponent,
            ],
            entryComponents: [
                authentication_component_1.AuthenticationComponent,
                profilemodal_component_1.ProfileModalComponent,
                contactdetailsmodal_component_1.ContactDetailsModalComponent,
                logindetailsmodal_component_1.LoginDetailsModalComponent,
                preferredretailermodal_component_1.PreferredRetailerModalComponent,
                currentmileagemodal_component_1.CurrentMileageModalComponent,
                commsmethodbmwmodal_component_1.CommsMethodBMWModalComponent,
                addvehiclemodal_component_1.AddVehicleModalComponent,
                cofirmmodal_1.ConfirmModalComponent,
                commsmethodfsmodal_component_1.CommsMethodFSModalComponent,
                customerdetailspolicystoremodal_component_1.customerdetailsPolicystoremodalComponent,
                customeractivatemodal_component_1.CustomerActivateModalComponent,
                serviceoptionsmodal_component_1.ServiceOptionsModalComponent,
                renewmotorplanmodal_component_1.RenewMotorplanModalComponent,
                blackoilmodal_component_1.BlackOilModalComponent,
                tyreoptionsmodal_component_1.TyreOptionsModalComponent,
                insertid_mdl_component_1.InsertIDmdlComponent,
                technicalcampaign_component_1.TechnicalCampaignComponent,
                contactadealer_component_1.ContactADealerComponent,
            ],
            providers: __spreadArrays(jwtProvider, [
                { provide: http_1.HTTP_INTERCEPTORS, useClass: _helpers_1.ErrorInterceptor, multi: true },
                { provide: http_1.HTTP_INTERCEPTORS, useClass: loader_interceptor_1.LoaderInterceptor, multi: true },
                { provide: http_1.HTTP_INTERCEPTORS, useClass: csrf_interceptor_1.CsrfInterceptor, multi: true },
                // { provide: HttpXsrfTokenExtractor, useClass: HttpXsrfCookieExtractor },
                // { provide: XSRF_COOKIE_NAME, useValue: 'XSRF-TOKEN' },
                // { provide: XSRF_HEADER_NAME, useValue: 'X-XSRF-TOKEN' },
                // {provide: LocationStrategy, useClass: HashLocationStrategy},
                // HttpXsrfCookieExtractor,
                policy_store_consent_resolver_1.PolicyStoreConsentResolve, user_service_reslover_1.UserServiceResolve, loader_service_1.LoaderService, get_customer_resolver_1.GetCustomerResolve,
                authentication_component_1.AuthenticationComponent, storage_service_1.StorageService, platform_browser_1.Title, bn_ng_idle_1.BnNgIdleService,
            ]),
            bootstrap: [app_component_1.AppComponent],
        })
    ], AppModule);
    return AppModule;
}());
exports.AppModule = AppModule;
;
