"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerActivateModalComponent = void 0;
var core_1 = require("@angular/core");
var _services_1 = require("@/_services");
var common_1 = require("@angular/common");
var CustomerActivateModalComponent = /** @class */ (function () {
    function CustomerActivateModalComponent(location, userService, authenticationService) {
        this.location = location;
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.isDataLoaded = false;
        this.isDataLoaded = true;
    }
    CustomerActivateModalComponent.prototype.onAccept = function () {
        var _this = this;
        this.userService.resendActivationMail(this.email).subscribe(function (data) {
            _this.authenticationService.gcdmLogoff().subscribe();
            _this.location.back();
        });
    };
    CustomerActivateModalComponent.prototype.onReject = function () {
        this.authenticationService.gcdmLogoff().subscribe();
        this.location.back();
    };
    CustomerActivateModalComponent.prototype.ngOnInit = function () {
        this.email = this.customerEmail;
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", String)
    ], CustomerActivateModalComponent.prototype, "customerEmail", void 0);
    CustomerActivateModalComponent = __decorate([
        core_1.Component({
            template: require('./customeractivatemodal.component.html')
        }),
        __metadata("design:paramtypes", [common_1.Location,
            _services_1.UserService,
            _services_1.AuthenticationService])
    ], CustomerActivateModalComponent);
    return CustomerActivateModalComponent;
}());
exports.CustomerActivateModalComponent = CustomerActivateModalComponent;
