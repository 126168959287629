"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleProfileComponent = void 0;
var core_1 = require("@angular/core");
// import { MatDialog, MatDialogRef } from '@angular/material';
var fileSaver = require("file-saver");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var add_remove_vehicle_service_1 = require("@/_services/add-remove-vehicle.service");
var confirmmodal_component_1 = require("./cofirmmodal/confirmmodal.component");
var _services_1 = require("@/_services");
var storage_service_1 = require("@/_services/storage-service");
var VehicleProfileComponent = /** @class */ (function () {
    function VehicleProfileComponent(modalService, authenticationService, aAddRemoveVehicleService, alertService, storageService) {
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.aAddRemoveVehicleService = aAddRemoveVehicleService;
        this.alertService = alertService;
        this.storageService = storageService;
        this.loading = false;
        //   removeVehicleFromCust(childVehicleDetail:any){
        //     let removeVehicleObservable = this.aAddRemoveVehicleService.removeVehicle(childVehicleDetail.bpOwner, childVehicleDetail.bodyNumber, childVehicleDetail.bodyNumber);
        //     removeVehicleObservable.subscribe((removeVehicle: any) => {this.removeVehicle = removeVehicle; 
        //     this.loading = true;
        //     });
        // };
        this.getOwenManual = function (childVehicleDetail) {
            var brand = "";
            if (childVehicleDetail.brand === 'BM') {
                brand = 'BMW';
            }
            if (childVehicleDetail.brand === 'MI') {
                brand = 'MINI';
            }
            if (childVehicleDetail.brand === 'MO') {
                brand = 'MOTORRAD';
            }
            var url = 'https://bao.bmwgroup.com/bao-rd/manual/?portalId=bmwstage2zaprivate&allowedBrands=' + brand.toUpperCase() + '&locale=en-GB&currentVin=' + childVehicleDetail.vinNumber;
            window.open(url, '_blank');
        };
        this.get_gcdm_config = this.authenticationService.get_config();
        this.get_brand = this.get_gcdm_config['brandUpperCase'];
        this.loginBrand = this.get_gcdm_config['brand'];
    }
    VehicleProfileComponent.prototype.saveFile = function (data, filename) {
        var blob = new Blob([data], { type: 'application/octet-stream' });
        fileSaver.saveAs(blob, filename);
    };
    VehicleProfileComponent.prototype.ngOnInit = function () {
    };
    VehicleProfileComponent.prototype.openConfirmModal = function (childVehicleDetail) {
        var _this = this;
        var modalRef = this.modalService.open(confirmmodal_component_1.ConfirmModalComponent);
        modalRef.componentInstance.my_modal_title = 'VEHICLE REMOVAL CONFIRMATION';
        modalRef.componentInstance.removethisVehicle = childVehicleDetail;
        modalRef.result.then(function (result) {
            _this.aAddRemoveVehicleService.removeVehicle(result.bpOwner, result.bodyNumber, result.vinNumber)
                .subscribe(function (data) {
                if (_this.storageService.getActiveContract() === "hasActiveContract") {
                    _this.alertService.success('Vehicle Removed Successful', true);
                }
                else {
                    _this.alertService.success("Vehicle Removed Successful, You wont see this vehicle on your next login.", true);
                }
            }, function (error) {
                _this.alertService.error(error);
            });
        }).catch(function (error) {
            // console.log(error);
        });
    };
    VehicleProfileComponent.prototype.getDismissReason = function (reason) {
        if (reason === ng_bootstrap_1.ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ng_bootstrap_1.ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], VehicleProfileComponent.prototype, "childVehicleDetail", void 0);
    VehicleProfileComponent = __decorate([
        core_1.Component({
            selector: 'vehicle-profile',
            template: require('./vehicleprofile.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbModal,
            _services_1.AuthenticationService,
            add_remove_vehicle_service_1.AddRemoveVehicleService,
            _services_1.AlertService,
            storage_service_1.StorageService])
    ], VehicleProfileComponent);
    return VehicleProfileComponent;
}());
exports.VehicleProfileComponent = VehicleProfileComponent;
