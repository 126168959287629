"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommsMethodBMWModalComponent = void 0;
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var storage_service_1 = require("@/_services/storage-service");
var CommsMethodBMWModalComponent = /** @class */ (function () {
    function CommsMethodBMWModalComponent(activeModal, formBuilder, storageService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.storageService = storageService;
        this.contactPolicyConsentsData = [];
        this.contactPolicyConsentsDataDisplayNsc = [];
        this.isDataLoaded = false;
        this.reducerFunction = function (data) {
            data["label"] = data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS' : data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS'
                : data["label"] = data.usageId === 'EMAIL' ? 'Email'
                    : data["label"] = data.usageId === 'MAIL' ? 'Post'
                        : data["label"] = data.usageId === 'ANALYTICS' ? 'Analytics' : data["label"] = data.usageId === 'IN_CAR' ? 'In-vehicle'
                            : data.usageId;
            return data;
        };
        this.CommsMethodBMWEditForm = this.formBuilder.group({
            contactPolicyConsents: new forms_1.FormArray([])
        });
        // async orders
        // of(
        this.addCheckboxes(this.storageService.getNscPolicyList());
        this.isDataLoaded = true;
    }
    CommsMethodBMWModalComponent.prototype.submitForm = function () {
        var _this = this;
        this.CommsMethodBMWEditForm.value.contactPolicyConsents
            .map(function (v, i) { return v ? _this.contactPolicyConsentsDataDisplayNsc[i].consent = 'ACCEPT' : _this.contactPolicyConsentsDataDisplayNsc[i].consent = 'REJECT'; });
        this.contactPolicyConsentsDataDisplayNsc.forEach(function (o, i) { delete _this.contactPolicyConsentsData[i].label; });
        var sortBeforeCheckForDup = this.contactPolicyConsentsDataDisplayNsc.sort(function (a, b) { return b.consentDate > a.consentDate ? -1 : 1; });
        var contactPolicyConsentsUpdate = this.checkDuplicateInObject(sortBeforeCheckForDup);
        this.activeModal.close(contactPolicyConsentsUpdate);
    };
    CommsMethodBMWModalComponent.prototype.ngOnInit = function () {
    };
    CommsMethodBMWModalComponent.prototype.checkDuplicateInObject = function (inputArray) {
        var seenDuplicate = false, testObject = {};
        var returnItems = [];
        inputArray.map(function (item) {
            var itemPropertyName = item["usageId"];
            if (itemPropertyName in testObject) {
                testObject[itemPropertyName].duplicate = true;
                item.duplicate = true;
                seenDuplicate = true;
            }
            else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                returnItems.push(item);
            }
        });
        return returnItems;
    };
    CommsMethodBMWModalComponent.prototype.sortByLetter = function (contactConsents) {
        return contactConsents.sort(function (a, b) {
            var nameA = a.usageId, nameB = b.usageId;
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
    };
    CommsMethodBMWModalComponent.prototype.addCheckboxes = function (contactPolicyConsent) {
        var _this = this;
        contactPolicyConsent.forEach(function (o, i) {
            if (o.legalEntityId === 'BMW_SOUTH_AFRICA_PTY_LTD_'
                && o.policyId === "Privacy_Statement") {
                _this.contactPolicyConsentsData.push(_this.reducerFunction(o));
            }
        });
        var sortBeforeCheckForDup = this.contactPolicyConsentsData.sort(function (a, b) { return new Date(b.consentDate) > new Date(a.consentDate) ? 1 : -1; });
        this.contactPolicyConsentsDataDisplayNsc = this.checkDuplicateInObject(sortBeforeCheckForDup);
        this.contactPolicyConsentsDataDisplayNsc = this.sortByLetter(this.contactPolicyConsentsDataDisplayNsc);
        this.contactPolicyConsentsDataDisplayNsc.forEach(function (o, i) {
            var control = new forms_1.FormControl(o.consent === 'ACCEPT' ? true : false); // if item is ACCEPT set to true, else false
            _this.CommsMethodBMWEditForm.controls.contactPolicyConsents.push(control);
        });
    };
    __decorate([
        core_1.Input(),
        __metadata("design:type", Object)
    ], CommsMethodBMWModalComponent.prototype, "my_modal_title", void 0);
    CommsMethodBMWModalComponent = __decorate([
        core_1.Component({
            selector: 'app-commsmethodbmwmodal',
            template: require('./commsmethodbmwmodal.component.html')
        }),
        __metadata("design:paramtypes", [ng_bootstrap_1.NgbActiveModal,
            forms_1.FormBuilder,
            storage_service_1.StorageService])
    ], CommsMethodBMWModalComponent);
    return CommsMethodBMWModalComponent;
}());
exports.CommsMethodBMWModalComponent = CommsMethodBMWModalComponent;
