"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostEmailComponent = void 0;
var core_1 = require("@angular/core");
var onDemand_login_bp_service_1 = require("@/_services/onDemand-login-bp.service");
var router_1 = require("@angular/router");
var _services_1 = require("@/_services");
var PostEmailComponent = /** @class */ (function () {
    function PostEmailComponent(activatedRoute, router, alertService, ondemandLoginBpService) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.alertService = alertService;
        this.ondemandLoginBpService = ondemandLoginBpService;
        this.vehicleServiceHists = [];
        this.returnMessage = [];
        this.gcdm_env_hostname = process.env.gcdm_env_hostname;
        this.activatedRoute.params.subscribe(function (params) {
            _this.guid = params.guid;
        });
    }
    PostEmailComponent.prototype.loadActivationMail = function (guid) {
        return __awaiter(this, void 0, void 0, function () {
            var customerProfileObservable;
            var _this = this;
            return __generator(this, function (_a) {
                customerProfileObservable = this.ondemandLoginBpService.postActivateOnDamnd(guid);
                customerProfileObservable.subscribe(function (returnMessage) {
                    _this.returnMessage = returnMessage;
                    _this.loading = true;
                    _this.returnMessage.messages.forEach(function (l) {
                        if (l.msgType === "E") {
                            _this.alertService.error("MERGE UNSUCCESSFUL, Your account cannot be merged at this time. Please contact 0800 600 555 for assistance.");
                        }
                        else {
                            _this.alertService.success("MERGE IN PROGRESS, We'll notify you when the merge is complete. In the meantime, you can still log in to your account.", true);
                        }
                    });
                    if (window.location.href.indexOf("bmw-motorrad") != -1) {
                        // this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
                        // this.brand = "bmw";
                        _this.web_site = "https://www.bmw-motorrad.co.za";
                    }
                    if (window.location.href.indexOf("mini") != -1) {
                        //    this. state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
                        //    this.brand = "mini";
                        _this.web_site = "https://www.mini.co.za";
                    }
                    if (window.location.href.indexOf("bmw") != -1) {
                        // this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
                        // this.brand = "bmwmotorrad";
                        _this.web_site = "https://www.bmw.co.za";
                    }
                    setTimeout(function () {
                        setTimeout(function () {
                            _this.router.navigate(["/"]).then(function (result) { window.location.href = _this.web_site; });
                        });
                    }, 6000);
                });
                return [2 /*return*/];
            });
        });
    };
    PostEmailComponent.prototype.ngOnInit = function () {
    };
    PostEmailComponent.prototype.activateLinked = function () {
        this.loadActivationMail(this.guid);
    };
    PostEmailComponent = __decorate([
        core_1.Component({ template: require('./psteml.component.html') }),
        __metadata("design:paramtypes", [router_1.ActivatedRoute, router_1.Router, _services_1.AlertService,
            onDemand_login_bp_service_1.OndemandLoginBpService])
    ], PostEmailComponent);
    return PostEmailComponent;
}());
exports.PostEmailComponent = PostEmailComponent;
